import { Card, Typography, Box, Grid, useMediaQuery } from '@mui/material'
import { ROUTE } from '../constants/route-constant'
import { COLOR } from '../constants/color-constant'
import fund from '../assets/icons/fund.svg'
import children_boy from '../assets/icons/children_boy.svg'
import children_gril from '../assets/icons/children_girl.svg'

import { HomeSummary } from '../models/HomeStat'
import { ReactComponent as Compasstion } from '../assets/images/compassionth.svg'
import { ReactComponent as Faq } from '../assets/images/faq.svg'
import { ReactComponent as About } from '../assets/images/about.svg'
import CountUp from './Animations/CountUpNumber'
function StatSection(props: { data: HomeSummary | null }) {
  const data = props.data
  const matches = useMediaQuery('(min-width:680px)')
  return (
    <Box
      display={'flex'}
      flexDirection={matches ? 'row' : 'column'}
      justifyContent={'center'}
      alignItems={'center'}
      flexWrap={'nowrap'}
      gap={'48px'}
      padding={'48px'}
      textAlign={'center'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'cebter'}
        alignItems={'center'}
      >
        <Typography variant='h2' color={COLOR.PRIMARY_1}>
          โครงการที่กำลังระดมทุน
        </Typography>
        {/* <Typography variant='h1' color={COLOR.PRIMARY_1}>
          {data?.fundingCampaign ?? '9,xxx'}
        </Typography> */}
        <CountUp targetNumber={data?.fundingCampaign ?? 0} textVariant='h1' />
        <Typography variant='h5' color={COLOR.PRIMARY_1}>
          โครงการ
        </Typography>
      </Box>

      {/* </Grid> */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'cebter'}
        alignItems={'center'}
      >
        <Typography variant='h2' color={COLOR.PRIMARY_1}>
          โครงการที่ระดมทุนตามเป้าหมาย
        </Typography>
        {/* <Typography variant='h1' color={COLOR.PRIMARY_1}>
          {data?.fundedCampaign ?? '9,xxx'}
        </Typography> */}
        <CountUp targetNumber={data?.fundedCampaign ?? 0} textVariant='h1' />
        <Typography variant='h5' color={COLOR.PRIMARY_1}>
          โครงการ
        </Typography>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'cebter'}
        alignItems={'center'}
      >
        <Typography align='center' variant='h2' color={COLOR.PRIMARY_1}>
          โครงการที่ดำเนินการเสร็จสิ้น
        </Typography>

        {/* <Typography variant='h1' color={COLOR.PRIMARY_1}>
          {data?.successfulCampaign ?? '9,xxx'}
        </Typography> */}

        <CountUp
          targetNumber={data?.successfulCampaign ?? 0}
          textVariant='h1'
        />

        <Typography variant='h5' color={COLOR.PRIMARY_1}>
          โครงการ
        </Typography>
      </Box>
    </Box>
  )
}
function FundchildSection(props: { data: HomeSummary | null }) {
  const data = props.data
  return (
    <Box
      py={3}
      px={{ xs: '5%', sm: '25%' }}
      sx={{ backgroundColor: COLOR.BACKGROUD_HOMEPAGE }}
    >
      <Card
        variant='outlined'
        sx={{ borderRadius: '20px', paddingY: '10px', boxShadow: 3 }}
      >
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          flex-direction={{ md: 'column', lg: 'row' }}
          gap={'40px'}
        >
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ maxWidth: '300px' }}
          >
            <img alt='fund' src={fund} style={{ height: 60 }} />
            <Typography align='center' variant='h5' color={COLOR.PRIMARY_1}>
              ยอดบริจาคทั้งหมด
            </Typography>

            <CountUp
              targetNumber={data?.totalAmountDonated ?? 0}
              duration={3000}
            />
            <Typography variant='h5' color={COLOR.PRIMARY_1}>
              บาท
            </Typography>
          </Grid>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ maxWidth: '300px' }}
          >
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <img
                alt='children_boy'
                src={children_boy}
                style={{ height: 60 }}
              />
              <img
                alt='children_gril'
                src={children_gril}
                style={{ height: 60 }}
              />
            </Grid>
            <Typography align='center' variant='h5' color={COLOR.PRIMARY_1}>
              เด็กได้รับการช่วยเหลือทั้งหมด
            </Typography>
            <CountUp
              targetNumber={data?.childrenDeveloped ?? 0}
              duration={3000}
            />

            <Typography variant='h5' color={COLOR.PRIMARY_1}>
              คน
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}
function Compassion() {
  return (
    <div className='bg-COLOR.BACKGROUD_HOMEPAGE grid grid-cols-1 items-center justify-center px-4 py-3 lg:grid-flow-row-dense lg:grid-cols-2'>
      <div className='flex items-center justify-center drop-shadow-lg'>
        <a href='http://www.compassionth.com/' target='_blank' rel='noreferrer'>
          <Compasstion width={260} height={260} />
        </a>
      </div>
      <div className='flex flex-col items-center justify-start gap-[30%] lg:flex-row'>
        <div className='py-4 drop-shadow-lg'>
          <a href={ROUTE.ABOUT} target='_blank' rel='noreferrer'>
            <About width={140} height={140} />
          </a>
        </div>
        <div className='py-4 drop-shadow-lg'>
          <a href={ROUTE.FAQ} target='_blank' rel='noreferrer'>
            <Faq width={140} height={140} />
          </a>
        </div>
      </div>
    </div>
  )
}
export { FundchildSection, StatSection, Compassion }

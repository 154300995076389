import {
  FormGroup,
  Checkbox,
  FormControlLabel,
  Typography,
  Stack,
  Divider,
  Input,
  FormControl,
  Radio,
  RadioGroup,
  Button,
} from '@mui/material'
import FileUpload from '../FileUpload/FileUpload'
import { ChangeEvent, useCallback, useRef } from 'react'
import { COLOR } from '../../constants/color-constant'
import { AttachmentMetaData } from '../../models/AttachmentMetaData'
import { CHURCH_QUESTION } from '../../constants/church-question'

export type SelectedChannels = {
  Facebook: boolean
  Twitter: boolean
  Youtube: boolean
  Email: boolean
  OtherChecked: boolean
  Other: string
}

export type ChurchProposal2Error = {
  memberRangeError: boolean
  fileTooBig: boolean
  isFileMissing: boolean
}

export type EditChurchError = {
  churchProfileError: boolean
  memberRangeError: boolean
  fileTooBig: boolean
  isFileMissing: boolean
}

interface ChurchProposalForm2Props {
  viewOnly: boolean
  memberRange: string
  uploadedFilesMetadata: AttachmentMetaData[]
  files: File[]
  selectedChannels: SelectedChannels
  onMemberRangeChange: (val: string) => void
  onSelectedChannelsChange: (channels: SelectedChannels) => void
  onFilesChange: ({
    files,
    uploadedFilesMetadata,
  }: {
    files: File[]
    uploadedFilesMetadata: AttachmentMetaData[]
  }) => void
  errors: ChurchProposal2Error
  setErrors: (errors: ChurchProposal2Error) => void
  onBack: () => void
  onSubmit: (memberRange: string, channels: string[], files: File[]) => void
  isSubmitLoading: boolean
}

function ChurchProposalForm2({
  viewOnly,
  memberRange,
  uploadedFilesMetadata,
  files,
  selectedChannels,
  onMemberRangeChange,
  onSelectedChannelsChange,
  onFilesChange,
  errors,
  setErrors,
  onBack,
  onSubmit,
  isSubmitLoading,
}: ChurchProposalForm2Props) {
  const memberRangeRef = useRef<HTMLDivElement | null>(null)
  const fileInputRef = useRef<HTMLDivElement | null>(null)

  const { memberRangeError, fileTooBig, isFileMissing } = errors

  const handleMemberRangeChange = (val: string) => {
    onMemberRangeChange(val)
    setErrors({
      ...errors,
      memberRangeError: false,
    })
  }

  // Function to handle changes in checkboxes
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    onSelectedChannelsChange({
      ...selectedChannels,
      [name]: checked,
    })
  }

  // Function to handle changes in the "อื่นๆ" input field
  const handleOtherFieldChange = (value: string) => {
    onSelectedChannelsChange({
      ...selectedChannels,
      Other: value,
      OtherChecked: value.trim() !== '',
    })
  }

  const handleFileTooBigError = useCallback(
    (isError: boolean) => {
      setErrors({
        ...errors,
        fileTooBig: isError,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setErrors]
  )

  const handleFileChange = ({
    files,
    uploadedFilesMetadata,
  }: {
    files: File[]
    uploadedFilesMetadata: AttachmentMetaData[]
  }) => {
    setErrors({
      ...errors,
      isFileMissing: files.length === 0,
    })
    onFilesChange({
      files: files,
      uploadedFilesMetadata: uploadedFilesMetadata,
    })
  }

  const handleSubmit = () => {
    if (
      memberRange &&
      (files.length > 0 || uploadedFilesMetadata.length > 0) &&
      !fileTooBig
    ) {
      onSubmit(memberRange, getSelectedChannels(), files)
    } else if (!memberRange) {
      setErrors({
        ...errors,
        memberRangeError: true,
      })
      scrollTo(memberRangeRef)
    } else if (files.length === 0 && uploadedFilesMetadata.length === 0) {
      setErrors({
        ...errors,
        isFileMissing: true,
      })
      scrollTo(fileInputRef)
    } else if (fileTooBig) {
      scrollTo(fileInputRef)
    }
  }

  const getSelectedChannels = () => {
    const channels: string[] = []
    for (const channel in selectedChannels) {
      if (channel === 'OtherChecked') {
        if (selectedChannels.Other.trim() !== '' && selectedChannels[channel]) {
          channels.push(selectedChannels.Other)
        }
      } else if (
        selectedChannels[channel as keyof typeof selectedChannels] &&
        channel !== 'Other'
      ) {
        channels.push(channel)
      }
    }
    return channels
  }

  const scrollTo = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }

  return (
    <Stack gap={'40px'}>
      <Stack
        flexDirection='column'
        flexWrap='wrap'
        gap={'20px'}
        ref={memberRangeRef}
      >
        <div>
          <span>
            <Typography display='inline' variant='h3' color={COLOR.PRIMARY_1}>
              *{' '}
            </Typography>
            <Typography display='inline' variant='h3'>
              จำนวนสมาชิก
            </Typography>
          </span>
          {memberRangeError && (
            <Typography variant='subtitle1' color='error'>
              กรุณากรอกจำนวนสมาชิก
            </Typography>
          )}
        </div>
        <FormControl disabled={viewOnly}>
          <RadioGroup
            aria-label='member-count'
            name='member-count'
            value={memberRange}
            onChange={(event, val) => {
              handleMemberRangeChange(val)
            }}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Stack
              flexDirection={{ sm: 'column', md: 'row' }}
              width={'100%'}
              justifyContent={'space-between'}
            >
              <FormControlLabel
                control={<Radio />}
                value={CHURCH_QUESTION.question1.choice1}
                label={CHURCH_QUESTION.question1.choice1}
              />
              <FormControlLabel
                control={<Radio />}
                value={CHURCH_QUESTION.question1.choice2}
                label={CHURCH_QUESTION.question1.choice2}
              />
              <FormControlLabel
                control={<Radio />}
                value={CHURCH_QUESTION.question1.choice3}
                label={CHURCH_QUESTION.question1.choice3}
              />
              <FormControlLabel
                control={<Radio />}
                value={CHURCH_QUESTION.question1.choice4}
                label={CHURCH_QUESTION.question1.choice4}
              />
              <FormControlLabel
                control={<Radio />}
                value={CHURCH_QUESTION.question1.choice5}
                label={CHURCH_QUESTION.question1.choice5}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>
      <Divider />
      <Stack flexDirection='column' flexWrap='wrap' gap={'20px'}>
        <Typography variant='h3'>ท่านรู้จักน้ำใจจากช่องทางใด</Typography>
        <FormGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack
            flexDirection={{ sm: 'column', md: 'row' }}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name={CHURCH_QUESTION.question2.choice1}
                  checked={selectedChannels.Facebook}
                  onChange={handleCheckboxChange}
                />
              }
              label={CHURCH_QUESTION.question2.choice1}
              disabled={viewOnly}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name={CHURCH_QUESTION.question2.choice2}
                  checked={selectedChannels.Twitter}
                  onChange={handleCheckboxChange}
                />
              }
              label={CHURCH_QUESTION.question2.choice2}
              disabled={viewOnly}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name={CHURCH_QUESTION.question2.choice3}
                  checked={selectedChannels.Youtube}
                  onChange={handleCheckboxChange}
                />
              }
              label={CHURCH_QUESTION.question2.choice3}
              disabled={viewOnly}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name={CHURCH_QUESTION.question2.choice4}
                  checked={selectedChannels.Email}
                  onChange={handleCheckboxChange}
                />
              }
              label={CHURCH_QUESTION.question2.choice4}
              disabled={viewOnly}
            />
            <FormControl
              variant='standard'
              sx={{ margin: '0px 16px 0px -11px' }}
              disabled={viewOnly}
            >
              <Stack flexDirection='row'>
                <Checkbox
                  name='OtherChecked'
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={selectedChannels.OtherChecked}
                  onChange={handleCheckboxChange}
                />
                <Input
                  placeholder='อื่น ๆ'
                  name={CHURCH_QUESTION.question2.choice5}
                  id='other-channel'
                  value={selectedChannels.Other}
                  onChange={(event) =>
                    handleOtherFieldChange(event.target.value)
                  }
                />
              </Stack>
            </FormControl>
          </Stack>
        </FormGroup>
      </Stack>
      <Divider />
      <Stack
        flexDirection='column'
        flexWrap='wrap'
        gap={'20px'}
        ref={fileInputRef}
      >
        <span>
          <Typography display='inline' variant='h3' color={COLOR.PRIMARY_1}>
            *{' '}
          </Typography>
          <Typography display='inline' variant='h3'>
            เอกสาร
          </Typography>
        </span>
        {isFileMissing && (
          <Typography variant='subtitle1' color='error'>
            กรุณาเลือกไฟล์
          </Typography>
        )}
        <div>
          <Typography variant='subtitle1' color={COLOR.GRAY_600}>
            เอกสารรับรองการจดทะเบียนคริสตจักร และอื่นๆ
          </Typography>
        </div>
        <FileUpload
          disabled={viewOnly}
          initialFiles={uploadedFilesMetadata}
          files={files}
          onChange={handleFileChange}
          setError={handleFileTooBigError}
        />
      </Stack>
      <Divider />
      <Stack justifyContent='space-between' flexDirection='row'>
        <Button variant='outlined' fullWidth={false} onClick={() => onBack()}>
          <Typography variant='h6'>{`< กลับ`}</Typography>
        </Button>
        {!viewOnly && (
          <Button
            variant='outlined'
            color='secondary'
            fullWidth={false}
            onClick={() => handleSubmit()}
            disabled={isSubmitLoading}
          >
            <Typography variant='h6'>ส่งคำขอ</Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default ChurchProposalForm2

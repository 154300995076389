import { useCallback, useEffect, useState } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Stack,
  Container,
  CircularProgress,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { grey } from '@mui/material/colors'
import dayjs from 'dayjs'
import isDeepEqual from 'fast-deep-equal/react'

import CampaignProposalForm1, {
  CampaignProposalForm1Input,
  campaignProposalForm1Schema,
} from './CampaignProposalForm1'
import CampaignProposalForm2, {
  CampaignProposalForm2Input,
  campaignProposalForm2Schema,
} from './CampaignProposalForm2'
import CampaignProposalForm3, {
  CampaignProposalForm3Input,
  campaignProposalForm3Schema,
} from './CampaignProposalForm3'
import AdminCommentSection from '../ChurchProposal/AdminCommentSection'
import { churchInfo } from '../SearchChurch'

import NormalModal from '../Modal/NormalModal'
import { useGet, usePost } from '../../hooks/useFetch'
import { UserShortDetail } from '../../models/UserTypes'
import { AttachmentMetaData } from '../../models/AttachmentMetaData'
import { CampaignProposalFormData } from '../../models/CampaignProposal/CampaignProposalFormData'
import usePeriodicSave from '../../hooks/usePeriodicSave'
import AuthService from '../../services/auth.service'
import {
  CampaignRequestContactPerson,
  CampaignRequestProjectHolder,
  CampaignRequestStatus,
  GetCampaignProposalData,
} from '../../models/CampaignProposal/GetCampaignProposalData'
import { useUser } from '../../services/UserContext'
import StatusBar from '../Admin/StatusBar'
import { combinedCampaignData } from '../../services/CampaignServices'
import { BankAccount } from '../../models/campaign'
import createAPIErrorMessage from '../../utils/createAPIErrorMessage'
import { transformCampaignProposalFormToLocalStorage } from '../../services/TransformCampaignProposalData'
import {
  CREATE_CAMPAIGN,
  removeLocalStorage,
} from '../../services/LocalStorageService'
import { useFormDirtyContext } from '../../services/FormDirtyContext'
import { ROUTE } from '../../constants/route-constant'
import trimFormValues from '../../utils/trimFormValues'

const steps = ['Step 1', 'Step 2', 'Step 3']

interface CampaignProposalProps {
  type: 'CREATE' | 'EDIT' | 'USER-VIEW' | 'ADMIN-VIEW'
  requestId?: string
  status?: CampaignRequestStatus
  adminNote?: string
  initialData?: any
  sendCampaignData?: (data: CampaignProposalFormData) => void
  onClickRequestFix?: () => void
  onClickApprove?: () => void
}

function CampaignProposal({
  type,
  requestId,
  status,
  adminNote,
  initialData,
  sendCampaignData,
  onClickRequestFix,
  onClickApprove,
}: CampaignProposalProps) {
  const user = useUser()
  const navigate = useNavigate()
  const [formData, setFormData] = useState<CampaignProposalFormData>()
  const { setIsDirty } = useFormDirtyContext()

  // Form 1 States
  const [contactPersons, setContactPersons] = useState<
    UserShortDetail[] | CampaignRequestContactPerson[]
  >([])
  const [holderChurches, setHolderChurches] = useState<
    churchInfo[] | CampaignRequestProjectHolder[]
  >([])
  const [selectedAreas, setSelectedAreas] = useState([
    false,
    false,
    false,
    false,
    false,
  ])

  const {
    register: registerCampaignProposalForm1,
    formState: {
      isDirty: campaignProposalForm1Dirty,
      errors: campaignProposalForm1Error,
    },
    getValues: getCampaignProposalForm1Values,
    handleSubmit: handleSubmitForm1,
    reset: resetForm1,
    control: campaignProposalForm1Control,
  } = useForm<CampaignProposalForm1Input>({
    resolver: zodResolver(campaignProposalForm1Schema),
  })

  //Form 2 States
  const [images, setImages] = useState<AttachmentMetaData[]>([])

  const {
    register: registerCampaignProposalForm2,
    formState: {
      isDirty: campaignProposalForm2Dirty,
      errors: campaignProposalForm2Error,
    },
    getValues: getCampaignProposalForm2Values,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    control: campaignProposalForm2Control,
  } = useForm<CampaignProposalForm2Input>({
    resolver: zodResolver(campaignProposalForm2Schema),
  })

  //Form 3 States
  const [bankAccount, setBankAccount] = useState<BankAccount>({
    id: '',
    bankName: '',
    accountName: '',
    accountNumber: '',
    qrCodeUrl: '',
  })

  const {
    register: registerCampaignProposalForm3,
    formState: {
      isDirty: campaignProposalForm3Dirty,
      errors: campaignProposalForm3Error,
    },
    getValues: getCampaignProposalForm3Values,
    handleSubmit: handleSubmitForm3,
    reset: resetForm3,
    control: campaignProposalForm3Control,
  } = useForm<CampaignProposalForm3Input>({
    resolver: zodResolver(campaignProposalForm3Schema),
  })

  useEffect(() => {
    resetForm1(initialData)
    resetForm2(
      initialData
        ? initialData
        : {
            location: [
              {
                addressLine: '',
                subDistrict: '',
                district: '',
                province: '',
              },
            ],
            childTarget: [
              {
                age: '',
                maleNumber: 0,
                femaleNumber: 0,
              },
            ],
            activityPlan: [
              {
                detail: '',
                startDate: dayjs(),
                endDate: dayjs().add(1, 'day'),
              },
            ],
          }
    )
    resetForm3(
      initialData
        ? initialData
        : {
            budgetPlan: [
              {
                topic: '',
                amount: 0,
                classifier: '',
                budget: 0,
              },
            ],
          }
    )

    if (initialData) {
      setContactPersons(initialData.oldContactPerson)
      setHolderChurches(initialData.oldChurchHolder)
      setSelectedAreas([
        initialData.areaHolistic.includes('EDUCATION'),
        initialData.areaHolistic.includes('CHILD_SAFEGUARDING'),
        initialData.areaHolistic.includes('INFRASTRUCTURE'),
        initialData.areaHolistic.includes('YOUTH_LEADERSHIP'),
        initialData.areaHolistic.includes('WELL_BEING'),
      ])
      setImages(initialData.image)
      if (initialData.bankAccount) {
        setBankAccount(initialData.bankAccount)
      }
    } else {
      if (user && user.userData) {
        const userChurch = user.userData.churchShort
        if (userChurch) {
          setHolderChurches([
            {
              churchId: userChurch.churchId,
              churchName: userChurch.churchName,
              churchProvince: userChurch.churchProvince,
            },
          ])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, user])

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createCampaignRes,
    sendCreateLoading,
    sendCreateReq,
    sendCreateStatusCode,
    createCampaignError,
  ] = usePost<any>({
    url: '/campaign-requests/propose-create',
    autoFetch: false,
    data: formData,
    jwtToken: AuthService.getTokenUser()!,
  })

  const [
    editCampaignRes,
    sendEditLoading,
    sendEditReq,
    sendEditStatusCode,
    editCampaignError,
  ] = usePost<any>({
    url: `/campaign-requests/propose-create/${requestId}/fix-campaign-propose`,
    autoFetch: false,
    data: formData,
    jwtToken: AuthService.getTokenUser()!,
  })

  const [
    fetchBankAccountRes,
    fetchBankAccountLoading,
    fetchBankAccount,
    fetchBankAccountStatusCode,
  ] = useGet<BankAccount[]>({
    url: `/churches/${user.userData?.churchShort?.churchId}/bank-accounts`,
    autoFetch: false,
    autoNavigate: false,
    jwtToken: AuthService.getTokenUser()!,
  })

  useEffect(() => {
    if (user.userData?.churchShort?.churchId && !fetchBankAccountLoading) {
      fetchBankAccount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userData?.churchShort?.churchId])

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const handleBack = () => {
    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = () => {
    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleSubmitCreateCampaign = () => {
    if (sendCreateLoading) return

    sendCreateReq()
  }

  const handleSubmitEditCampaign = () => {
    if (sendEditLoading) return

    sendEditReq()
  }

  useEffect(() => {
    if (formData) {
      if (type === 'CREATE') {
        handleSubmitCreateCampaign()
      } else if (type === 'EDIT') {
        handleSubmitEditCampaign()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  useEffect(() => {
    if (fetchBankAccountStatusCode === 403) {
      navigate(ROUTE.PERMISSION_DENIED)
    }

    if (sendEditStatusCode === -1 && sendCreateStatusCode === -1) return

    if (sendCreateStatusCode === 200) removeLocalStorage(CREATE_CAMPAIGN)

    if (sendEditStatusCode === 200 || sendCreateStatusCode === 200) {
      setShowSuccessModal(true)
      setIsDirty(false)
    } else {
      const statusCode =
        type === 'CREATE' ? sendCreateStatusCode : sendEditStatusCode

      let errMsg = ''
      if (editCampaignError) {
        errMsg = createAPIErrorMessage(editCampaignError)
      } else if (createCampaignError) {
        errMsg = createAPIErrorMessage(createCampaignError)
      }

      toast.error(
        `ไม่สามารถส่งคำขอได้ กรุณาลองอีกครั้งในภายหลัง ${errMsg} :ERROR(${statusCode})`
      )
    }
  }, [
    sendEditStatusCode,
    sendCreateStatusCode,
    fetchBankAccountStatusCode,
    type,
    editCampaignError,
    createCampaignError,
    createCampaignRes,
    editCampaignRes,
    setIsDirty,
    navigate,
  ])

  useEffect(() => {
    const isDirty =
      campaignProposalForm1Dirty ||
      campaignProposalForm2Dirty ||
      campaignProposalForm3Dirty

    handleSetIsDirty(isDirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignProposalForm1Dirty,
    campaignProposalForm2Dirty,
    campaignProposalForm3Dirty,
    setIsDirty,
    type,
  ])

  const handleSetIsDirty = (dirty: boolean) => {
    if (type === 'EDIT') {
      setIsDirty(dirty)
    }
  }

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false)
    navigate('/')
  }

  const handleImageChange = (images: AttachmentMetaData[]) => {
    handleSetIsDirty(true)
    setImages(images)
  }

  const handleBankAccountChange = (bankAccount: BankAccount) => {
    handleSetIsDirty(true)
    setBankAccount(bankAccount)
  }

  const prepareDataAndSend = async () => {
    const imageUrls = images.map((i) => i.url)
    const qrUrl = bankAccount.qrCodeUrl

    const combinedData: CampaignProposalFormData = combinedCampaignData({
      form1: getCampaignProposalForm1Values(),
      form2: getCampaignProposalForm2Values(),
      form3: getCampaignProposalForm3Values(),
      imageUrls,
      holderChurches,
      contactPersons,
      selectedAreas,
      qrUrl,
      bankAccount: sanitizeBankAccountData(),
    })

    setFormData(combinedData)
  }

  const sanitizeBankAccountData = () => {
    if (bankAccount.id === initialData.bankAccount.id) {
      if (!isDeepEqual(trimFormValues(bankAccount), initialData.bankAccount)) {
        return { ...bankAccount, id: '' }
      }
    }

    return bankAccount
  }

  const transformToLocalStorage = useCallback(() => {
    const qrUrl = bankAccount.qrCodeUrl

    const toSavedData: GetCampaignProposalData =
      transformCampaignProposalFormToLocalStorage({
        form1: getCampaignProposalForm1Values(),
        form2: getCampaignProposalForm2Values(),
        form3: getCampaignProposalForm3Values(),
        images,
        holderChurches,
        contactPersons,
        selectedAreas,
        qrUrl,
        bankAccount,
      })

    return toSavedData
  }, [
    bankAccount,
    contactPersons,
    getCampaignProposalForm1Values,
    getCampaignProposalForm2Values,
    getCampaignProposalForm3Values,
    holderChurches,
    images,
    selectedAreas,
  ])

  if (type === 'CREATE') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePeriodicSave('create_campaign', transformToLocalStorage, 5000)
  }

  if (sendEditLoading || sendCreateLoading) {
    window.scrollTo(0, 0)
    return (
      <div className='flex h-full items-stretch justify-center'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <Container sx={{ marginBottom: '80px' }}>
        <Stack gap={'20px'}>
          <div
            style={{
              maxWidth: '400px',
              width: '100%',
              minWidth: 0,
              alignSelf: 'center',
            }}
          >
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {type !== 'CREATE' && (
            <>
              {status && (
                <Stack flexDirection='row' gap='24px'>
                  <Typography variant='h6'>สถานะ: </Typography>
                  <StatusBar variant={status} />
                  {type !== 'ADMIN-VIEW' && (
                    <Typography variant='h6'>
                      {status === 'REQUEST_FIX'
                        ? 'กรุณาแก้ไขข้อมูล ก่อนส่งคำร้องขอการสร้างโครงการอีกครั้ง'
                        : 'ไม่สามารถแก้ไขคำร้องขอนี้ได้ในขณะนี้'}
                    </Typography>
                  )}
                </Stack>
              )}
              {adminNote && <AdminCommentSection comment={adminNote} />}
            </>
          )}
          <div>
            <Stack
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              paddingTop={'20px'}
            >
              <Stack
                flexDirection='column'
                border={1}
                borderColor={grey[400]}
                borderRadius={'20px'}
                width={'100%'}
                padding={3}
                gap='40px'
                bgcolor={'#FFFFFF'}
              >
                {activeStep === 0 && (
                  <CampaignProposalForm1
                    contactPersons={contactPersons}
                    holderChurches={holderChurches}
                    selectedAreas={selectedAreas}
                    onContactPersonChange={(v) => {
                      handleSetIsDirty(true)
                      setContactPersons(v)
                    }}
                    onHolderChurchChange={(v) => {
                      handleSetIsDirty(true)
                      setHolderChurches(v)
                    }}
                    onSelectedAreaChange={(v) => {
                      handleSetIsDirty(true)
                      setSelectedAreas(v)
                    }}
                    register={registerCampaignProposalForm1}
                    control={campaignProposalForm1Control}
                    errors={campaignProposalForm1Error}
                    onSubmit={handleSubmitForm1}
                    onSubmissionPass={handleNext}
                    allowEditProjectHolder={type === 'CREATE'}
                    viewOnly={type === 'ADMIN-VIEW' || type === 'USER-VIEW'}
                  />
                )}
                {activeStep === 1 && (
                  <CampaignProposalForm2
                    images={images}
                    onImagesChange={handleImageChange}
                    onBack={handleBack}
                    register={registerCampaignProposalForm2}
                    errors={campaignProposalForm2Error}
                    control={campaignProposalForm2Control}
                    onSubmit={handleSubmitForm2}
                    onSubmissionPass={handleNext}
                    viewOnly={type === 'ADMIN-VIEW' || type === 'USER-VIEW'}
                  />
                )}
                {activeStep === 2 && (
                  <CampaignProposalForm3
                    onBack={handleBack}
                    register={registerCampaignProposalForm3}
                    errors={campaignProposalForm3Error}
                    control={campaignProposalForm3Control}
                    churchBankAccounts={
                      fetchBankAccountRes
                        ? fetchBankAccountRes.filter((b) => b !== null)
                        : []
                    }
                    currBankAccount={bankAccount}
                    setBankAccount={handleBankAccountChange}
                    onSubmit={handleSubmitForm3}
                    onSubmissionPass={prepareDataAndSend}
                    onClickApprove={onClickApprove}
                    onClickRequestFix={onClickRequestFix}
                    viewOnly={type === 'ADMIN-VIEW' || type === 'USER-VIEW'}
                    adminView={type === 'ADMIN-VIEW'}
                    hasNextPage={false}
                  />
                )}
              </Stack>
            </Stack>
          </div>
        </Stack>
      </Container>
      <NormalModal
        id='success modal'
        type='DEFAULT'
        title={
          type === 'CREATE'
            ? 'ส่งแบบฟอร์มการสร้างโครงการสำเร็จ'
            : 'แก้ไขแบบฟอร์มสำเร็จ'
        }
        description='ระบบจะทำการตรวจสอบข้อมูล และแจ้งผลของท่านผ่านทางอีเมล'
        open={showSuccessModal}
        onClose={handleSuccessModalClose}
      />
    </>
  )
}

export default CampaignProposal

import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { createContext, useContext, useEffect, useState } from 'react'
import { ChurchMember } from '../../models/UserTypes'
import ChurchMemberList from '../../components/ChurchMemberList'
import { COLOR } from '../../constants/color-constant'
import ModalWithButton from '../../components/Modal/ModalWithButton'
import { toast } from 'react-toastify'
import { ChurchMemberManagementContext } from './ChurchManagement'
import { grey } from '@mui/material/colors'
import CustomToast from '../../components/Toast/CustomToast'

interface churchManagementProps {
  churchID: string
  pastor: ChurchMember
  mainContactPerson: ChurchMember
  contactPerson: ChurchMember[]
  memberUpdateStatusCode: number
  toPostData: object | undefined
  setToPostData: React.Dispatch<React.SetStateAction<object | undefined>>
  loadingPatchUpdateChurchMember: boolean
  sendMemberUpdate: () => void
  refreshMemberMgmt: () => void
}

interface UpdateChurchMemberData {
  pastor: ChurchMember
  mainContactPerson: any
  churchStaff: ChurchMember[]
  toBeRemoved: ChurchMember[]
}

export const DeleteMemberListContext = createContext<ChurchMember[]>([])

export default function ChurchMemberManagement(props: churchManagementProps) {
  const {
    pastor,
    mainContactPerson,
    contactPerson,
    setToPostData,
    sendMemberUpdate,
    memberUpdateStatusCode,
  } = props

  // const userContext = useUser()
  const [userIds, setUserIds, checkIfNotContactPerson] = useContext(
    ChurchMemberManagementContext
  )
  const [manageButtonText, setManageButtonText] = useState<string>(
    'จัดการข้อมูลเจ้าหน้าที่'
  )
  const [openManage, setOpenManage] = useState<boolean>(false)
  const [mockPastor, setMockPastor] = useState<ChurchMember>(pastor)
  const [MCP, setMCP] = useState<ChurchMember | null>(mainContactPerson)
  const [CP, setCP] = useState<ChurchMember[]>(contactPerson)
  const [toBeRemovedMembers, setToBeRemovedMembers] = useState<ChurchMember[]>(
    []
  )
  const [pastorDemote, setPastorDemote] = useState<boolean>(true)
  const [openConfirmMemberMamgt, setOpenConfirmMemberMamgt] =
    useState<boolean>(false)
  const [modalConfirmDescp, setModalConfirmDescp] = useState<React.ReactNode>(
    <></>
  )

  function handleClickManage() {
    setManageButtonText('ยืนยัน')
    setOpenManage(true)
  }

  const handleBeforeSave = () => {
    const pastor = mockPastor
    const mockMCP =
      toBeRemovedMembers.filter((x) => x.firstName === MCP?.firstName)
        .length === 1
        ? null
        : MCP
    const mockCP = CP.filter((x) => !toBeRemovedMembers.includes(x))
    const toBeRemove = toBeRemovedMembers

    const data = {
      pastor: pastor,
      mainContactPerson: mockMCP,
      churchStaff: mockCP,
      toBeRemoved: toBeRemove,
    }
    handleClickSave(data)
  }

  const handleClickSave = (data: UpdateChurchMemberData) => {
    setModalConfirmDescp(
      <Stack display={'flex'} flexDirection={'column'} gap={'4px'}>
        <Typography>
          โปรดตรวจสอบอีกครั้งว่าการจัดการลำดับสมาชิกในโบสถ์ถูกต้อง
        </Typography>
        {/* <Box height={'8px'} />
        <Typography>บาทหลวง</Typography>
        <Typography>
          {data.pastor.firstName +
            ' ' +
            data.pastor.lastName +
            ' ' +
            data.pastor.email}
        </Typography> */}
        <Box height={'8px'} />
        <Typography>ตัวแทนหลัก</Typography>
        {data.mainContactPerson ? (
          <Typography>
            {data.mainContactPerson.firstName +
              ' ' +
              data.mainContactPerson.lastName +
              ' ' +
              data.mainContactPerson.email}
          </Typography>
        ) : (
          <Typography>ไม่มีตัวแทนหลัก</Typography>
        )}
        <Box height={'8px'} />
        <Typography>ตัวแทน</Typography>
        {data.churchStaff.length > 0 ? (
          data.churchStaff.map((churchStaff) => (
            <Typography>
              {churchStaff.firstName +
                ' ' +
                churchStaff.lastName +
                ' ' +
                churchStaff.email}
            </Typography>
          ))
        ) : (
          <Typography>ไม่มีตัวแทน</Typography>
        )}
        <Box height={'8px'} />
        <Typography color={'error'}>ตัวแทนที่ถูกลบ</Typography>
        {data.toBeRemoved.length > 0 ? (
          data.toBeRemoved.map((toBeRemoved) => (
            <Typography>
              {toBeRemoved.firstName +
                ' ' +
                toBeRemoved.lastName +
                ' ' +
                toBeRemoved.email}
            </Typography>
          ))
        ) : (
          <Typography>ไม่มีตัวแทนที่ถูกลบ</Typography>
        )}
      </Stack>
    )

    const toPostFinalData = {
      pastorEmail: data.pastor.email,
      mainContactPersonEmail: data.mainContactPerson
        ? data.mainContactPerson.email
        : '',
      churchStaffEmails: data.churchStaff.map((x) => x.email),
      toBeRemovedEmails: data.toBeRemoved.map((x) => x.email),
    }

    setToPostData(toPostFinalData)
    setOpenConfirmMemberMamgt(true)
  }

  function handleClickPromoteMCP() {
    const tempPastor = mockPastor

    // promote mcp to pastor
    // demote pastor to mcp
    setMockPastor(MCP!)
    setMCP(tempPastor)
  }
  function handleClickPromoteCP(member: ChurchMember) {
    const thisCP = CP!.filter((cp) => cp.email === member.email).at(0)
    const newCPList = CP!.filter((cp) => cp.email !== member.email)
    const tempMCP = MCP
    if (tempMCP) newCPList.push(tempMCP!)

    // promote cp to mcp
    // demote mcp to cp
    setMCP(thisCP!)
    setCP(newCPList)
    setPastorDemote(true)
  }

  function handleClickDemotePastor() {
    const tempMCP = MCP

    if (
      MCP &&
      toBeRemovedMembers.filter((x) => x.email === MCP.email).length === 0
    ) {
      // if mockMCP isn't mark as delete
      setMCP(mockPastor)
      setMockPastor(tempMCP!)
    } else {
      toast.warning('ต้องมีศิษยาภิบาลของคริสตจักรอย่างน้อย 1 คน')
    }
  }

  function handleClickDemoteMCP() {
    if (MCP) {
      CP?.push(MCP)
      setMCP(CP[0])
      setCP(CP.filter((x) => x.email !== CP[0].email))
    }
    // setMockMCP(null)
    // setPastorDemote(false)
  }

  const handleClicRemoveMember = (member: ChurchMember) => {
    // push email if not duplicate
    if (
      toBeRemovedMembers.filter((x) => x.email === member.email).length === 0
    ) {
      toBeRemovedMembers.push(member)
    }
  }

  const handleClickCancelRemoveMember = (member: ChurchMember) => {
    // remove this member from toBeRemove List
    setToBeRemovedMembers(
      toBeRemovedMembers.filter((x) => x.email !== member.email)
    )
  }

  const handleClickConfirmManage = async () => {
    setOpenConfirmMemberMamgt(false)
    setOpenManage(false)
    sendMemberUpdate()
  }

  useEffect(() => {
    if (memberUpdateStatusCode === -1) {
      return
    }
    if (memberUpdateStatusCode === 200) {
      setManageButtonText('จัดการตัวแทน')
    } else if (memberUpdateStatusCode !== 200) {
      setManageButtonText('บันทึก')
    }
  }, [memberUpdateStatusCode])

  return (
    <Stack
      display='flex'
      flexDirection='column'
      alignItems='center'
      border={1}
      borderColor={grey[400]}
      borderRadius={'16px'}
      padding={'16px'}
    >
      <Box
        key={'church-member-mgmt'}
        display={'flex'}
        flexDirection={'column'}
        gap={'16px'}
        position={'relative'}
        alignItems={'flex-end'}
        padding={'16px'}
        width={'100%'}
      >
        <DeleteMemberListContext.Provider value={toBeRemovedMembers}>
          <Box
            id='member-mgmt-box'
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'left'}
            alignItems={'left'}
            gap={'8px'}
            alignSelf={'stretch'}
          >
            {/* <Stack gap={'8px'}>
              <Typography variant='h6'>ศิษยาภิบาล</Typography>

              <ChurchMemberList
                member={mockPastor}
                promoteable={false}
                demoteable={pastorDemote}
                removeable={false}
                open={openManage}
                handleClickPromote={() => {}}
                handleClickDemote={() => handleClickDemotePastor()}
                handleClickRemove={() => handleClicRemoveMember(mockPastor)}
                handleClickCancelRemove={() => {
                  handleClickCancelRemoveMember(mockPastor)
                }}
              />
            </Stack>
            <Divider variant='fullWidth' /> */}
            <Stack gap={'8px'}>
              <Typography variant='h6'>ผู้ประสานงานหลัก</Typography>

              {MCP && MCP.firstName && MCP.lastName && MCP.email ? (
                <ChurchMemberList
                  member={MCP}
                  promoteable={false}
                  demoteable={true}
                  removeable={false}
                  open={openManage}
                  handleClickPromote={() => handleClickPromoteMCP()}
                  handleClickDemote={() => handleClickDemoteMCP()}
                  handleClickRemove={() => handleClicRemoveMember(MCP)}
                  handleClickCancelRemove={() => {
                    handleClickCancelRemoveMember(MCP)
                  }}
                />
              ) : (
                <Typography variant='h6' color={COLOR.GRAY_400}>
                  ไม่มีเจ้าหน้าที่คริสตจักรในตำแหน่งผู้ประสานงานหลัก
                </Typography>
              )}
            </Stack>
            <Divider variant='fullWidth' />
            <Stack gap='8px'>
              <Typography variant='h6'>เจ้าหน้าที่คริสตจักร</Typography>
              {CP &&
                CP.map((cp) => (
                  <ChurchMemberList
                    key={'church-member-list-' + cp.email}
                    member={cp}
                    promoteable={true}
                    demoteable={false}
                    removeable={true}
                    open={openManage}
                    handleClickPromote={() => handleClickPromoteCP(cp)}
                    handleClickDemote={() => {}}
                    handleClickRemove={() => handleClicRemoveMember(cp)}
                    handleClickCancelRemove={() => {
                      handleClickCancelRemoveMember(cp)
                    }}
                  />
                ))}
            </Stack>
          </Box>
        </DeleteMemberListContext.Provider>

        {!openManage && checkIfNotContactPerson() && (
          <Button
            variant='outlined'
            color='primary'
            sx={{ width: '180px' }}
            onClick={handleClickManage}
          >
            {manageButtonText}
          </Button>
        )}
        {openManage && checkIfNotContactPerson() && (
          <Button
            variant='outlined'
            color='primary'
            sx={{ width: '120px' }}
            onClick={handleBeforeSave}
          >
            {manageButtonText}
          </Button>
        )}
      </Box>
      {/** CONFIRM MANAGE CHURCH MODAL */}
      <ModalWithButton
        id='church-mgmt-confirm-manage-member'
        type='DEFAULT'
        title={'แจ้งเตือนการมอบบทบาทสำหรับเจ้าหน้าที่คริสตจักร'}
        description={modalConfirmDescp}
        confirmBtnText={'เข้าใจและยืนยัน'}
        cancleBtnText={'ยกเลิก'}
        open={openConfirmMemberMamgt}
        onClose={() => {
          setOpenConfirmMemberMamgt(false)
          setManageButtonText('บันทึก')
        }}
        handleClickConfirm={() => handleClickConfirmManage()}
      />
      v
      <CustomToast />
    </Stack>
  )
}

import { ToastContainer } from 'react-toastify'

type ToastContainerProps = React.ComponentProps<typeof ToastContainer>

function CustomToast(props: ToastContainerProps) {
  return (
    <ToastContainer
      {...props}
      closeButton={props.closeButton === undefined ? false : props.closeButton}
      hideProgressBar={
        props.hideProgressBar === undefined ? true : props.hideProgressBar
      }
    />
  )
}

export default CustomToast

import { Stack, TextField } from '@mui/material'

import { SelectBank } from './SelectBank'
import { AttachmentMetaData } from '../../models/AttachmentMetaData'
import { BankAccount } from '../../models/campaign'

import ImageUpload from '../ImageUpload/ImageUpload'

interface CreateBankAccountProps {
  viewOnly: boolean
  bankAccount: BankAccount
  handleBankChange: (bankAccount: BankAccount) => void
}

export default function CreateBankAccount({
  viewOnly,
  bankAccount,
  handleBankChange,
}: CreateBankAccountProps) {
  return (
    <Stack gap='24px' width={'100%'}>
      <Stack gap='16px' flexDirection={'row'} flexWrap={'wrap'}>
        <Stack gap='16px' minWidth={'220px'} flexGrow={1}>
          <SelectBank
            viewOnly={viewOnly}
            bankAccount={bankAccount}
            setBank={(bank: string) => {
              handleBankChange({
                ...bankAccount,
                bankName: bank,
              })
            }}
          />
          <TextField
            size='small'
            label='ชื่อบัญชี'
            fullWidth
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#212121',
              },
              input: {
                background: 'white',
              },
            }}
            disabled={viewOnly}
            value={bankAccount.accountName}
            onChange={(e) => {
              handleBankChange({
                ...bankAccount,
                accountName: e.target.value,
              })
            }}
          />
          <TextField
            type='tel'
            size='small'
            label='เลขที่บัญชี'
            fullWidth
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              onInput: (e) => {
                // Allow only numeric characters
                e.currentTarget.value = e.currentTarget.value.replace(
                  /[^0-9]/g,
                  ''
                )
              },
            }}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#212121',
              },
              input: {
                background: 'white',
              },
            }}
            disabled={viewOnly}
            value={bankAccount.accountNumber}
            onChange={(e) => {
              handleBankChange({
                ...bankAccount,
                accountNumber: e.target.value,
              })
            }}
          />
        </Stack>
        <Stack gap='20px' width={'248px'}>
          <ImageUpload
            label='อัปโหลด QR Code'
            multiple={false}
            files={
              bankAccount.qrCodeUrl
                ? [
                    {
                      url: bankAccount.qrCodeUrl,
                      fileName: `qr-code-${bankAccount.accountName}`,
                      fileSize: 0,
                    },
                  ]
                : []
            }
            onChange={(updatedQR: AttachmentMetaData[]) => {
              handleBankChange({
                ...bankAccount,
                qrCodeUrl: updatedQR[0] ? updatedQR[0].url : '',
              })
            }}
            adminView={viewOnly}
            endpoint={'/campaign-requests/upload-qr-code'}
            type={'qr-code'}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

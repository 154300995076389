import { ReactComponent as KasikornBankLogo } from '../../assets/icons/Banks/kbank - ธนาคารกสิกรไทย.svg'
import { ReactComponent as ScbBankLogo } from '../../assets/icons/Banks/scb - ธนาคารไทยพาณิชย์.svg'
import { ReactComponent as KrungthaiBankLogo } from '../../assets/icons/Banks/ktb- ธนาคารกรุงไทย.svg'
import { ReactComponent as KrungsriBankLogo } from '../../assets/icons/Banks/bay - ธนาคารกรุงศรีอยุธยา.svg'
import { ReactComponent as BblBankLogo } from '../../assets/icons/Banks/bbl - ธนาคารกรุงเทพ.svg'
import { ReactComponent as IcbcBankLogo } from '../../assets/icons/Banks/icbc - ธนาคารไอซีบีซี (ไทย).svg'
import { ReactComponent as CitibankLogo } from '../../assets/icons/Banks/citi - ซิตี้แบงก์.svg'
import { ReactComponent as GsbBankLogo } from '../../assets/icons/Banks/gsb - ธนาคารออมสิน.svg'
import { ReactComponent as TtbBankLogo } from '../../assets/icons/Banks/ttb -ธนาคารทหารไทยธนชาต.svg'
import { ReactComponent as UobBankLogo } from '../../assets/icons/Banks/uob - ธนาคารยูโอบี.svg'
import { ReactComponent as CimbBankLogo } from '../../assets/icons/Banks/cimb - _ธนาคาร ซีไอเอ็มบี ไทย.svg'
import { ReactComponent as KiatnakinBankLogo } from '../../assets/icons/Banks/kk - ธนาคารเกียรตินาคิน.svg'
import { ReactComponent as OtherBankLogo } from '../../assets/icons/Banks/other bank.svg'
import { ReactComponent as GhbBankLogo } from '../../assets/icons/Banks/ghb - ธนาคารอาคารสงเคราะห์.svg'
import { ReactComponent as Lhbank } from '../../assets/icons/Banks/lhb - ธนาคารแลนด์ แอนด์ เฮ้าส์.svg'
import { ReactComponent as BaacBankLogo } from '../../assets/icons/Banks/baac - ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร.svg'

export default function BankLogo({ bankName }: { bankName: string }) {
  switch (bankName) {
    case 'ธนาคารไทยพาณิชย์':
      return <ScbBankLogo width='80px' height='80px' />
    case 'ธนาคารกสิกรไทย':
      return <KasikornBankLogo width='80px' height='80px' />
    case 'ธนาคารกรุงเทพ':
      return <BblBankLogo width='80px' height='80px' />
    case 'ธนาคารทหารไทยธนชาต':
      return <TtbBankLogo width='80px' height='80px' />
    case 'ธนาคารเพื่อการเกษตรและสกหรณ์การเกษตร':
      return <BaacBankLogo width='80px' height='80px' />
    case 'ธนาคารออมสิน':
      return <GsbBankLogo width='80px' height='80px' />
    case 'ธนาคารอาคารสงเคราะห์':
      return <GhbBankLogo width='80px' height='80px' />
    case 'ซิตี้แบงค์':
      return <CitibankLogo width='80px' height='80px' />
    case 'ธนาคาร ซีไอเอ็มบี ไทย':
      return <CimbBankLogo width='80px' height='80px' />
    case 'ธนาคารเกียรตินาคินภัทร':
      return <KiatnakinBankLogo width='80px' height='80px' />
    case 'ธนาคารยูโอบี':
      return <UobBankLogo width='80px' height='80px' />
    case 'ธนาคารไอซีบีซี (ไทย)':
      return <IcbcBankLogo width='80px' height='80px' />
    case 'ธนาคารแลนด์ แอนด์ เฮ้าส์':
      return <Lhbank width='80px' height='80px' />
    case 'ธนาคารกรุงไทย':
      return <KrungthaiBankLogo width='80px' height='80px' />
    case 'ธนาคารกรุงศรีอยุธยา':
      return <KrungsriBankLogo width='80px' height='80px' />
    default:
      return <OtherBankLogo width='80px' height='80px' />
  }
}

import { useEffect } from 'react'
import { Stack, Typography } from '@mui/material'

import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import CampaignProposal from '../../components/CampaignProposal/CampaignProposal'
import { useGet } from '../../hooks/useFetch'
import { useNavigate, useParams } from 'react-router'
import AuthService from '../../services/auth.service'
import { GetCampaignProposalData } from '../../models/CampaignProposal/GetCampaignProposalData'
import { ROUTE } from '../../constants/route-constant'
import transformCampaignProposalData from '../../services/TransformCampaignProposalData'
import { generateEditCampaignProposalRoute } from '../../utils/routeGenerator'
import CustomToast from '../../components/Toast/CustomToast'

function EditCampaignProposalPage() {
  const navigate = useNavigate()
  const params = useParams()
  const { campaignId: requestId } = params
  const [campaignProposalData, loading, getCampaignData, statusCode] =
    useGet<GetCampaignProposalData>({
      url: `/campaign-requests/${requestId}`,
      jwtToken: AuthService.getTokenUser() ?? '',
      autoFetch: true,
      autoNavigate: true,
    })

  useEffect(() => {
    if (statusCode === 401 || statusCode === 406) {
      navigate(ROUTE.PERMISSION_DENIED)
    } else if (!AuthService.getTokenUser()) {
      navigate(
        `${ROUTE.LOGIN}?redirectTo=${generateEditCampaignProposalRoute(
          requestId!
        )}`
      )
    }
  }, [statusCode, navigate, requestId])

  if (loading) {
    return null
  }

  const status = campaignProposalData?.campaignRequestStatus
  const isViewOnly = status !== 'REQUEST_FIX'

  return (
    <>
      <CustomToast />
      <Stack flexDirection='column' minHeight={'100vh'}>
        <Navbar />
        <Typography alignSelf={'center'} variant='h1' sx={{ margin: '20px' }}>
          {isViewOnly ? 'ดูคำขอเสนอโครงการ' : 'แก้ไขคำขอเสนอโครงการ'}
        </Typography>
        <div className='flex min-h-screen flex-col'>
          <div className='flex-1'>
            <CampaignProposal
              type={isViewOnly ? 'USER-VIEW' : 'EDIT'}
              {...(campaignProposalData
                ? {
                    initialData:
                      transformCampaignProposalData(campaignProposalData),
                  }
                : {})}
              requestId={requestId}
              status={status}
              adminNote={campaignProposalData?.adminComment}
            />
          </div>
          <Footer />
        </div>
      </Stack>
    </>
  )
}

export default EditCampaignProposalPage

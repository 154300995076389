import React, { useEffect, useState, useRef } from 'react'
import { LuFile } from 'react-icons/lu'
import { Box, Typography } from '@mui/material'

import { AttachmentMetaData } from '../../models/AttachmentMetaData'
import ImagePreview from './ImagePreview'
import uploadFile from '../../services/uploadfile.service'
import { toast } from 'react-toastify'
import ImageLoading from './ImageLoading'
import { useFormDirtyContext } from '../../services/FormDirtyContext'
import createAPIErrorMessage from '../../utils/createAPIErrorMessage'

const ACCEPTED_EXTENSION = '.jpg, .jpeg, .png'
const MAX_MULTIPLE_FILES_SIZE = 20 * 1000 * 1000 // 20 MB
const MAX_SINGLE_FILE_SIZE = 5 * 1000 * 1000 // 5 MB

type Prop = {
  label: string
  multiple?: boolean
  files: AttachmentMetaData[]
  endpoint: string
  onChange: (files: AttachmentMetaData[]) => void
  onUploading?: (isUploading: boolean) => void
  adminView: boolean
  type: 'image' | 'qr-code' | 'file'
}

function ImageUpload({
  label,
  multiple = true,
  endpoint,
  type,
  files,
  onChange,
  adminView,
}: Prop) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const [uploadProgress, setUploadProgress] = useState<
    { key: string; progress: number }[]
  >([])
  const [filesSize, setFilesSize] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const formDirtyContext = useFormDirtyContext()

  useEffect(() => {
    setFilesSize(calculateExistingFilesSize())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      prepareAndUploadFiles(Array.from(e.target.files))
    }
  }

  const handleDeleteFile = (index: number) => {
    onChange(files.filter((_, i) => i !== index))
  }

  // Below is from https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
  const fileDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault()
    const newFiles: File[] = []

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      ;[...e.dataTransfer.items].forEach((item) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile()
          if (file) {
            newFiles.push(file)
          }
        }
      })
    } else {
      // Use DataTransfer interface to access the file(s), in case the browser
      // doesn't support the above interface
      newFiles.push(...e.dataTransfer.files)
    }

    prepareAndUploadFiles(newFiles)
  }

  const prepareAndUploadFiles = (newFiles: File[]) => {
    resetFileInput()
    const validFiles = validateFiles(newFiles)
    if (validFiles.length > 0) {
      uploadFiles(validFiles)
    }
  }

  // Reset file input to allow re-selecting the same file
  const resetFileInput = () => {
    if (fileInputRef !== null && fileInputRef.current !== null) {
      fileInputRef.current.value = ''
    }
  }

  const validateFiles = (newFiles: File[]) => {
    const hasTooBigFile = newFiles.some(
      (file) => file.size > MAX_SINGLE_FILE_SIZE
    )

    const hasUnsupportedExtension = newFiles.some(
      (file) =>
        !ACCEPTED_EXTENSION.includes(
          file.name.substring(file.name.lastIndexOf('.')).toLowerCase()
        )
    )

    if (hasTooBigFile) {
      toast.error('ไฟล์ใหญ่เกินไป กรุณาเลือกไฟล์ที่มีขนาดไม่เกิน 5 MB')
      return []
    }

    if (hasUnsupportedExtension) {
      toast.error(`ไฟล์ไม่รองรับ ต้องเป็นไฟล์ JPEG, JPG, PNG เท่านั้น`)
      return []
    }

    const totalSize = filesSize + calculateNewFilesSize(newFiles)

    if (multiple && totalSize >= MAX_MULTIPLE_FILES_SIZE) {
      toast.error('ไม่สามารถอัปโหลดได้ ไฟล์รวมมีขนาดใหญ่เกินไป')
      return []
    }

    setFilesSize(totalSize)
    return newFiles
  }

  const calculateExistingFilesSize = () => {
    return files.reduce((acc, file) => file.fileSize + acc, 0) * 1024 // Convert KB to bytes
  }

  const calculateNewFilesSize = (newFiles: File[]) => {
    return newFiles.reduce((acc, file) => file.size + acc, 0)
  }

  const hasFile = files.length > 0 || isUploading

  const uploadFiles = async (newFiles: File[]) => {
    setIsUploading(true)
    formDirtyContext.setIsDirty(true)

    const responses = await Promise.allSettled(
      newFiles.map((file) =>
        uploadFile(endpoint, file, type, handleUploadProgress)
      )
    )

    const uploadedFiles = responses
      .filter((result) => result.status === 'fulfilled')
      .map((result: any) => result.value.data as AttachmentMetaData)

    const updatedFiles = [...files, ...uploadedFiles]
    formDirtyContext.setIsDirty(false)

    onChange(updatedFiles)

    setIsUploading(false)
    const errors = responses.filter(
      (result): result is PromiseRejectedResult => result.status === 'rejected'
    )
    if (errors.length > 0) {
      setUploadProgress([])
      const firstError = errors[0]
      const errMsg = createAPIErrorMessage(firstError.reason)

      const errCode =
        firstError.reason.response?.status || firstError.reason.code

      toast.error(
        `ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองอีกครั้งในภายหลัง: ${errMsg} ERROR(${errCode})`
      )
    }
  }

  const handleUploadProgress = (file: File, percent: number) => {
    setUploadProgress((prev) => {
      const key = file.name
      const newProgress = [...prev]
      const fileIndex = newProgress.findIndex((item) => item.key === key)
      if (fileIndex !== -1) {
        newProgress[fileIndex].progress = percent
      } else {
        newProgress.push({ key, progress: percent })
      }

      if (percent >= 99) {
        return newProgress.filter((item) => item.key !== key)
      }
      return newProgress
    })
  }

  return (
    <Box display='flex' flexDirection='column' gap='20px' minWidth={'220px'}>
      {hasFile && (
        <div
          className={`grid grid-cols-1 gap-4
        ${multiple && 'sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'}`}
        >
          {files.map((file, i) => (
            <ImagePreview
              key={i}
              name={file.fileName}
              index={i}
              imageUrl={file.url}
              onDelete={() => handleDeleteFile(i)}
              adminView={adminView}
            />
          ))}

          {uploadProgress.map((item) => (
            <ImageLoading key={item.key} progress={item.progress} />
          ))}
        </div>
      )}
      {!hasFile && adminView && (
        <div>
          <Typography variant='h6'>No photos have been uploaded.</Typography>
        </div>
      )}
      {(!hasFile || multiple) && !adminView && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '20px',
            outline: '2px dashed black',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
          }}
          onDrop={fileDropHandler}
          onDragOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <label
            htmlFor='file-upload'
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <input
              id='file-upload'
              type='file'
              accept={ACCEPTED_EXTENSION}
              multiple={multiple}
              onChange={handleAddFile}
              className='hidden'
              ref={fileInputRef}
            />
            <LuFile size={100} />
            <br />
            <Typography variant='h5'>{label}</Typography>
            {multiple ? (
              <Typography variant='body1' align='center'>
                รองรับไฟล์ JPEG, JPG, PNG ขนาดไม่เกิน 5 MB รวมไม่เกิน 20 MB
              </Typography>
            ) : (
              <Typography variant='body1' align='center'>
                รองรับไฟล์ JPEG, JPG, PNG ขนาดไม่เกิน 5 MB
              </Typography>
            )}
          </label>
        </div>
      )}
    </Box>
  )
}

export default ImageUpload

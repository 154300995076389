import dayjs from 'dayjs'

import {
  CampaignRequestContactPerson,
  CampaignRequestProjectHolder,
  GetCampaignProposalData,
} from '../models/CampaignProposal/GetCampaignProposalData'

import { AREA_HOLISTIC_ENUM } from './CampaignServices'
import { CampaignProposalForm1Input } from '../components/CampaignProposal/CampaignProposalForm1'
import { CampaignProposalForm2Input } from '../components/CampaignProposal/CampaignProposalForm2'
import { CampaignProposalForm3Input } from '../components/CampaignProposal/CampaignProposalForm3'
import { AttachmentMetaData } from '../models/AttachmentMetaData'
import { churchInfo } from '../components/SearchChurch'
import { UserShortDetail } from '../models/UserTypes'
import { BankAccount } from '../models/campaign'

// Why do we need to transform data?
// Because the data from the backend is not in the same format as the data that the form needs
export default function transformCampaignProposalData(
  data: GetCampaignProposalData
) {
  const transformed = {
    campaignName: data.name,
    campaignStartDate: dayjs(data.startDate),
    campaignEndDate: dayjs(data.endDate),
    fundingGoal: data.campaignRequestFundingDetail.targetBudget,
    donationStartDate: dayjs(
      data.campaignRequestFundingDetail.fundingStartDate
    ),
    donationEndDate: dayjs(data.campaignRequestFundingDetail.fundingEndDate),
    oldChurchHolder: data.projectHolders,
    oldContactPerson: data.campaignRequestContactPerson,
    objective: data.objective,
    background: data.background,
    workingProcess: data.workProcess,
    areaHolistic: data.campaignRequestAreaHolistics,
    childTarget: data.childParticipantTarget.map((c) => ({
      age: c.age,
      maleNumber: c.male,
      femaleNumber: c.female,
    })),
    expectedResult: data.expectedResult,
    activityPlan: data.activityPlan.map((a) => ({
      detail: a.description,
      startDate: dayjs(a.startDate),
      endDate: dayjs(a.endDate),
    })),
    image: data.images,
    budgetPlan: data.budgetPlan.map((b) => ({
      topic: b.topic,
      amount: b.amount,
      classifier: b.classifier,
      budget: b.budget,
    })),
    bankAccount: data.campaignRequestFundingDetail.bankAccount,
    resourceType: [],
    location: data.location.map((l) => ({
      addressLine: l.address,
      province: l.province,
      district: l.district,
      subDistrict: l.subDistrict,
    })),
  }

  return transformed
}

type TransformCampaignProposalFormToLocalStorageInput = {
  form1: CampaignProposalForm1Input
  form2: CampaignProposalForm2Input
  form3: CampaignProposalForm3Input
  images: AttachmentMetaData[]
  holderChurches: churchInfo[] | CampaignRequestProjectHolder[]
  contactPersons: CampaignRequestContactPerson[] | UserShortDetail[]
  selectedAreas: boolean[]
  qrUrl: string
  bankAccount: BankAccount
}

// Transform to a format that can be saved to local storage and then retrieved
// Will be retrieved and transformed to initial form data format
export function transformCampaignProposalFormToLocalStorage({
  form1,
  form2,
  form3,
  images,
  holderChurches,
  contactPersons,
  selectedAreas,
  qrUrl,
  bankAccount,
}: TransformCampaignProposalFormToLocalStorageInput): GetCampaignProposalData {
  const transformed: GetCampaignProposalData = {
    campaignId: '',
    name: form1.campaignName,
    startDate: form1.campaignStartDate.valueOf(),
    endDate: form1.campaignEndDate.valueOf(),
    campaignRequestAreaHolistics: AREA_HOLISTIC_ENUM.filter(
      (_, i) => selectedAreas[i]
    ),
    campaignRequestFundingDetail: {
      currentDonationAmount: 0,
      targetBudget: form1.fundingGoal,
      donatedUserCount: 0,
      fundingStartDate: form1.donationStartDate.valueOf(),
      fundingEndDate: form1.donationEndDate.valueOf(),
      bankAccount: {
        id: bankAccount.id,
        bankName: bankAccount.bankName,
        accountName: bankAccount.accountName,
        accountNumber: bankAccount.accountNumber,
        qrCodeUrl: qrUrl,
      },
    },
    projectHolders: holderChurches,
    campaignRequestContactPerson: contactPersons.map((person) => ({
      id: person.id,
      prefix: '',
      firstName: person.firstName,
      lastName: person.lastName,
      phoneNumber: '',
      email: person.email,
      profileImageUrl: '',
    })),
    objective: form2.objective,
    background: form2.background,
    workProcess: form2.workingProcess,
    location: form2.location.map((l) => ({
      address: l.addressLine,
      province: l.province,
      district: l.district,
      subDistrict: l.subDistrict,
    })),
    childParticipantTarget: form2.childTarget.map((c, index) => ({
      index: index,
      age: c.age,
      male: c.maleNumber,
      female: c.femaleNumber,
    })),
    expectedResult: form2.expectedResult,
    activityPlan: form2.activityPlan.map((p, index) => ({
      activityIndex: index,
      startDate: p.startDate.valueOf(),
      endDate: p.endDate.valueOf(),
      description: p.detail,
    })),
    images: images,
    budgetPlan: form3.budgetPlan.map((b, index) => ({
      index: index,
      topic: b.topic,
      amount: b.amount,
      classifier: b.classifier,
      budget: b.budget,
    })),
    campaignRequestType: '',
    proposerComment: '',
    adminComment: '',
  }

  return transformed
}

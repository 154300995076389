import { useState } from 'react'
import {
  Divider,
  Paper,
  Box,
  Button,
  Typography,
  CssBaseline,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material'
import loginBaner from '../assets/images/login_banner.png'
import { ReactComponent as IconGoogle } from '../assets/icons/google.svg'
import { COLOR } from '../constants/color-constant'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import AuthService from '../services/auth.service'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuthNotEnterMiddleware } from '../services/AuthMiddleware'
import { toast } from 'react-toastify'
import { ROUTE } from '../constants/route-constant'
import { useUser } from '../services/UserContext'
import { useGoogleLogin } from '@react-oauth/google'
import Navbar from '../components/Navbar/Navbar'
import { ADMIN_ROUTE } from '../constants/admin-route-constant'
import CustomToast from '../components/Toast/CustomToast'

function LoginPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const tokenChecked = useAuthNotEnterMiddleware()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => setShowPassword((prev) => !prev)
  const userContext = useUser()
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email') as string
    const password = data.get('password') as string

    AuthService.login(email, password).then(
      (res) => {
        if (userContext) {
          userContext.handleLogin()
        }
        const isAdmin = res.role === 'ADMIN' || res.role === 'SUPER_ADMIN'
        redirectAfterLogin(isAdmin)
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.detail) ||
          error.message ||
          error.toString()
        if (error.response.status === 404 || resMessage === 'Bad credentials') {
          toast.error('อีเมลหรือรหัสผ่านไม่ถูกต้อง')
          return // Don't continue if the user is not found
        }
        toast.error('เข้าสู่ระบบไม่สำเร็จ: ' + resMessage)
      }
    )
  }

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (tokenResponse) => {
      AuthService.googleLogin(tokenResponse.code).then(
        () => {
          if (userContext) {
            userContext.handleLogin()
          }
          redirectAfterLogin(false)
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.detail) ||
            error.message ||
            error.toString()
          toast.error('Login failed: ' + resMessage)
        }
      )
    },
  })

  const redirectAfterLogin = (isAdmin: boolean) => {
    if (isAdmin) {
      navigate(ADMIN_ROUTE.DASHBOARD)
    } else {
      const search = location.search
      const params = new URLSearchParams(search)
      const redirectTo = params.get('redirectTo')

      if (redirectTo && checkValidRedirectTo(redirectTo)) {
        navigate(redirectTo)
      } else {
        navigate('/')
      }
    }
  }

  const checkValidRedirectTo = (redirectTo: string) => {
    return redirectTo.startsWith('/') && !redirectTo.startsWith('//')
  }

  if (!tokenChecked) {
    return null // Don't render anything until token check is complete
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Navbar />
        <div style={{ flex: '1', overflowY: 'auto' }}>
          <Grid container component='main' sx={{ height: '100%' }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={false}
              md={4}
              lg={6}
              sx={{
                backgroundImage: 'url(' + loginBaner + ')',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                  t.palette.mode === 'light'
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={6}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 5,
                  mx: { xl: 20, lg: 6, md: 6, sm: 6, xs: 3 },
                  display: 'flex',
                  flexWrap: 'nowarp',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CustomToast />
                <Box width={'100%'} my={5}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <Typography variant='h1'>เข้าสู่ระบบ </Typography>
                    <Typography variant='h1' color={COLOR.PRIMARY_1}>
                      น้ำใจ
                    </Typography>
                  </Box>
                  <Typography variant='h6'>
                    ร่วมสร้างอนาคตที่ดีขึ้นให้กับเด็ก ๆ
                  </Typography>
                  <Button
                    variant='outlined'
                    onClick={() => googleLogin()}
                    style={{
                      borderBlockWidth: '2px',
                      borderRadius: '10px',
                      width: '100%',
                      marginTop: '5%',
                      marginBottom: '2%',
                      padding: '10px',
                      gap: '15px',
                    }}
                  >
                    <IconGoogle />
                    <Typography variant='h6' color={COLOR.GRAY_600}>
                      เข้าสู่ระบบด้วย Google
                    </Typography>
                  </Button>

                  <Divider>หรือ เข้าสู่ระบบด้วยอีเมลของท่าน </Divider>
                </Box>
                <Box
                  width={'100%'}
                  component='form'
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  {/* Email Field */}
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='อีเมล'
                    name='email'
                    autoComplete='email'
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* Password Field */}
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='รหัสผ่าน'
                    type={showPassword ? 'text' : 'password'}
                    id='password'
                    autoComplete='current-password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handleShowPassword} edge='end'>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* Submit Button */}
                  <Button
                    style={{
                      borderBlockWidth: '2px',
                      borderRadius: '10px',
                      borderColor:
                        !email || !password
                          ? COLOR.DIVIDER_MAIN
                          : COLOR.PRIMARY_2,
                      padding: '10px',
                    }}
                    type='submit'
                    fullWidth
                    variant='outlined'
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!email || !password} // Disable button if either field is empty
                  >
                    <Typography
                      variant='h5'
                      color={
                        !email || !password
                          ? COLOR.DIVIDER_MAIN
                          : COLOR.PRIMARY_2
                      }
                    >
                      เข้าสู่ระบบ
                    </Typography>
                  </Button>
                  <Grid
                    container
                    alignContent={'flex-end'}
                    flexDirection={'column'}
                  >
                    <Grid item xs>
                      <Button
                        variant='text'
                        onClick={() => navigate(ROUTE.FORGOT_PASSWORD)}
                      >
                        <Typography variant='body2' color={COLOR.PRIMARY_1}>
                          ลืมรหัสผ่าน?
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    direction='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Typography
                      variant='subtitle1'
                      style={{
                        whiteSpace: 'normal', // Change whiteSpace to 'normal'
                        wordWrap: 'break-word', // Add wordWrap property
                        overflowWrap: 'break-word', // Add overflowWrap property
                        maxWidth: '100%', // Optionally, set a maximum width
                      }}
                    >
                      หากท่านยังไม่ได้เป็นสมาชิก กรุณา
                      <Typography
                        color={COLOR.PRIMARY_1}
                        onClick={() => {
                          navigate(ROUTE.SIGNUP)
                        }}
                        style={{ display: 'inline', cursor: 'pointer' }}
                      >
                        สมัครสมาชิกที่นี่
                      </Typography>
                    </Typography>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default LoginPage

import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import StatusBar from './StatusBar'

import { useState } from 'react'
import { ReactComponent as Pencil } from '../../assets/icons/Admin/pencil.svg'
import { ReactComponent as Eye } from '../../assets/icons/Admin/eye.svg'
import { Link, useNavigate } from 'react-router-dom'
import { generateViewCampaignAdminRoute } from '../../utils/routeGenerator'
import formatCompactNumber from '../../utils/formatNumber'
import { theme } from '../../constants/theme'

type ReceiptListProps = {
  receiptId: string
  dateTime: number
  donor: string
  campaignId: string
  campaignName: string
  amount: number
  status: string
  imgUrl: string
  saveReceipt: (receiptId: string, newAmount: number, newStatus: string) => void
  variant: 'CHURCH' | 'ADMIN'
}

export default function ReceiptList(props: ReceiptListProps) {
  const navigate = useNavigate()

  const {
    receiptId,
    dateTime,
    donor,
    campaignId,
    campaignName,
    amount,
    status,
    imgUrl,
    saveReceipt,
    variant,
  } = props

  const [editReceipt, setEditReceipt] = useState(false)
  const [editIdReceipt, setIdEditReceipt] = useState('')

  const [newStatus, setNewStatus] = useState(status)

  const handleChange = (event: { target: { value: string } }) => {
    setNewStatus(event.target.value)
  }

  const [newAmount, setNewAmount] = useState<number | null>(amount)
  const handleAmountChange = (event: { target: { value: string } }) => {
    const inputValue = event.target.value
    const numericValue = inputValue.replace(/[^0-9]/g, '')

    if (inputValue === '') {
      setNewAmount(null)
    } else {
      setNewAmount(Number(numericValue))
    }
  }

  const dt = new Date(dateTime)

  return (
    <TableRow key={props.receiptId}>
      <TableCell sx={{ width: 110, flexWrap: 'wrap' }}>
        <Typography
          variant={'h6'}
          color={'text.primary'}
          sx={{
            color: 'text.primary',
          }}
        >
          {' ' +
            dt.getDate().toString() +
            '/' +
            (dt.getMonth() + 1) +
            '/' +
            dt.getFullYear()}
        </Typography>
      </TableCell>

      <TableCell align='left' sx={{ width: 100, flexWrap: 'wrap' }}>
        <Typography
          variant='h6'
          color={'text.primary'}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          {dt.getHours() +
            ':' +
            (dt.getMinutes() < 10 ? '0' : '') +
            dt.getMinutes()}
        </Typography>
      </TableCell>
      <TableCell align='left' sx={{ width: 170, flexWrap: 'wrap' }}>
        <Typography
          variant='h6'
          color={'primary'}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          {donor}
        </Typography>
      </TableCell>
      <TableCell align='left' sx={{ width: 320, flexWrap: 'wrap' }}>
        <Typography
          variant='h6'
          color={'primary'}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'nowrap',
          }}
          onClick={() => {
            navigate(generateViewCampaignAdminRoute(campaignId))
          }}
        >
          {campaignName}
        </Typography>
      </TableCell>
      {variant === 'ADMIN' ? (
        editReceipt ? (
          <>
            <TableCell align='left' sx={{ width: 250, flexWrap: 'wrap' }}>
              <FormControl variant='outlined' sx={{ width: 250 }}>
                <OutlinedInput
                  id='outlined-adornment-weight'
                  endAdornment={
                    <InputAdornment position='end'>บาท</InputAdornment>
                  }
                  inputProps={{
                    type: 'number',
                  }}
                  value={newAmount}
                  onChange={handleAmountChange}
                />
              </FormControl>
            </TableCell>

            <TableCell align='left' sx={{ width: 180, flexWrap: 'wrap' }}>
              <FormControl sx={{ width: 180 }}>
                <Select
                  value={newStatus}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={'UNCONFIRMED'}>
                    <StatusBar variant={'UNCONFIRMED'}></StatusBar>
                  </MenuItem>
                  <MenuItem value={'CONFIRMED'}>
                    <StatusBar variant={'CONFIRMED'}></StatusBar>
                  </MenuItem>
                  <MenuItem value={'ISSUE'}>
                    <StatusBar variant={'ISSUE'}></StatusBar>
                  </MenuItem>
                </Select>
              </FormControl>
            </TableCell>

            <TableCell align='left' sx={{ width: 130, flexWrap: 'wrap' }}>
              <Button
                style={{
                  borderBlockWidth: '2px',
                  borderRadius: '10px',
                  borderColor: 'primary',
                  padding: '10px',
                  color: 'primary',
                }}
                fullWidth
                variant='outlined'
                onClick={() => {
                  setEditReceipt(false)
                  saveReceipt(editIdReceipt, newAmount!, newStatus)
                  setIdEditReceipt('')
                  setNewStatus('')
                }}
                sx={{ height: '42px' }}
                disabled={newAmount === null}
              >
                <Typography variant='h6'>Save</Typography>
              </Button>
            </TableCell>
            <TableCell align='left' sx={{ width: 130, flexWrap: 'wrap' }}>
              <Button
                style={{
                  borderBlockWidth: '2px',
                  borderRadius: '10px',
                  borderColor: 'primary',
                  padding: '10px',
                }}
                fullWidth
                variant='outlined'
                onClick={() => {
                  setEditReceipt(false)
                  setIdEditReceipt('')
                  setNewStatus(status)
                  setNewAmount(amount)
                }}
                sx={{ height: '42px' }}
              >
                <Typography variant='h6' color={'primary'}>
                  Cancel
                </Typography>
              </Button>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell align='left' sx={{ width: 250, flexWrap: 'wrap' }}>
              <Typography
                variant='h6'
                color={'text.primary'}
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                }}
              >
                {formatCompactNumber(amount)}
              </Typography>
            </TableCell>
            <TableCell align='left' sx={{ width: 120, flexWrap: 'wrap' }}>
              <Link to={imgUrl} target='_blank'>
                <Box
                  border={1}
                  borderRadius={'5px'}
                  borderColor={'main'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  width={'42px'}
                  height={'42px'}
                  display={'flex'}
                  sx={{ cursor: 'pointer' }}
                >
                  <Eye
                    color='main'
                    style={{
                      fontSize: '32px',
                    }}
                  />
                </Box>
              </Link>
            </TableCell>
            <TableCell align='left' sx={{ flexWrap: 'wrap' }}>
              <StatusBar variant={status}></StatusBar>
            </TableCell>
            <TableCell align='right' sx={{ width: 120, flexWrap: 'wrap' }}>
              <Box
                border={1}
                borderRadius={'5px'}
                borderColor={'main'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'42px'}
                height={'42px'}
                display={'flex'}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setEditReceipt(true)
                  setIdEditReceipt(receiptId)
                  setNewStatus(status)
                }}
              >
                <Pencil
                  color='main'
                  style={{
                    fontSize: '32px',
                  }}
                />
              </Box>
            </TableCell>
          </>
        )
      ) : (
        <>
          {' '}
          <TableCell align='left' sx={{ width: 250, flexWrap: 'wrap' }}>
            <Typography
              variant='h6'
              color={'text.primary'}
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
              }}
            >
              {formatCompactNumber(amount)}
            </Typography>
          </TableCell>
          <TableCell align='left' sx={{ width: 120, flexWrap: 'wrap' }}>
            <Link to={imgUrl} target='_blank'>
              <Box
                border={1}
                borderRadius={'5px'}
                borderColor={'main'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'42px'}
                height={'42px'}
                display={'flex'}
                sx={{ cursor: 'pointer' }}
              >
                <Eye
                  style={{
                    color: 'main',
                    fontSize: '32px',
                  }}
                />
              </Box>
            </Link>
          </TableCell>
          {status !== 'UNCONFIRMED' ? (
            <>
              <TableCell
                align='left'
                sx={{ width: 130, flexWrap: 'wrap' }}
              ></TableCell>
              <TableCell align='left' sx={{ width: 130, flexWrap: 'wrap' }}>
                <StatusBar variant={status}></StatusBar>
              </TableCell>
            </>
          ) : (
            <>
              <TableCell align='left' sx={{ width: 130, flexWrap: 'wrap' }}>
                <Button
                  style={{
                    borderBlockWidth: '2px',
                    borderRadius: '10px',
                    borderColor: '#34CC25',
                    padding: '10px',
                    color: '#34CC25',
                  }}
                  fullWidth
                  variant='outlined'
                  onClick={() => {
                    saveReceipt(receiptId, amount, 'CONFIRMED')
                    setEditReceipt(false)
                    setIdEditReceipt('')
                    setNewStatus('')
                  }}
                  sx={{ height: '42px' }}
                  disabled={newAmount === null}
                >
                  <Typography variant='h6'>Confirm</Typography>
                </Button>
              </TableCell>
              <TableCell align='left' sx={{ width: 130, flexWrap: 'wrap' }}>
                <Button
                  style={{
                    borderBlockWidth: '2px',
                    borderRadius: '10px',
                    borderColor: '#FF0038',
                    padding: '10px',
                  }}
                  fullWidth
                  variant='outlined'
                  onClick={() => {
                    saveReceipt(receiptId, amount, 'ISSUE')
                    setEditReceipt(false)
                    setIdEditReceipt('')
                    setNewStatus('')
                  }}
                  sx={{ height: '42px' }}
                >
                  <Typography variant='h6' color={'#FF0038'}>
                    Issue
                  </Typography>
                </Button>
              </TableCell>
            </>
          )}
        </>
      )}
    </TableRow>
  )
}

import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import AuthService from '../services/auth.service'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Typography,
  Stack,
  Box,
  Divider,
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  Input,
  FormGroup,
  RadioGroup,
  Radio,
  CircularProgress,
  Container,
  Autocomplete,
} from '@mui/material'
import { COLOR } from '../constants/color-constant'

import { ReactComponent as ChurchIcon } from '../assets/Church.svg'
import { useGet, usePost } from '../hooks/useFetch'
import Navbar from '../components/Navbar/Navbar'
import { common, grey } from '@mui/material/colors'
import PermissionDeny from './ErrorPage/PermissionDeny'
import Footer from '../components/Footer/Footer'
import {
  GetChurchProposalFormAnswer,
  SubmitChurchProposalFormData,
  SubmitEditChurchProposalFormData,
} from '../models/ChurchProposalFormData'
import { SelectedChannels } from '../components/ChurchProposal/ChurchProposalForm2'
import FileUpload from '../components/FileUpload/FileUpload'
import { AttachmentMetaData } from '../models/AttachmentMetaData'
import ModalWithButton from '../components/Modal/ModalWithButton'
import NormalModal from '../components/Modal/NormalModal'
import { TypeOf } from 'zod'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import ThaiProvince from '../constants/thai-province'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import trimFormValues from '../utils/trimFormValues'
import uploadFile from '../services/uploadfile.service'
import { toast } from 'react-toastify'
import { churchProfileSchema } from '../components/ChurchProposal/ChurchProposalForm1'
import { CHURCH_QUESTION } from '../constants/church-question'
import appendHttpsIfMissing from '../utils/appendHttps'
import CustomToast from '../components/Toast/CustomToast'
import createAPIErrorMessage from '../utils/createAPIErrorMessage'
import { EditChurchDetail } from '../models/ChurchDetail'

export type ChurchProfileInput = TypeOf<typeof churchProfileSchema>

export type EditChurchError = {
  churchProfileError: boolean
  memberRangeError: boolean
  fileTooBig: boolean
  isFileMissing: boolean
}

export default function EditChurchForChurchMember() {
  const token = AuthService.getTokenUser()
  const navigate = useNavigate()

  const params = useParams()
  const churchID = params.churchId

  const memberRangeRef = useRef<HTMLDivElement | null>(null)
  const fileInputRef = useRef<HTMLDivElement | null>(null)

  const [errors, setErrors] = useState<EditChurchError>({
    churchProfileError: false,
    memberRangeError: false,
    fileTooBig: false,
    isFileMissing: false,
  })

  const [
    requestData,
    loadingEditChurchDetails,
    fetchEditChurchDetails,
    statusCode,
  ] = useGet<EditChurchDetail>({
    url: `/churches/${churchID}`,
    jwtToken: token ? token : '',
    autoFetch: true,
  })

  // handle church profile
  const {
    register: registerChurchProfileForm,
    formState: { errors: churchProfileErrors },
    reset: setChurchProfileValue,
    getValues: getChurchProfileData,
    handleSubmit: handleSubmitForm1,
    control: churhProfileFormControl,
  } = useForm<ChurchProfileInput>({
    resolver: zodResolver(churchProfileSchema),
  })

  let err: FieldErrors<SubmitChurchProposalFormData> = churchProfileErrors
  const [fileUploadLoading, setFileUploadLoading] = useState(false)
  const [uploadFileError, setUploadFileError] = useState<boolean>(false)
  const uploadFiles = async (): Promise<string[]> => {
    setFileUploadLoading(true)
    try {
      const responses = await Promise.all(
        files.map((file) =>
          uploadFile('/church-requests/upload-document', file, 'file')
        )
      )

      const cdnUrls = responses.map((res) => res.data.url)
      setUploadFileError(false)
      return cdnUrls
    } catch (error: any) {
      setUploadFileError(true)
      let toastErrorMsg = `ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองอีกครั้งในภายหลัง`
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response)
        const msg = createAPIErrorMessage(error)
        toastErrorMsg = `ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองอีกครั้งในภายหลัง ${msg} :ERROR(${error.response.status})`
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      toast.error(toastErrorMsg)
    } finally {
      setFileUploadLoading(false)
    }

    return []
  }

  const OnSubmit = async () => {
    if (handleClickSubmit()) {
      setOpenSubmitModal(true)
    } else {
      return
    }
  }

  // handle member answer
  const getMemberRange = (initialData?: EditChurchDetail) => {
    if (
      initialData &&
      initialData['formAnswers'] &&
      initialData.formAnswers.length > 0 &&
      !loadingEditChurchDetails
    ) {
      const question = initialData.formAnswers.find(
        (q: GetChurchProposalFormAnswer) => q.formQuestion.id === 1
      )

      if (question) {
        return question.answer
      }
    }

    return ''
  }

  const [memberRange, setMemberRange] = useState<string>('')

  const handleMemberRangeChange = (val: string) => {
    setMemberRange(val)
    setErrors({
      ...errors,
      memberRangeError: false,
    })
  }

  //handle Channels answer
  const getSelectedChannels = (
    initialData: EditChurchDetail
  ): SelectedChannels => {
    if (!initialData) {
      return {
        Facebook: false,
        Twitter: false,
        Youtube: false,
        Email: false,
        OtherChecked: false,
        Other: '',
      }
    }

    const channels = initialData.formAnswers
      .filter((q: GetChurchProposalFormAnswer) => q.formQuestion.id === 2)
      .map((ans: GetChurchProposalFormAnswer) => ans.answer)

    const other = channels.filter(
      (c: string) =>
        c !== 'Facebook' && c !== 'Twitter' && c !== 'Youtube' && c !== 'Email'
    )

    return {
      Facebook: channels.includes('Facebook'),
      Twitter: channels.includes('Twitter'),
      Youtube: channels.includes('Youtube'),
      Email: channels.includes('Email'),
      OtherChecked: other.length > 0,
      Other: other.length > 0 ? other[0] : '',
    }
  }

  const getSelectedChannelsOut = () => {
    const channels: string[] = []
    for (const channel in selectedChannels) {
      if (channel === 'OtherChecked') {
        if (selectedChannels.Other.trim() !== '' && selectedChannels[channel]) {
          channels.push(selectedChannels.Other)
        }
      } else if (
        selectedChannels[channel as keyof typeof selectedChannels] &&
        channel !== 'Other'
      ) {
        channels.push(channel)
      }
    }
    return channels
  }

  const [selectedChannels, setSelectedChannels] = useState<SelectedChannels>({
    Facebook: false,
    Twitter: false,
    Youtube: false,
    Email: false,
    OtherChecked: false,
    Other: '',
  })
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    setSelectedChannels({
      ...selectedChannels,
      [name]: checked,
    })
  }

  const handleOtherFieldChange = (value: string) => {
    setSelectedChannels({
      ...selectedChannels,
      Other: value,
      OtherChecked: value.trim() !== '',
    })
  }

  //handle file upload
  const { memberRangeError, fileTooBig, isFileMissing } = errors
  const [uploadedFiles, setUploadedFiles] = useState<AttachmentMetaData[]>([])
  // console.log('uploadedFiles', uploadedFiles)

  // handle initial data
  useEffect(() => {
    if (requestData) {
      setUploadedFiles(requestData.documents)
      setMemberRange(getMemberRange(requestData))
      setSelectedChannels(getSelectedChannels(requestData))
      setChurchProfileValue(requestData)
    } else {
      setUploadedFiles([])
    }
  }, [requestData])

  const [files, setFiles] = useState<File[]>([])
  const [formData, setFormData] = useState<SubmitEditChurchProposalFormData>()

  const handleFileChange = ({
    files,
    uploadedFilesMetadata,
  }: {
    files: File[]
    uploadedFilesMetadata: AttachmentMetaData[]
  }) => {
    setFiles(files)
    setUploadedFiles(uploadedFilesMetadata)
  }

  const handleFileTooBigError = useCallback(
    (isError: boolean) => {
      setErrors({
        ...errors,
        fileTooBig: isError,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setErrors]
  )

  //handle Submit
  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false)
  const [openSuccessSubmitModal, setOpenSuccessSubmitModal] =
    useState<boolean>(false)

  const scrollTo = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }
  const handleClickSubmit = () => {
    if (
      memberRange &&
      (files.length > 0 || uploadedFiles.length > 0) &&
      !fileTooBig
    ) {
      return true
      // setOpenSubmitModal(true)
    } else if (!memberRange) {
      setErrors({
        ...errors,
        memberRangeError: true,
      })
      scrollTo(memberRangeRef)
      return false
    } else if (files.length === 0 && uploadedFiles.length === 0) {
      setErrors({
        ...errors,
        isFileMissing: true,
      })
      scrollTo(fileInputRef)
      return false
    } else if (fileTooBig) {
      scrollTo(fileInputRef)
      return false
    }
  }

  const [
    editChurchRes,
    sendEditLoading,
    sendEditReq,
    sendEditStatusCode,
    sendEditError,
  ] = usePost<any>({
    url: `/churches/${churchID}/propose-edit`,
    autoFetch: false,
    jwtToken: AuthService.getTokenUser() ?? '',
    data: formData,
  })

  const handleSubmitEditChurch = async () => {
    // setOpenSubmitModal(false)
    // setIsClickConfirm(true)
    const cdnFileLinks = uploadedFiles ? uploadedFiles?.map((f) => f.url) : []
    const page1Data = trimFormValues(getChurchProfileData())
    if (page1Data.website) {
      page1Data.website = appendHttpsIfMissing(page1Data.website)
    }

    const page2Data = {
      formAnswers: [
        {
          questionId: 1,
          answer: memberRange,
        },
        ...getSelectedChannelsOut().map((channel) => {
          return {
            questionId: 2,
            answer: channel,
          }
        }),
      ],
      documents: [...(await uploadFiles()), ...cdnFileLinks],
    }

    if (uploadFileError) {
      setOpenSubmitModal(false)
    } else {
      setFormData({
        ...page1Data,
        ...page2Data,
      })

      if (sendEditLoading) return
    }
  }

  useMemo(() => {
    if (formData && !sendEditLoading) sendEditReq()
  }, [formData])

  // Modal handling

  useEffect(() => {
    if (sendEditStatusCode === -1) return

    if (sendEditStatusCode === 200) {
      setOpenSubmitModal(false)
      setOpenSuccessSubmitModal(true)
    } else if (sendEditError) {
      const statusCode = sendEditStatusCode
      const errMsg = createAPIErrorMessage(sendEditError)
      toast.error(
        `ไม่สามารถส่งคำขอได้ กรุณาลองอีกครั้งในภายหลัง ${errMsg} :ERROR(${statusCode})`
      )
    }
  }, [sendEditStatusCode])

  if (loadingEditChurchDetails) {
    return (
      <>
        <Navbar />
        <Box
          display={'flex'}
          justifyContent={'center'}
          padding={'10vw'}
          alignItems={'center'}
          height={'70vh'}
        >
          <CircularProgress />
        </Box>
        <Footer />
      </>
    )
  } else if (statusCode === 200) {
    return (
      <div className='bg-[#FBFDFF]'>
        <Navbar />
        <Container>
          <Stack gap={'40px'}>
            <Stack
              flexDirection='row'
              flexWrap='wrap'
              justifyContent='space-between'
              gap={'24px'}
            >
              <Box height={'12px'} />
              <Box textAlign={'center'} width={'100%'}>
                <Typography variant='h2'>แก้ไขข้อมูลคริสตจักร</Typography>
              </Box>
              <Stack
                flexDirection='column'
                border={1}
                borderColor={grey[400]}
                borderRadius={'20px'}
                width={'100%'}
                padding={3}
                gap='40px'
                bgcolor={common.white}
              >
                <Stack display={'flex'} flexDirection={'column'} gap={'24px'}>
                  {/** Church Profile section */}
                  <Stack
                    flexDirection='row'
                    flexWrap='wrap'
                    justifyContent='space-between'
                    gap={'24px'}
                  >
                    <Stack>
                      <span>
                        <Typography
                          display='inline'
                          variant='h3'
                          color={COLOR.PRIMARY_1}
                        >
                          *{' '}
                        </Typography>
                        <Typography display='inline' variant='h3'>
                          ข้อมูลคริสตจักร
                        </Typography>
                      </span>
                      <Box
                        display={{ xs: 'none', lg: 'block' }}
                        marginTop='auto'
                        marginBottom='auto'
                      >
                        <ChurchIcon />
                      </Box>
                    </Stack>
                    <Box
                      maxWidth={{ xs: '100%', lg: '75%' }}
                      onSubmit={handleSubmitForm1(OnSubmit)}
                      component='form'
                      id='church-profile-form'
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='churchName'
                            size='small'
                            label='ชื่อคริสตจักร'
                            variant='outlined'
                            error={Boolean(err['churchName'])}
                            fullWidth={true}
                            {...registerChurchProfileForm('churchName')}
                          />
                        </Grid>
                        <Grid
                          item
                          display={{ xs: 'none', md: 'block' }}
                          md={6}
                        ></Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='denomination'
                            size='small'
                            label='สังกัด'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err['denomination'])}
                            {...registerChurchProfileForm('denomination')}
                          />
                        </Grid>
                        <Grid
                          item
                          display={{ xs: 'none', md: 'block' }}
                          md={6}
                        ></Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='address'
                            size='small'
                            label='ที่อยู่'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err.address?.addressLine)}
                            {...registerChurchProfileForm(
                              'address.addressLine'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='sub-district'
                            size='small'
                            label='แขวง / ตำบล'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err.address?.subDistrict)}
                            {...registerChurchProfileForm(
                              'address.subDistrict'
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='district'
                            size='small'
                            label='เขต / อำเภอ'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err.address?.district)}
                            {...registerChurchProfileForm('address.district')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name='address.province'
                            control={churhProfileFormControl}
                            render={({ field }) => (
                              <Autocomplete
                                ref={
                                  registerChurchProfileForm('address.province')
                                    .ref
                                }
                                disablePortal
                                blurOnSelect
                                id='province'
                                size='small'
                                options={ThaiProvince}
                                value={
                                  ThaiProvince.find(
                                    (p) => p.name_th === field.value
                                  ) ?? null
                                }
                                onChange={(event, value) => {
                                  field.onChange(value ? value.name_th : '')
                                }}
                                fullWidth={true}
                                getOptionLabel={(option) => option.name_th}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='จังหวัด'
                                    error={Boolean(err.address?.province)}
                                  />
                                )}
                                renderOption={(
                                  props,
                                  option,
                                  { inputValue }
                                ) => {
                                  const matches = match(
                                    option.name_th,
                                    inputValue,
                                    {
                                      insideWords: true,
                                    }
                                  )
                                  const parts = parse(option.name_th, matches)

                                  return (
                                    <li {...props}>
                                      <div>
                                        {parts.map(
                                          (part: any, index: number) => (
                                            <span
                                              key={index}
                                              style={{
                                                color: part.highlight
                                                  ? COLOR.PRIMARY_1
                                                  : common.black,
                                              }}
                                            >
                                              {part.text}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    </li>
                                  )
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='postCode'
                            size='small'
                            inputProps={{
                              inputMode: 'numeric',
                              pattern: '[0-9]*',
                              maxLength: 5,
                              onInput: (e) => {
                                // Allow only numeric characters
                                e.currentTarget.value =
                                  e.currentTarget.value.replace(/[^0-9]/g, '')
                              },
                            }}
                            label='รหัสไปรษณีย์'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err.address?.postCode)}
                            {...registerChurchProfileForm('address.postCode')}
                          />
                        </Grid>
                        <Grid
                          item
                          display={{ xs: 'none', md: 'block' }}
                          md={6}
                        ></Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='email'
                            size='small'
                            inputProps={{ inputMode: 'email' }}
                            label='อีเมล'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err['email'])}
                            {...registerChurchProfileForm('email')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='phoneNumber'
                            size='small'
                            inputProps={{
                              inputMode: 'tel',
                              maxLength: 10,
                              onInput: (e) => {
                                // Allow only numeric characters
                                e.currentTarget.value =
                                  e.currentTarget.value.replace(/[^0-9]/g, '')
                              },
                            }}
                            label='เบอร์มือถือ'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err['phoneNumber'])}
                            {...registerChurchProfileForm('phoneNumber')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='website'
                            size='small'
                            inputProps={{ inputMode: 'url' }}
                            label='เว็บไซต์'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err['website'])}
                            {...registerChurchProfileForm('website')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id='facebook'
                            size='small'
                            label='เฟซบุ๊ก'
                            variant='outlined'
                            fullWidth={true}
                            error={Boolean(err['facebook'])}
                            {...registerChurchProfileForm('facebook')}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                  <Divider />
                </Stack>

                <>
                  <Stack
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'24px'}
                    width={'100%'}
                    ref={memberRangeRef}
                  >
                    <div>
                      <Stack
                        alignItems={'flex-start'}
                        flexDirection={'row'}
                        gap={'10px'}
                      >
                        <Typography
                          display='inline'
                          variant='h3'
                          color={COLOR.PRIMARY_1}
                        >
                          *{' '}
                        </Typography>
                        <Typography display='inline' variant='h3'>
                          จำนวนสมาชิกในคริสตจักร
                        </Typography>
                      </Stack>
                      {memberRangeError && (
                        <Typography variant='subtitle1' color='error'>
                          กรุณากรอกจำนวนสมาชิก
                        </Typography>
                      )}
                    </div>

                    <FormControl>
                      <RadioGroup
                        aria-label='member-count'
                        name='member-count'
                        value={memberRange}
                        onChange={(event, val) => {
                          handleMemberRangeChange(val)
                        }}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Stack
                          flexDirection={{ sm: 'column', md: 'row' }}
                          width={'100%'}
                          justifyContent={'space-between'}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value={CHURCH_QUESTION.question1.choice1}
                            label={CHURCH_QUESTION.question1.choice1}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={CHURCH_QUESTION.question1.choice2}
                            label={CHURCH_QUESTION.question1.choice2}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={CHURCH_QUESTION.question1.choice3}
                            label={CHURCH_QUESTION.question1.choice3}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={CHURCH_QUESTION.question1.choice4}
                            label={CHURCH_QUESTION.question1.choice4}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value={CHURCH_QUESTION.question1.choice5}
                            label={CHURCH_QUESTION.question1.choice5}
                          />
                        </Stack>
                      </RadioGroup>
                    </FormControl>

                    <Divider />
                    <Stack flexDirection='column' flexWrap='wrap' gap={'20px'}>
                      <Typography variant='h3'>
                        คุณรู้จักน้ำใจได้อย่างไร?
                      </Typography>
                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Stack
                          flexDirection={{ sm: 'column', md: 'row' }}
                          width={'100%'}
                          justifyContent={'space-between'}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={CHURCH_QUESTION.question2.choice1}
                                checked={selectedChannels.Facebook}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={CHURCH_QUESTION.question2.choice1}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={CHURCH_QUESTION.question2.choice2}
                                checked={selectedChannels.Twitter}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={CHURCH_QUESTION.question2.choice2}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={CHURCH_QUESTION.question2.choice3}
                                checked={selectedChannels.Youtube}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={CHURCH_QUESTION.question2.choice3}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={CHURCH_QUESTION.question2.choice4}
                                checked={selectedChannels.Email}
                                onChange={handleCheckboxChange}
                              />
                            }
                            label={CHURCH_QUESTION.question2.choice4}
                          />
                          <FormControl
                            variant='standard'
                            sx={{ margin: '0px 16px 0px -11px' }}
                          >
                            <Stack flexDirection='row'>
                              <Checkbox
                                name='OtherChecked'
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={selectedChannels.OtherChecked}
                                onChange={handleCheckboxChange}
                              />
                              <Input
                                placeholder='อื่น ๆ'
                                name={CHURCH_QUESTION.question2.choice5}
                                id='other-channel'
                                value={selectedChannels.Other}
                                onChange={(event) =>
                                  handleOtherFieldChange(event.target.value)
                                }
                              />
                            </Stack>
                          </FormControl>
                        </Stack>
                      </FormGroup>
                    </Stack>
                    <Divider />
                    <Stack
                      flexDirection='column'
                      flexWrap='wrap'
                      gap={'20px'}
                      width={'100%'}
                      ref={fileInputRef}
                    >
                      <div>
                        <Stack flexDirection={'row'} gap={'10px'}>
                          <Typography
                            display='inline'
                            variant='h3'
                            color={COLOR.PRIMARY_1}
                          >
                            *{' '}
                          </Typography>
                          <Typography display='inline' variant='h3'>
                            เอกสารแนบ
                          </Typography>
                        </Stack>
                        {isFileMissing && (
                          <Typography variant='subtitle1' color='error'>
                            กรุณาเลือกไฟล์
                          </Typography>
                        )}
                      </div>

                      <Stack
                        flexDirection={'column'}
                        gap={'8px'}
                        textAlign={'left'}
                      >
                        <Typography variant='subtitle1' color={COLOR.GRAY_600}>
                          เอกสารรับรองการจดทะเบียนคริสตจักร และอื่นๆ
                        </Typography>
                        <FileUpload
                          initialFiles={uploadedFiles}
                          files={files}
                          onChange={handleFileChange}
                          setError={handleFileTooBigError}
                        />
                      </Stack>
                      <Divider />
                    </Stack>
                    <Stack alignSelf={'flex-end'}>
                      <Button
                        variant='outlined'
                        type='submit'
                        fullWidth={false}
                        form='church-profile-form'
                        onClick={handleClickSubmit}
                        disabled={sendEditLoading || fileUploadLoading}
                      >
                        <Typography variant='h6'>ยืนยัน</Typography>
                      </Button>
                    </Stack>
                  </Stack>
                </>
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Box height={'24px'} />
        <Footer />
        <ModalWithButton
          id={'submit-edit-church-modal'}
          type={'DEFAULT'}
          open={openSubmitModal && !uploadFileError}
          onClose={() => setOpenSubmitModal(false)}
          handleClickConfirm={handleSubmitEditChurch}
          title='ยืนยันที่จะแก้ไขโบสถ์ใช่ไหม'
          description='โปรดแน่ใจว่าคุณได้ตรวจสอบข้อมูลถูกต้องแล้ว'
          confirmBtnText='ยืนยัน'
          cancleBtnText='ยกเลิก'
          disableCancelBtn={
            sendEditLoading || fileUploadLoading || uploadFileError
          }
          disableConfirmBtn={
            sendEditLoading || fileUploadLoading || uploadFileError
          }
        />

        <NormalModal
          id={'success-submit-edit-church-modal'}
          type={'DEFAULT'}
          open={openSuccessSubmitModal && !uploadFileError}
          onClose={() => navigate(`/churches/${churchID}`)}
          title='การแก้ไขโบสถ์ได้ถูกส่งไปหาแอดมินแล้ว'
          confirmText='กลับไปที่หน้าโบสถ์'
          onClickConfirm={() => navigate(`/churches/${churchID}`)}
        />
        <CustomToast />
      </div>
    )
  } else {
    return <PermissionDeny />
  }
}

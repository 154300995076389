import { Box, Button, Typography } from '@mui/material'
import { COLOR } from '../../constants/color-constant'
import { Role } from '../../models/UserTypes'
import { useUser } from '../../services/UserContext'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import Navbar from '../../components/Navbar/Navbar'
import Gif404 from '../../assets/404/404-animation.gif'

export default function NotFoundPage() {
  const userRole: Role | undefined = useUser().userData?.userrole.userRole
  const getHomePath = () => {
    if (userRole && userRole === 'ADMIN') {
      return '/admin/dashboard'
    } else {
      return '/'
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      height='100vh'
    >
      {userRole! === 'ADMIN' ? <ResponsiveAppBar /> : <Navbar />}

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        alignItems='center'
        textAlign='center'
        height='100%'
        px='8vw'
        bgcolor={'white'}
      >
        <Box
          component='img'
          src={Gif404}
          alt='404 Not Found by freepik'
          maxWidth='300px'
        />
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          textAlign='center'
          gap='4px'
        >
          <Typography
            variant='h3'
            fontWeight='bold'
            color={COLOR.GRAY_600}
            maxWidth='500px'
          >
            ขออภัย คุณไม่สามารถรับชมเนื้อหานี้ได้ หรือ เนื้อหานี้ไม่มีอยู่แล้ว
          </Typography>

          <Typography variant='h6' color={COLOR.GRAY_600}>
            โปรดติดต่อแอดมินหลัก หากคุณคิดว่านี่คือความผิดพลาด
          </Typography>
          <Button
            variant='outlined'
            href={getHomePath()}
            sx={{
              maxWidth: '50vw',
              margin: '8px',
              transition: 'transform 0.2s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            กลับไปที่หน้าหลัก
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

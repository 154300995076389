import {
  Button,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'
import AdminRoleBar from './AdminRoleBar'
import { useContext, useState } from 'react'
import ModalWithButton from '../Modal/ModalWithButton'
import AuthService from '../../services/auth.service'
import axios from 'axios'
import { AdminManagementContext } from '../../pages/Admin/AdminManagementPage'
import { grey } from '@mui/material/colors'
import { toast } from 'react-toastify'
import createAPIErrorMessage from '../../utils/createAPIErrorMessage'

interface AdminListProps {
  userId: string
  firstName: string
  lastName: string
  email: string
  role: string
}

const StyledTableCell = styled(TableCell)({
  padding: '0px',
  paddingLeft: '32px',
  paddingRight: '8px',
})

export default function AdminList(props: AdminListProps) {
  const token = AuthService.getTokenUser()
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''
  const { userId, firstName, lastName, email, role } = props

  const desktop = useMediaQuery('(min-width:680px)')

  const [openDeleteAdminModal, setOpenDeleteAdminModal] =
    useState<boolean>(false)

  const [refreshAdminList] = useContext(AdminManagementContext)

  const handleDeleteAdmin = async () => {
    try {
      await axios.patch(`${API_URL}/admins/${userId}/delete`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-API-KEY': `${API_KEY}`,
        },
      })
      console.log('delete admin success')
      setOpenDeleteAdminModal(false)
      refreshAdminList()
      toast.success('Admin removed successfully')
    } catch (e: any) {
      console.log('error')
      setOpenDeleteAdminModal(false)
      toast.error(`Failed to remove admin. ${createAPIErrorMessage(e)}`)
    }
  }

  return (
    <TableRow key={userId}>
      <TableCell sx={{ flexWrap: 'wrap', width: desktop ? '40%' : '100%' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Column',
            flexWrap: 'wrap',
            alignItems: 'left',
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'Column',
              columnGap: '4px',
              alignItems: 'left',
            }}
          >
            <Typography variant='subtitle1' color={'text.primary'}>
              {firstName + ' '}
              {lastName}
            </Typography>
            {!desktop && (
              <Typography
                variant='body2'
                color={grey[600]}
                sx={{ cursor: 'pointer' }}
              >
                {email}
              </Typography>
            )}

            {!desktop && (
              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                paddingTop={'8px'}
              >
                <AdminRoleBar variant={role} />
                <Button
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'center',
                    justifyContent: 'left',
                    minHeight: 0,
                    minWidth: 0,
                    padding: 0,
                  }}
                  disabled={role === 'SUPER_ADMIN'}
                  onClick={() => setOpenDeleteAdminModal(true)}
                  color='error'
                >
                  <DeleteIcon
                    style={{
                      color: role === 'ADMIN' ? '#FF0038' : '#808080',
                      fontSize: '36px',
                    }}
                  />
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </TableCell>
      {desktop && (
        <>
          <TableCell sx={{ flexWrap: 'wrap', width: '50%' }}>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'Column',
                flexWrap: 'wrap',
                alignItems: 'left',
              }}
            >
              <Typography variant='subtitle1' color={'text.primary'}>
                {email}
              </Typography>
            </Stack>
          </TableCell>

          <TableCell sx={{ width: '30%' }} align='right'>
            <AdminRoleBar variant={role} />
          </TableCell>

          <StyledTableCell
            width={'40px'}
            sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
          >
            <Button
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'center',
                justifyContent: 'right',
                minHeight: 0,
                minWidth: 0,
                padding: 0,
              }}
              disabled={role === 'SUPER_ADMIN'}
              onClick={() => setOpenDeleteAdminModal(true)}
              color='error'
            >
              <DeleteIcon
                style={{
                  color: role === 'ADMIN' ? '#FF0038' : '#808080',
                  fontSize: '36px',
                }}
              />
            </Button>
          </StyledTableCell>
        </>
      )}

      <ModalWithButton
        id={'delete-admin-modal'}
        type={'ERROR'}
        title={`Are you sure to remove ${firstName + ' '}${lastName} from admin?
            `}
        confirmBtnText={'Yes, remove'}
        cancleBtnText={'Cancel'}
        open={openDeleteAdminModal}
        onClose={() => setOpenDeleteAdminModal(false)}
        handleClickConfirm={() => handleDeleteAdmin()}
      />
    </TableRow>
  )
}

import Navbar from '../../components/Navbar/Navbar'
import { Box, Button, Typography } from '@mui/material'
import { COLOR } from '../../constants/color-constant'
import { useUser } from '../../services/UserContext'
import { Role } from '../../models/UserTypes'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'

export default function PermissionDeny() {
  const userRole: Role | undefined = useUser().userData?.userrole.userRole
  const getHomePath = () => {
    if (userRole && userRole === 'ADMIN') {
      return '/admin/dashboard'
    } else {
      return '/'
    }
  }

  return (
    <>
      {userRole! === 'ADMIN' ? <ResponsiveAppBar /> : <Navbar />}

      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        height={'70vh'}
        gap={'4px'}
        padding={'8vw'}
      >
        <Typography
          variant='h3'
          fontWeight='bold'
          color={COLOR.GRAY_600}
          maxWidth='500px'
        >
          ขออภัย คุณไม่สามารถรับชมเนื้อหานี้ได้ หรือ เนื้อหานี้ไม่มีอยู่แล้ว
        </Typography>
        <Typography variant='h6' color={COLOR.GRAY_600}>
          โปรดติดต่อแอดมินหลัก หากคุณคิดว่านี่คือความผิดพลาด
        </Typography>
        <Button
          variant='outlined'
          href={getHomePath()}
          sx={{
            maxWidth: '50vw',
            margin: '8px',
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        >
          กลับไปที่หน้าหลัก
        </Button>
      </Box>
    </>
  )
}

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, ThemeProvider, Typography } from '@mui/material'

import ModalFillReason from '../../components/Modal/ModalFillReason'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { ADMIN_ROUTE } from '../../constants/admin-route-constant'
import AuthService from '../../services/auth.service'
import { useGet, usePatch } from '../../hooks/useFetch'
import axios from 'axios'
import { theme } from '../../constants/theme'
import AdminCreateCampaignModal from '../../components/Admin/modal/AdminCreateCampaignModal'
import AdminNormalModal from '../../components/Admin/modal/AdminNormalModal'
import {
  CreateCampaignForm,
  CreateCampaignFormWithFile,
} from '../../models/admin/AdminCreateCampaignForm'
import { generateViewCampaignAdminRoute } from '../../utils/routeGenerator'
import uploadFile from '../../services/uploadfile.service'
import CampaignProposal from '../../components/CampaignProposal/CampaignProposal'
import { GetCampaignProposalData } from '../../models/CampaignProposal/GetCampaignProposalData'
import transformCampaignProposalData from '../../services/TransformCampaignProposalData'
import createAPIErrorMessage from '../../utils/createAPIErrorMessage'
import CustomToast from '../../components/Toast/CustomToast'
import { toast } from 'react-toastify'

export default function CampaignRequestDetail() {
  document.body.style.background = '#FBFDFF'

  const navigate = useNavigate()
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''
  const token = AuthService.getTokenUser()
  const params = useParams()

  const [textErrorSnackbar, setTextErrorSnackbar] = useState<string>()

  const [openNeedfixModal, setOpenNeedfixModal] = useState<boolean>(false)
  const [openConfirmNeedfixModal, setOpenConfirmNeedfixModal] =
    useState<boolean>(false)

  const [openConfirmlaunch, setOpenConfirmlaunch] = useState<boolean>(false)
  const [campaignId, setCampaignId] = useState<string>()
  const [openConfirmSave, setOpenConfirmSave] = useState<boolean>(false)
  const [openCreateCampaignModal, setopenCreateCampaignModal] =
    useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)

  const [openConflictErrorModal, setConflictErrorModal] =
    useState<boolean>(false)

  const [createForm, setCreateForm] = useState<CreateCampaignForm>()
  const [createType, setCreateType] = useState<string>()

  const [uploadImgLoading, setUploadImgLoading] = useState<boolean>(false)
  const [uploadImgError, setUploadImgError] = useState<boolean>(false)

  const [requestData, requestDataLoading, refresh, statusCode] =
    useGet<GetCampaignProposalData>({
      url: `/campaign-requests/${params.requestId}`,
      jwtToken: token ?? '',
      autoFetch: true,
    })

  const [
    launchCreateRes,
    launchCreateLoading,
    launchCreateReq,
    launchCreateStatusCode,
    launchCreateError,
  ] = usePatch<any>({
    url: `/campaign-requests/${params.requestId}/approve`,
    autoFetch: false,
    jwtToken: AuthService.getTokenUser()!,
  })

  const [
    saveCreateRes,
    saveCreateLoading,
    saveCreateReq,
    saveCreateStatusCode,
    saveCreateError,
  ] = usePatch<any>({
    url: `/campaign-requests/${params.requestId}/save-admin-filled-info`,
    autoFetch: false,
    data: createForm,
    jwtToken: AuthService.getTokenUser()!,
  })

  const handleClickConfirmNeedfix = async (data: string) => {
    const comment = {
      comment: data,
    }
    try {
      await axios.patch(
        `${API_URL}/campaign-requests/${params.requestId}/request-fix`,
        comment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
        }
      )
      // Handle a successful response (e.g., display a success message)
      setOpenNeedfixModal(false)
      setOpenConfirmNeedfixModal(true)
    } catch (error: any) {
      // Handle errors (e.g., display an error message)
      setOpenNeedfixModal(false)
      setTextErrorSnackbar('An error occurred. Please try again later.')
      toast.error(
        `An error occurred. Please try again later. ${createAPIErrorMessage(
          error
        )}`
      )
    }
  }

  const handleClickNeedFix = () => {
    setOpenNeedfixModal(true)
  }

  const handleClickConfirm = () => {
    setopenCreateCampaignModal(true)
  }

  const handleSubmitSaveCreate = () => {
    if (uploadImgError || saveCreateLoading) return
    saveCreateReq()
  }

  useEffect(() => {
    if (createForm) {
      handleSubmitSaveCreate()
    }
  }, [createForm])

  useEffect(() => {
    if (saveCreateStatusCode === -1) return

    if (saveCreateStatusCode === 200 && createType === 'LAUNCH') {
      launchCreateReq()
    } else if (saveCreateStatusCode === 200 && createType === 'SAVE') {
      setopenCreateCampaignModal(false)
      setOpenConfirmSave(true)
    } else {
      setopenCreateCampaignModal(false)
      setTextErrorSnackbar('An error occurred. Please try again later.')

      const msg = saveCreateError ? createAPIErrorMessage(saveCreateError) : ''
      toast.error(`An error occurred. Please try again later. ${msg}`)
    }
  }, [saveCreateStatusCode, createType, saveCreateError])

  useEffect(() => {
    if (launchCreateStatusCode === -1) return

    if (launchCreateStatusCode === 200 && createType === 'LAUNCH') {
      setopenCreateCampaignModal(false)
      setCampaignId(launchCreateRes.campaignId)
      setOpenConfirmlaunch(true)
    } else if (
      launchCreateStatusCode === 409 &&
      createType === 'LAUNCH' &&
      launchCreateError
    ) {
      setopenCreateCampaignModal(false)
      setTextErrorSnackbar(createAPIErrorMessage(launchCreateError))
      setConflictErrorModal(true)
    } else if (launchCreateStatusCode === 400 && createType === 'LAUNCH') {
      setopenCreateCampaignModal(false)
      setTextErrorSnackbar(
        'Some church has not accepted the invitation to be one of the holder churches'
      )
      setConflictErrorModal(true)
    } else {
      setopenCreateCampaignModal(false)
      setTextErrorSnackbar('An error occurred. Please try again later.')

      const msg = launchCreateError
        ? createAPIErrorMessage(launchCreateError)
        : ''
      toast.error(`An error occurred. Please try again later. ${msg}`)
    }
  }, [launchCreateStatusCode, createType, launchCreateError])

  const handleClickConfirmSave = async (data: CreateCampaignFormWithFile) => {
    if (data.imagefile) {
      setUploadImgLoading(true)
      try {
        const response = uploadFile(
          '/campaign-requests/upload-cover-image',
          data.imagefile!,
          'file'
        )
        setCreateType(data.type)
        setCreateForm({
          description: data.description,
          quote: data.quote,
          coverImageUrl: (await response).data.url,
        })
        setUploadImgLoading(false)
      } catch (error: any) {
        toast.error(
          `อัปโหลดรูปภาพไม่สำเร็จ กรุณาลองอีกครั้งในภายหลัง ${createAPIErrorMessage(
            error
          )}`
        )
        setTextErrorSnackbar('อัปโหลดรูปภาพไม่สำเร็จ กรุณาลองอีกครั้งในภายหลัง')
      }
    } else {
      setCreateType(data.type)
      setCreateForm({
        description: data.description,
        quote: data.quote,
        coverImageUrl: data.oldImgURL,
      })
      setUploadImgLoading(false)
    }
  }

  useEffect(() => {
    if (!launchCreateLoading && !saveCreateLoading) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [launchCreateLoading, saveCreateLoading])

  if (requestDataLoading) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <div className='bg-[#FBFDFF]'>
        <ResponsiveAppBar />
        <Container>
          <Box>
            <Box textAlign={'center'}>
              <Typography variant='h2' paddingTop={'24px'}>
                Request: Create Campaign
              </Typography>
            </Box>
            <CampaignProposal
              type='ADMIN-VIEW'
              {...(requestData
                ? {
                    initialData: transformCampaignProposalData(requestData),
                  }
                : {})}
              requestId={params.requestId}
              onClickApprove={handleClickConfirm}
              onClickRequestFix={handleClickNeedFix}
            />
          </Box>
        </Container>
      </div>

      <ModalFillReason
        id={'admin-need-fix-campaign'}
        type={'ERROR'}
        title={'Provide reasons for correction'}
        description={
          'Provide detailed reasons why this campaign needs to be fixed. So that the church can be corrected.'
        }
        confirmText={'Confirm'}
        open={openNeedfixModal}
        onClose={() => setOpenNeedfixModal(false)}
        onClickConfirm={handleClickConfirmNeedfix}
        engVer={true}
        disableConfirmBtn={false}
      />

      <AdminCreateCampaignModal
        id={'admin-create-campaign'}
        type={'DEFAULT'}
        open={openCreateCampaignModal}
        onClose={() => setopenCreateCampaignModal(false)}
        OnClickConfirmSave={handleClickConfirmSave}
        requestId={params.requestId!}
        token={token!}
        loading={loading}
      />

      <AdminNormalModal
        id={'success-needfix'}
        type={'ERROR'}
        title={'Successfully submitted reason'}
        confirmText={'Back to campaign request page'}
        open={openConfirmNeedfixModal}
        onClose={() => {
          setOpenConfirmNeedfixModal(false)
          navigate(ADMIN_ROUTE.CAMPAIGN_REQUEST)
        }}
        onClickConfirm={() => navigate(ADMIN_ROUTE.CAMPAIGN_REQUEST)}
      />

      <AdminNormalModal
        id={'create-error-conflict'}
        type={'ERROR'}
        title={'Failed to create campaign'}
        description={textErrorSnackbar}
        confirmText={'Request Fix'}
        open={openConflictErrorModal}
        onClose={() => {
          setConflictErrorModal(false)
        }}
        onClickConfirm={() => {
          setConflictErrorModal(false)
          setOpenNeedfixModal(true)
        }}
      />

      <AdminNormalModal
        id={'launch-to-namjai'}
        type={'DEFAULT'}
        title={'Campaign successfully created'}
        confirmText={'Back to campaign request page'}
        confirmText2={'View this campaign'}
        open={openConfirmlaunch}
        onClose={() => {
          setOpenConfirmlaunch(false)
        }}
        onClickConfirm={() => navigate(ADMIN_ROUTE.CAMPAIGN_REQUEST)}
        onClickConfirm2={() =>
          navigate(generateViewCampaignAdminRoute(campaignId!))
        }
      />

      <AdminNormalModal
        id={'save-create'}
        type={'DEFAULT'}
        title={'Save campaign information successfully'}
        confirmText={'Back to campaign request page'}
        open={openConfirmSave}
        onClose={() => {
          setOpenConfirmSave(false)
          navigate(ADMIN_ROUTE.CAMPAIGN_REQUEST)
        }}
        onClickConfirm={() => navigate(ADMIN_ROUTE.CAMPAIGN_REQUEST)}
      />

      <CustomToast />
    </ThemeProvider>
  )
}

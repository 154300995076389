import { Box, Container, ThemeProvider, Typography } from '@mui/material'
import { theme } from '../../constants/theme'
import Navbar from '../../components/Navbar/Navbar'

import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../../hooks/useFetch'
import AuthService from '../../services/auth.service'
import { useEffect, useState } from 'react'
import { CampaignProgressDetail } from '../../models/CampaignProgress/CampaignProgressDetail'
import CampaignProgressForm from '../../components/Campaign/CampaignProgress/CapaignProgressForm'
import PermissionDeny from '../ErrorPage/PermissionDeny'
import { GetCampaignProposalData } from '../../models/CampaignProposal/GetCampaignProposalData'
import { useUser } from '../../services/UserContext'

interface progressDetail {
  startDate: any
  endDate: any
  activity: string
  outcome: string
  expenses: number
}

const transformData = (data: CampaignProgressDetail) => {
  const img: string[] = []
  data.imgUrl.map((v, i, a) => (img[i] = v.url))

  const detail: progressDetail[] = []
  data.campaignProgressDetails.forEach((v, i) => {
    const d: progressDetail = {
      startDate: dayjs(v.activityStartDate),
      endDate: dayjs(v.activityEndDate),
      activity: v.activity,
      outcome: v.outcome,
      expenses: v.expenses,
    }
    detail[i] = d
  })

  const transformed = {
    imageUrls: img,
    progressDetails: detail,
  }

  return transformed
}

export default function EditCampaignProgressUpdate() {
  const token = AuthService.getTokenUser()
  const param = useParams()
  const requestId = param.requestId
  const userEmail = useUser().userData?.userdetail.email

  const [checkPermission, setCheckPermission] = useState<boolean>(false)

  const [progressDetail, getDeatailLoading, getDetail, getDetailStatusCode] =
    useGet<CampaignProgressDetail>({
      url: `/campaign-requests/${requestId}/progress`,
      jwtToken: token ? token : '',
      autoFetch: true,
    })

  const [reqDetail, getreqDetailLoading, getreqDetail, getreqDetailStatusCode] =
    useGet<GetCampaignProposalData>({
      url: `/campaign-requests/${requestId}`,
      jwtToken: token ? token : '',
      autoFetch: true,
    })

  const hasMatchingEmail = reqDetail?.campaignRequestContactPerson?.some(
    (person) => person.email === userEmail
  )

  useEffect(() => {
    if (hasMatchingEmail != null) {
      setCheckPermission(true)
    }
  }, [hasMatchingEmail])

  if (getDeatailLoading) return null

  return (
    <ThemeProvider theme={theme}>
      {' '}
      {!checkPermission ? (
        <Navbar />
      ) : !hasMatchingEmail ? (
        <PermissionDeny />
      ) : (
        <>
          <Navbar />
          <Container>
            <Box textAlign={'center'}>
              <Typography variant='h2' paddingTop={'24px'}>
                ส่งรายงานความคืบหน้าโครงการ
              </Typography>
              <Box height={'20px'}></Box>
              <CampaignProgressForm
                type={'EDIT'}
                status={reqDetail?.campaignRequestStatus}
                id={requestId!}
                {...(progressDetail
                  ? { initialData: transformData(progressDetail) }
                  : {})}
                {...(progressDetail
                  ? { uploadedImagesMetadata: progressDetail.imgUrl }
                  : {})}
              />
            </Box>
          </Container>
        </>
      )}
    </ThemeProvider>
  )
}

import { Box, Typography, Divider, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { COLOR } from '../constants/color-constant'
import { useEffect, useState } from 'react'
import { common } from '@mui/material/colors'
import SelectTotal from './PaymentSection/SelectTotal'
import ThankYouLetterSection, {
  ThankyouLetterFormInput,
  ThankyouLetterFormSchema,
} from './PaymentSection/ThankYouLetterSection'

import { PaymentData, UpdatedFundingDetail } from '../models/campaign'
import { useGet, usePost } from '../hooks/useFetch'
import dayjs from 'dayjs'
import PaymentSlipAndChecklistForm, {
  paymentSlipFormInput,
  paymentSlipFormSchema,
} from './PaymentSection/PaymentSlipAndChecklistForm'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'
import AuthService from '../services/auth.service'
import { ROUTE } from '../constants/route-constant'
import axios from 'axios'
import CustomToast from './Toast/CustomToast'
import createAPIErrorMessage from '../utils/createAPIErrorMessage'

interface PaymentProps {
  campaignName: string
  campaignId: string
  initialData?: any
  isFunding?: boolean
}
export default function Payment(props: PaymentProps) {
  const { campaignName, campaignId, initialData, isFunding } = props

  const token = AuthService.getTokenUser()
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

  const [openThankYouLetterSection, setOpenThankYouLetterSection] =
    useState<boolean>(false)
  const [openPaymentSection, setOpenPaymentSection] = useState<boolean>(false)
  const [readPolicy, setReadPolicy] = useState<boolean>(false)
  const navigate = useNavigate()

  //Campaign ID FOR TEST
  //FFzasrbVSDWSF_XZEe91EA#JOIN

  const [toUploadData, setToUploadData] = useState<PaymentData>({
    campaignId: campaignId,
    total: 0,
    donorType: 'PERSONAL',
    donorFirstName: '',
    donorLastName: '',
    donorPhoneNumber: '',
    donorAddress: '',
    donorTaxNumber: '',
    slipFirstName: '',
    slipLastName: '',
    slipTransactionDateTime: dayjs().unix(),
    slipImageUrl: '',
    receiveTaxDeductionForm: false,
    receiveThankYouLetter: false,
    anonymous: false,
    receiveMarketingEmail: false,
  })

  // console.log('toUploadData', toUploadData)

  //Thank you letter form controls
  const {
    register: registerThankyouLetter,
    getValues: getValuesThankyouLetter,
    handleSubmit: handleSubmitThankyouletter,
    clearErrors: clearErrorsThankyouletter,
    formState: { errors: errorsThankyouLetter },
  } = useForm<ThankyouLetterFormInput>({
    resolver: zodResolver(ThankyouLetterFormSchema),
    defaultValues: initialData
      ? initialData
      : {
          thankyouLetterDetails: [
            {
              donorFirstName: toUploadData.donorFirstName,
              donorLastName: toUploadData.donorLastName,
              donorPhoneNumber: toUploadData.donorPhoneNumber,
              donorAddress: toUploadData.donorAddress,
              donorTaxNumber: toUploadData.donorTaxNumber,
            },
          ],
        },
  })

  //Slip letter form controls
  const {
    register: registerSlip,
    getValues: getValuesSlip,
    handleSubmit: handleSubmitSlip,
    clearErrors: clearErrorsSlip,
    formState: { errors: errorsSlip },
    control: controlSlip,
  } = useForm<paymentSlipFormInput>({
    resolver: zodResolver(paymentSlipFormSchema),
    defaultValues: initialData
      ? initialData
      : {
          thankyouLetterDetails: [
            {
              slipFirstName: toUploadData.slipFirstName,
              slipLastName: toUploadData.slipLastName,
              slipTransactionDateTime: toUploadData.slipTransactionDateTime,
            },
          ],
        },
  })

  const combineData = () => {
    if (
      getValuesSlip().slipFirstName !== '' &&
      getValuesSlip().slipLastName !== ''
    ) {
      const datas = {
        ...getValuesThankyouLetter(),
        ...getValuesSlip(),
        campaignId: toUploadData.campaignId,
        total: toUploadData.total,
        donorType: toUploadData.donorType,
        slipImageUrl: toUploadData.slipImageUrl,
        receiveTaxDeductionForm: toUploadData.receiveTaxDeductionForm,
        receiveThankYouLetter: toUploadData.receiveThankYouLetter,
        anonymous: toUploadData.anonymous,
        receiveMarketingEmail: toUploadData.receiveMarketingEmail,
        slipTransactionDateTime:
          getValuesSlip().slipTransactionDateTime.valueOf(),
      }

      setToUploadData(datas)

      // processDonation()
    }
  }

  useEffect(() => {
    //STATE ISSUE
    //WILL FIX LATER
    if (toUploadData.donorFirstName !== '') {
      processDonation()
    }
  }, [toUploadData])

  const processDonation = () => {
    // console.log('datas', toUploadData)
    refreshDonation()
  }

  const [fundingDetail] = useGet<UpdatedFundingDetail>({
    url: `/campaigns/${campaignId}/funding-detail`,
    autoFetch: true,
  })

  //Handle Add to bag
  const handleClickAddToBag = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/namjai-bag/add/${campaignId}?amount=${toUploadData.total}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
        }
      )
      if (response.status === 200) {
        navigate(ROUTE.NAMJAI_BAG)
      }
    } catch (err: any) {
      const msg = createAPIErrorMessage(err)
      const statusCode = err.response.status
      toast.error(
        `ไม่สามารถเพิ่มโครงการลงกระเป๋าน้ำใจได้ ${msg} :ERROR(${statusCode})`
      )
      console.log(err)
    }
  }

  //Handle Pay
  const [
    confirmDonation,
    confirmingDonation,
    refreshDonation,
    confirmDonationStatusCode,
    confirmDonationError,
  ] = usePost<PaymentData>({
    url: '/receipts/confirm-donation',
    data: toUploadData,
    jwtToken: token ?? '',
    autoFetch: false,
  })

  useEffect(() => {
    if (confirmDonationStatusCode === 200 && !confirmingDonation) {
      navigate(ROUTE.THANKYOU)
    } else if (confirmDonationError) {
      const msg = createAPIErrorMessage(confirmDonationError)
      toast.error(`An error has occured. ${msg}`)
    }
    console.log('confirm', confirmDonationStatusCode)
  }, [confirmDonationStatusCode])

  if (isFunding) {
    return (
      <Box
        bgcolor={'#EFFBFF'}
        display={'flex'}
        justifyContent={'center'}
        padding={'40px 0px'}
      >
        {(confirmingDonation && confirmDonationStatusCode === -1) ||
        confirmDonationStatusCode === 200 ? (
          <CircularProgress />
        ) : (
          <Box
            id='namjai-payment-section'
            display={'flex'}
            padding={'20px 40px'}
            flexDirection={'column'}
            alignItems={'flex-end'}
            gap={'12px'}
            sx={{
              borderRadius: '16px',
              background: common.white,
            }}
            width={'90vw'}
            boxShadow={'0px 4px 10px 5px rgba(0, 0, 0, 0.10)'}
          >
            <Box
              id='namjai-payment-section-form-area'
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={'10px'}
              width={'100%'}
            >
              {/** HEADING */}
              <Box
                id='namjai-payment-section-form-area-heading'
                display={'flex'}
                padding={'12px 0px'}
                flexDirection={'column'}
                alignItems={'center'}
                // gap={'6px'}
                alignSelf={'stretch'}
                width={'100%'}
              >
                <Box display={'flex'} flexDirection={'row'} gap={'4px'}>
                  <Typography variant='h4' color={COLOR.BLACK_TEXT}>
                    มอบ
                  </Typography>
                  <Typography variant='h4' color={COLOR.PRIMARY_1}>
                    "น้ำใจ"
                  </Typography>
                  <Typography variant='h4' color={COLOR.BLACK_TEXT}>
                    ให้กับ
                  </Typography>
                </Box>

                <Typography variant='h1'>โครงการ {campaignName}</Typography>
              </Box>
              <Divider sx={{ width: '100%' }} />
              {/** Select Total */}
              <CustomToast />
              <SelectTotal
                toUploadData={toUploadData}
                setToUploadData={setToUploadData}
                setOpenThankYouLetterSection={setOpenThankYouLetterSection}
                addToBag={handleClickAddToBag}
              />

              <Box
                id='namjai-payment-section-form-area-letter'
                display={'flex'}
                padding={'16px'}
                flexDirection={'column'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                gap={'12px'}
                alignSelf={'stretch'}
              >
                {openThankYouLetterSection && (
                  <ThankYouLetterSection
                    openPaymentSection={openPaymentSection}
                    setOpenPaymentSection={setOpenPaymentSection}
                    toUploadData={toUploadData}
                    setToUploadData={setToUploadData}
                    handleSubmit={handleSubmitThankyouletter}
                    register={registerThankyouLetter}
                    clearErrors={clearErrorsThankyouletter}
                    errors={errorsThankyouLetter}
                  />
                )}
              </Box>
            </Box>
            {openPaymentSection && (
              <>
                <Divider sx={{ width: '100%' }} />
                <PaymentSlipAndChecklistForm
                  toUploadData={toUploadData}
                  setToUploadData={setToUploadData}
                  readPolicy={readPolicy}
                  setReadPolicy={setReadPolicy}
                  fundingDetail={fundingDetail ?? undefined}
                  handleSubmit={handleSubmitSlip}
                  register={registerSlip}
                  clearErrors={clearErrorsSlip}
                  errors={errorsSlip}
                  control={controlSlip}
                  onPass={combineData}
                />
              </>
            )}
          </Box>
        )}
      </Box>
    )
  } else {
    return (
      <Box
        bgcolor={'#EFFBFF'}
        display={'flex'}
        justifyContent={'center'}
        padding={'40px 0px'}
        textAlign={'center'}
      >
        <Typography variant='h3'>โครงการปิดรับการบริจาคแล้ว</Typography>
      </Box>
    )
  }
}

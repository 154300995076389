import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
  TextField,
  useMediaQuery,
  Stack,
} from '@mui/material'
import React, { useState } from 'react'
import { ROUTE } from '../../constants/route-constant'
import {
  BankAccount,
  PaymentData,
  UpdatedFundingDetail,
} from '../../models/campaign'
import { z, TypeOf } from 'zod'
import dayjs, { Dayjs } from 'dayjs'

import {
  FieldErrors,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  Controller,
  Control,
} from 'react-hook-form'
import { COLOR } from '../../constants/color-constant'
import logo from '../../assets/icons/logo.svg'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import UploadPaymentSlip from './UploadPaymentSlip'
import uploadFile from '../../services/uploadfile.service'
import { toast } from 'react-toastify'
import createAPIErrorMessage from '../../utils/createAPIErrorMessage'
import { Link } from 'react-router-dom'
import BankLogo from '../BankLogo/BankLogo'
import { formatBankAccountNumber } from '../../utils/formatString'

type ORCodeImageProps = {
  url: string
  accountName: string
  bankName: string
  accountNumber: string
}

const QRCodeImage: React.FC<ORCodeImageProps> = ({
  url,
  accountName,
  bankName,
  accountNumber,
}) => (
  <img
    src={url}
    width='80%'
    alt={`QR code to pay for account ${accountName}, Bank Name: ${bankName}, Account Number: ${accountNumber}`}
  />
)

type BankDetailsProps = {
  bankAccount: BankAccount
}

function BankDetails({ bankAccount }: BankDetailsProps) {
  return (
    <Stack
      flexDirection='row'
      width='100%'
      justifyContent='center'
      alignItems='center'
      gap='20px'
    >
      <BankLogo bankName={bankAccount.bankName || ''} />
      <Stack flexDirection='column'>
        <Typography variant='h5'>{bankAccount.bankName}</Typography>
        <Typography variant='body1'>{bankAccount.accountName}</Typography>
        <span>
          <Typography variant='body1' sx={{ display: 'inline-block' }}>
            {'เลขที่บัญชี: '}
          </Typography>
          <Typography variant='subtitle1' sx={{ display: 'inline-block' }}>
            {formatBankAccountNumber(bankAccount.accountNumber)}
          </Typography>
        </span>
      </Stack>
    </Stack>
  )
}

type PaymentOptionDisplayProps = {
  fundingDetail: UpdatedFundingDetail | undefined
}

function PaymentOptionDisplay({ fundingDetail }: PaymentOptionDisplayProps) {
  if (!fundingDetail?.bankAccount) {
    return <img src={logo} width='80%' alt='Namjai Logo' />
  }

  const { bankAccount } = fundingDetail

  return (
    <>
      <BankDetails bankAccount={bankAccount} />
      {bankAccount.qrCodeUrl && (
        <QRCodeImage
          url={bankAccount.qrCodeUrl}
          accountName={bankAccount.accountName}
          bankName={bankAccount.bankName}
          accountNumber={bankAccount.accountNumber}
        />
      )}
    </>
  )
}

interface SlipForm {
  slipFirstName: string
  slipLastName: string
  slipTransactionDateTime: dayjs.Dayjs
}

export const paymentSlipFormSchema = z
  .object({
    slipFirstName: z.string().trim().nonempty({ message: '*ต้องกรอก' }),
    slipLastName: z.string().trim(),
    slipTransactionDateTime: z.custom<Dayjs>(),
  })
  .refine(
    (data) => {
      if (!data.slipTransactionDateTime) {
        return false
      }
    },
    {
      message: '*ต้องกรอก',
    }
  )
export type paymentSlipFormInput = TypeOf<typeof paymentSlipFormSchema>

interface PaymentSlipAndChecklistFormProps {
  toUploadData: PaymentData
  setToUploadData: React.Dispatch<React.SetStateAction<PaymentData>>
  readPolicy: boolean
  setReadPolicy: React.Dispatch<React.SetStateAction<boolean>>
  fundingDetail: UpdatedFundingDetail | undefined
  register: UseFormRegister<SlipForm>
  handleSubmit: UseFormHandleSubmit<SlipForm>
  clearErrors: UseFormClearErrors<SlipForm>
  errors: FieldErrors<SlipForm>
  control: Control<SlipForm>
  onPass: () => void
}
export default function PaymentSlipAndChecklistForm(
  props: PaymentSlipAndChecklistFormProps
) {
  const {
    toUploadData,
    setToUploadData,
    readPolicy,
    setReadPolicy,
    fundingDetail,
    register,
    handleSubmit,
    clearErrors,
    errors,
    control,
    onPass,
  } = props

  const matches = useMediaQuery('(min-width:890px)')
  const [selectedFile, setSelectedFile] = useState<File | null>()
  const [fileUploadLoading, setFileUploadLoading] = useState(false)

  const submitHandler = () => {
    onPass()
  }

  const handleFile = ({ files }: { files: File | null; haveFile: boolean }) => {
    setSelectedFile(files)
    uploadFiles(files)
  }

  const uploadFiles = async (files: File | null): Promise<string[]> => {
    setFileUploadLoading(true)
    if (files) {
      try {
        const responses = await uploadFile(
          '/receipts/upload-slip',
          files,
          'file'
        )

        const cdnUrls = responses.data.url
        setToUploadData({ ...toUploadData, slipImageUrl: cdnUrls })
        return cdnUrls
      } catch (error: any) {
        let toastErrorMsg = `ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองอีกครั้งในภายหลัง`
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response)
          const msg = createAPIErrorMessage(error)
          toastErrorMsg = `ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองอีกครั้งในภายหลัง ${msg} :ERROR(${error.response.status})`
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        toast.error(toastErrorMsg)
      } finally {
        setFileUploadLoading(false)
      }
    }

    return []
  }

  return (
    <>
      <Box
        id='namjai-payment-section-slip-area'
        display={'flex'}
        // flexDirection={'row'}
        width={'100%'}
        gap={'40px'}
        component='form'
        onSubmit={handleSubmit(submitHandler)}
        flexDirection={matches ? 'row' : 'column'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={matches ? '30%' : '100%'}
          alignItems={'center'}
          gap={'20px'}
          height={'100%'}
          justifyContent={'center'}
        >
          <PaymentOptionDisplay fundingDetail={fundingDetail} />
          <Stack alignItems='center'>
            <Typography variant='h3'>จำนวนเงิน</Typography>
            <Typography variant='h3'>
              {toUploadData.total?.toLocaleString()} บาท
            </Typography>
          </Stack>
          <Typography
            variant='subtitle2'
            color={COLOR.PRIMARY_1}
            align='center'
          >
            *โปรดตรวจสอบยอดเงินก่อนการโอนทุกครั้ง หากจำนวนเงินไม่ตรง
            ระบบจะถือว่าการบริจาคไม่สำเร็จ
          </Typography>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          width={matches ? '70%' : '100%'}
          gap={'12px'}
        >
          <Typography variant='h4'>กรุณากรอกหลักฐานการชำระเงิน</Typography>
          <Box
            display={'flex'}
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={'flex-start'}
            gap={'12px'}
            alignSelf={'stretch'}
          >
            <TextField
              id='thankyou-letter-name'
              label='ชื่อ'
              variant='outlined'
              size='small'
              fullWidth
              {...register('slipFirstName')}
              onChange={() => clearErrors('slipFirstName')}
              error={Boolean(errors.slipFirstName)}
            />
            <TextField
              id='thankyou-letter-surname'
              label='สกุล'
              variant='outlined'
              size='small'
              fullWidth
              {...register('slipLastName')}
              onChange={() => clearErrors('slipLastName')}
              error={Boolean(errors.slipLastName)}
            />
          </Box>

          <Controller
            name={'slipTransactionDateTime'}
            control={control}
            defaultValue={dayjs()}
            render={({
              field: { onChange, value, ...restField },
              fieldState: { error },
            }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale='th'
              >
                <DateTimePicker
                  label='วัน และ เวลาที่ชำระเงิน'
                  value={dayjs(value)}
                  {...restField}
                  onChange={(value) => {
                    onChange(value)
                  }}
                  slotProps={{
                    textField: {
                      error: Boolean(error),
                      size: 'small',
                    },
                  }}
                  disabled={false}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#212121',
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />

          <UploadPaymentSlip
            onChange={handleFile}
            imgURL={null}
            toUploadData={toUploadData}
            setToUploadData={setToUploadData}
            fileUploadLoading={fileUploadLoading}
          />
          {!selectedFile && (
            <Typography variant='body1' color={'error'}>
              * กรุณาอัปโหลดสลิป
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        id='namjai-payment-section-checkbox-area'
        display={'flex'}
        padding={'0px 24px'}
        flexDirection={'column'}
        justifyContent={'flex-end'}
        alignItems={'flex-end'}
        gap={'10px'}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  setToUploadData({
                    ...toUploadData,
                    receiveTaxDeductionForm:
                      !toUploadData.receiveTaxDeductionForm,
                  })
                }
              />
            }
            label={
              <Typography variant='h6'>ฉันต้องการรับใบลดหย่อนลดภาษี</Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  setToUploadData({
                    ...toUploadData,
                    receiveThankYouLetter: !toUploadData.receiveThankYouLetter,
                  })
                }
              />
            }
            label={
              <Typography variant='h6'>ฉันต้องการรับจดหมายขอบคุณ</Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  setToUploadData({
                    ...toUploadData,
                    anonymous: !toUploadData.anonymous,
                  })
                }
              />
            }
            label={<Typography variant='h6'>บริจาคแบบไม่ระบุตัวตน</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                onClick={() =>
                  setToUploadData({
                    ...toUploadData,
                    receiveMarketingEmail: !toUploadData.receiveMarketingEmail,
                  })
                }
              />
            }
            label={
              <Typography variant='h6'>
                ฉันยอมรับที่จะรับอีเมลจาก NamJai
              </Typography>
            }
          />
          <FormControlLabel
            required
            sx={{
              alignItems: 'flex-start',
            }}
            control={
              <Checkbox onClick={() => setReadPolicy(!readPolicy)} required />
            }
            label={
              <Box
                display={'flex'}
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={'2px'}
              >
                <Typography variant='h6' sx={{ lineHeight: { md: '42px' } }}>
                  ฉันได้อ่าน และ รับทราบ
                </Typography>
                <Link
                  to={ROUTE.PRIVACY_POLICY}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Typography
                    variant='h6'
                    color={'primary'}
                    sx={{
                      display: 'inline',
                      cursor: 'pointer',
                      lineHeight: { md: '42px' },
                    }}
                  >
                    ข้อตกลงนโยบายความเป็นส่วนตัวของผู้ใช้
                  </Typography>
                </Link>
              </Box>
            }
          />
        </FormGroup>
        <Button
          variant='outlined'
          color='secondary'
          disabled={!readPolicy || fileUploadLoading}
          form='namjai-payment-section-slip-area'
          type='submit'
          onClick={onPass}
        >
          <Typography variant='h6'>ยืนยันการบริจาค</Typography>
        </Button>
      </Box>
    </>
  )
}

import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { ROUTE } from '../constants/route-constant'

const useAuthMiddleware = () => {
  const navigate = useNavigate()
  const [tokenChecked, setTokenChecked] = useState(false)
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      // Redirect to the login page if not logged in
      navigate(ROUTE.LOGIN)
    }
    setTokenChecked(true)
  }, [navigate])
  return tokenChecked
}

const useAuthNotEnterMiddleware = () => {
  const navigate = useNavigate()
  const [tokenChecked, setTokenChecked] = useState(false)
  useEffect(() => {
    const token = localStorage.getItem('token')
    // Redirect to the HOME page if logged in
    if (token) {
      navigate('/')
    }
    setTokenChecked(true)
  }, [navigate])
  return tokenChecked
}

export { useAuthMiddleware, useAuthNotEnterMiddleware }

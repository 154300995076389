import { object, string, TypeOf } from 'zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Navbar from '../components/Navbar/Navbar'
import { ReactComponent as Sendmail } from '../assets/icons/sendmailnoti.svg'
import ModalNotification from '../components/Modal/ModalNotification'
import loginBaner from '../assets/images/login_banner.png'
import {
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
  Error,
} from '@mui/icons-material'
import {
  Button,
  Card,
  Box,
  Stack,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  CssBaseline,
  TextField,
  Typography,
} from '@mui/material'

import AuthService from '../services/auth.service'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE } from '../constants/route-constant'
import { COLOR } from '../constants/color-constant'
const forgotPasswordSchema = object({
  email: string().min(1, 'Email is required').email('Invalid email address'),
})

const resetPasswordSchema = object({
  newpass: string()
    .nonempty('ต้องกรอกรหัสผ่าน')
    .min(8, 'รหัสผ่านต้องมีความยาวมากกว่า 8 ตัวอักษร')
    .max(20, 'รหัสผ่านต้องมีความยาวไม่เกิน 20 ตัวอักษร'),
  passwordConfirm: string().nonempty('กรุณายืนยันรหัสผ่าน'),
})
  .refine((data) => data.newpass === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'รหัสผ่านไม่ตรงกัน',
  })
  .refine((data) => /[a-zA-Z0-9!@#&()-_{}:;',?/*~$^+=<>]$/.test(data.newpass), {
    path: ['password'],
    message:
      "รองรับเฉพาะตัวอักษร (a-z),(A-Z), เลข 0-9 และ \n อักขระพิเศษ  !@#&()-_{}:;',?/*~$^+=<>",
  })

export type ForgotPasswordInput = TypeOf<typeof forgotPasswordSchema>
export type ResetPasswordInput = TypeOf<typeof resetPasswordSchema>

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const resetcode = queryParams.get('resetcode')
  const [isSendedToBackend, setIsSendedToBackend] = useState<boolean>(false)
  const [showEmail, setShowEmail] = useState<String>('')
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const [isEmailNotFound, setIsEmailNotFound] = useState(false)
  const [isInvalidResetLink, setIsInvalidResetLink] = useState(false)
  const [showPasswordChangeSuccessModal, setShowChangePasswordSuccesModal] =
    useState(false)

  const handleShow1 = () => setShowPassword1((prev) => !prev)
  const handleShow2 = () => setShowPassword2((prev) => !prev)

  const {
    reset: resetFormForgotPassword,
    handleSubmit: handleSubmitForgot,
    register: registerForgot,
    formState: {
      isSubmitSuccessful: isSubmitSuccessfulForgot,
      errors: errorsForgot,
    },
  } = useForm<ForgotPasswordInput>({
    resolver: zodResolver(forgotPasswordSchema),
  })

  const {
    reset: resetFormResetPassword,
    handleSubmit: handleSubmitReset,
    register: registerReset,
    formState: {
      isSubmitSuccessful: isSubmitSuccessfulReset,
      errors: errorsReset,
    },
  } = useForm<ResetPasswordInput>({
    resolver: zodResolver(resetPasswordSchema),
  })

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [countdown, setCountdown] = useState(60)
  const resetPasswordState = resetcode !== null

  useEffect(() => {
    if (isSubmitSuccessfulForgot) {
      resetFormForgotPassword()
    }
    if (isSubmitSuccessfulReset) {
      resetFormResetPassword()
    }
  }, [
    isSubmitSuccessfulForgot,
    isSubmitSuccessfulReset,
    resetFormResetPassword,
    resetFormForgotPassword,
  ])

  const startCountdown = () => {
    setIsButtonDisabled(true)
    setCountdown(60) // Reset countdown to 60 seconds

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(interval)
          setIsButtonDisabled(false) // Re-enable button
          return 0
        }
        return prevCountdown - 1
      })
    }, 1000)
  }

  const forgotPassword = async (data: ForgotPasswordInput) => {
    setShowEmail(data.email)
    try {
      startCountdown() // Start the countdown
      const response = await AuthService.getResetPass(data.email)
      // wait api
      setIsSendedToBackend(true)
      await new Promise((resolve) => setTimeout(resolve, 15000))
      setIsSendedToBackend(false)
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        setIsEmailNotFound(true)
      }

      console.error('error', error)
    }
  }

  const setPassword = async (data: ResetPasswordInput) => {
    try {
      await AuthService.getSetPass(data.newpass, resetcode)

      setShowChangePasswordSuccesModal(true)
      await new Promise((resolve) => setTimeout(resolve, 5000))
      setShowChangePasswordSuccesModal(false)
      navigate(ROUTE.LOGIN)
    } catch (error: any) {
      console.error('error', error)
      if (error.response && error.response.status === 404) {
        setIsInvalidResetLink(true)
      }
    }
  }

  const onSubmitGetCode = handleSubmitForgot((values) => {
    forgotPassword(values)
  })

  const onSubmitReset = handleSubmitReset((values) => {
    setPassword(values)
  })

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Navbar />
        <ModalNotification
          id={'send-reset-password'}
          type={'SUCCESS'}
          SvgIcon={
            <Sendmail
              style={{
                height: 80,
              }}
            />
          }
          title={`ลิงก์เปลี่ยนรหัสผ่านได้ถูกส่งไปที่ อีเมล: ${showEmail}`}
          description='กรุณาตรวจสอบอีเมลของท่าน'
          open={isSendedToBackend}
          onClose={() => setIsSendedToBackend(false)}
        />
        <ModalNotification
          id={'change-password-success'}
          type={'SUCCESS'}
          SvgIcon={
            <CheckCircleOutline
              color='secondary'
              sx={{
                fontSize: '80px',
              }}
            />
          }
          title={`เปลี่ยนรหัสผ่านสำเร็จ!`}
          description='ระบบจะพาท่านไปหน้าเข้าสู่ระบบ'
          open={showPasswordChangeSuccessModal}
          onClose={() => {}}
        />
        <ModalNotification
          id={'invalid-reset-link'}
          type={'ERROR'}
          SvgIcon={
            <Error
              color='error'
              sx={{
                fontSize: '80px',
              }}
            />
          }
          title={`ลิงก์เปลี่ยนรหัสผ่านไม่ถูกต้อง`}
          description='กรุณาลองใหม่อีกครั้ง'
          open={isInvalidResetLink}
          onClose={() => {
            setIsInvalidResetLink(false)
            navigate(ROUTE.FORGOT_PASSWORD)
          }}
        />
        <div style={{ flex: '1', overflowY: 'auto' }}>
          <Grid container component='main' sx={{ height: '100%' }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={false}
              md={4}
              lg={6}
              sx={{
                backgroundImage: 'url(' + loginBaner + ')',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                  t.palette.mode === 'light'
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={6}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 3,
                  mx: { xl: 20, lg: 6, md: 6, sm: 6, xs: 3 },
                  display: 'flex',
                  flexWrap: 'nowarp',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box width={'100%'} my={20}>
                  {!resetPasswordState && (
                    <Card
                      variant='outlined'
                      sx={{ p: 4, borderRadius: '16px', boxShadow: 3 }}
                    >
                      <Typography variant='h3' color='textPrimary' gutterBottom>
                        ลืมรหัสผ่านใช่ไหม?
                      </Typography>
                      <Typography
                        variant='h6'
                        color='textSecondary'
                        gutterBottom
                      >
                        กรุณากรอกอีเมลของท่านเพื่อรับลิงก์เปลี่ยนรหัสผ่าน
                      </Typography>
                      <form onSubmit={onSubmitGetCode}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          label='อีเมล'
                          {...registerForgot('email')}
                          error={Boolean(errorsForgot.email || isEmailNotFound)}
                          helperText={
                            isEmailNotFound
                              ? 'ไม่พบอีเมลนี้ในระบบ'
                              : errorsForgot.email?.message || ''
                          }
                        />
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          fullWidth
                          style={{ marginTop: '1rem' }}
                          disabled={isButtonDisabled}
                        >
                          ส่งลิงก์เปลี่ยนรหัสผ่าน
                        </Button>
                        {isButtonDisabled && (
                          <div>
                            <Typography
                              variant='body2'
                              color='textSecondary'
                              align='center'
                            >
                              กรุณาตรวจสอบอีเมลของท่าน
                            </Typography>
                            <Typography
                              variant='body2'
                              color='textSecondary'
                              align='center'
                            >
                              สามารถกดส่งอีกครั้งในอีก {countdown} วินาที
                            </Typography>
                          </div>
                        )}
                      </form>
                    </Card>
                  )}
                  {resetPasswordState && (
                    <Card
                      variant='outlined'
                      sx={{ p: 4, borderRadius: '16px', boxShadow: 3 }}
                    >
                      <Typography
                        variant='h4'
                        align='center'
                        color='textPrimary'
                        gutterBottom
                      >
                        แก้ไขรหัสผ่าน
                      </Typography>
                      <form onSubmit={onSubmitReset}>
                        <Stack
                          spacing={{ xs: 1, sm: 2 }}
                          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                          useFlexGap
                          justifyContent={'space-between'}
                          flexWrap='wrap'
                          marginBottom={'25px'}
                        >
                          <TextField
                            sx={{ mb: 1, label: { color: COLOR.DIVIDER_MAIN } }}
                            label={'รหัสผ่านใหม่'}
                            fullWidth
                            required
                            type={showPassword1 ? 'text' : 'password'}
                            error={Boolean(errorsReset.newpass)}
                            helperText={errorsReset.newpass?.message}
                            {...registerReset('newpass')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton onClick={handleShow1} edge='end'>
                                    {showPassword1 ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            sx={{ mb: 1, label: { color: COLOR.DIVIDER_MAIN } }}
                            label={'ยืนยันรหัสผ่านใหม่'}
                            fullWidth
                            required
                            type={showPassword2 ? 'text' : 'password'}
                            error={Boolean(errorsReset.passwordConfirm)}
                            helperText={errorsReset.passwordConfirm?.message}
                            {...registerReset('passwordConfirm')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <IconButton onClick={handleShow2} edge='end'>
                                    {showPassword2 ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>

                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          fullWidth
                          style={{ marginTop: '1rem' }}
                          disabled={isButtonDisabled}
                        >
                          เปลี่ยนรหัสผ่าน
                        </Button>
                      </form>
                    </Card>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordPage

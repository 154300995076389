import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react'
import AuthService from './auth.service'
import { UserData } from '../models/UserTypes'

// Define the shape of the context
interface UserContextType {
  userData: UserData | null
  handleLogin: () => void
}

// Create the context
export const UserContext = createContext<UserContextType | undefined>(undefined)

// Custom hook to access the context
export function useUser() {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

// Context Provider component
interface UserProviderProps {
  children: ReactNode
}

export function UserProvider({ children }: UserProviderProps) {
  //share data
  const [userData, setUserData] = useState<UserData | null>(null)

  const fetchData = async () => {
    const token = AuthService.getTokenUser()
    if (!token) {
      return // No need to proceed if there's no token
    }

    try {
      const response = await AuthService.jwtFetchdata(token)

      if (!response || !response.userdetail || !response.userrole) {
        console.error('Invalid response structure')
        return
      }

      const modifiedUserRole = { ...response.userrole }
      // if churchID is not null -> member of some church
      if (response.userrole.churchId !== null) {
        modifiedUserRole.userRole = 'CHURCH'
      } else if (response.userrole.userRole === 'ADMIN' || 'SUPER_ADMIN') {
        // clear the church Member Role for safety.
        modifiedUserRole.churchMemberRole = null
      } else {
        // else just USER
        modifiedUserRole.userRole = 'USER'
        modifiedUserRole.churchMemberRole = null
      }

      const modifiedUserData: UserData = {
        userdetail: response.userdetail,
        userrole: modifiedUserRole,
        churchShort: response.churchShort,
      }
      setUserData(modifiedUserData)
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleLogin = () => {
    fetchData()
  }

  return (
    <UserContext.Provider value={{ userData, handleLogin }}>
      {children}
    </UserContext.Provider>
  )
}

export const formatBankAccountNumber = (str: string) => {
  if (!/^\d+$/.test(str)) {
    return str
  }

  const length = str.length
  if (length < 10) {
    return str
  }

  if (length <= 12) {
    return `${str.slice(0, 3)}-${str.slice(3, 9)}-${str.slice(9)}`
  } else if (length <= 14) {
    return `${str.slice(0, 3)}-${str.slice(3, 6)}-${str.slice(
      6,
      9
    )}-${str.slice(9)}`
  } else {
    return `${str.slice(0, 4)}-${str.slice(4, 8)}-${str.slice(
      8,
      11
    )}-${str.slice(11)}`
  }
}

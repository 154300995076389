import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CircularProgress,
  Container,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router'

import {
  CampaignRequestContactPerson,
  CampaignRequestProjectHolder,
} from '../../models/CampaignProposal/GetCampaignProposalData'
import { BankAccount } from '../../models/campaign'
import { AttachmentMetaData } from '../../models/AttachmentMetaData'
import { UserShortDetail } from '../../models/UserTypes'
import { combinedCampaignData } from '../../services/CampaignServices'
import CampaignEditServices from '../../services/AdminCampaignEditServices'

import NormalModal from '../../components/Modal/NormalModal'
import CampaignProposalForm1, {
  CampaignProposalForm1Input,
  campaignProposalForm1Schema,
} from '../../components/CampaignProposal/CampaignProposalForm1'
import CampaignProposalForm2, {
  CampaignProposalForm2Input,
  campaignProposalForm2Schema,
} from '../../components/CampaignProposal/CampaignProposalForm2'
import CampaignProposalForm3, {
  CampaignProposalForm3Input,
  campaignProposalForm3Schema,
} from '../../components/CampaignProposal/CampaignProposalForm3'
import { churchInfo } from '../../components/SearchChurch'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'
import { usePut } from '../../hooks/useFetch'
import AuthService from '../../services/auth.service'
import {
  CamEditDesQuotCoverFormInput,
  camEditDesQuotCoverFormSchema,
} from '../../components/Admin/Campaign/AdminEditDesQuoteCoverForm'
import CampaignDesQuotCoverForm from '../../components/Admin/Campaign/AdminEditDesQuoteCoverForm'
import CustomToast from '../../components/Toast/CustomToast'
import { useFormDirtyContext } from '../../services/FormDirtyContext'

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4']

export default function AdminCampaignEdit() {
  const { campaignId } = useParams()
  const navigate = useNavigate()
  const [formData, setFormData] = useState<any>()
  const [loadingCampaignData, setLoadingCampaignData] = useState(true)
  const { setIsDirty } = useFormDirtyContext()

  //set new campaign data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editCampaignRes, sendEditLoading, sendEditReq, sendEditStatusCode] =
    usePut<any>({
      url: `/campaigns/${campaignId}/edit`,
      autoFetch: false,
      data: formData,
      jwtToken: AuthService.getTokenUser()!,
    })

  // Form 1 States
  const [contactPersons, setContactPersons] = useState<
    UserShortDetail[] | CampaignRequestContactPerson[]
  >([])
  const [holderChurches, setHolderChurches] = useState<
    churchInfo[] | CampaignRequestProjectHolder[]
  >([])
  const [selectedAreas, setSelectedAreas] = useState([
    false,
    false,
    false,
    false,
    false,
  ])

  const {
    register: registerCampaignProposalForm1,
    formState: {
      isDirty: campaignProposalForm1Dirty,
      errors: campaignProposalForm1Error,
    },
    getValues: getCampaignProposalForm1Values,
    handleSubmit: handleSubmitForm1,
    reset: resetForm1,
    control: campaignProposalForm1Control,
  } = useForm<CampaignProposalForm1Input>({
    resolver: zodResolver(campaignProposalForm1Schema),
  })

  //Form 2 States
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [campaignImages, setCampaignImages] = useState<AttachmentMetaData[]>([])

  const {
    register: registerCampaignProposalForm2,
    formState: {
      isDirty: campaignProposalForm2Dirty,
      errors: campaignProposalForm2Error,
    },
    getValues: getCampaignProposalForm2Values,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    control: campaignProposalForm2Control,
  } = useForm<CampaignProposalForm2Input>({
    resolver: zodResolver(campaignProposalForm2Schema),
  })

  //Form 3 States
  const [bankAccount, setBankAccount] = useState<BankAccount>({
    id: '',
    bankName: '',
    accountName: '',
    accountNumber: '',
    qrCodeUrl: '',
  })
  const [qr, setQr] = useState<AttachmentMetaData | undefined>()

  const {
    register: registerCampaignProposalForm3,
    formState: {
      isDirty: campaignProposalForm3Dirty,
      errors: campaignProposalForm3Error,
    },
    getValues: getCampaignProposalForm3Values,
    handleSubmit: handleSubmitForm3,
    reset: resetForm3,
    control: campaignProposalForm3Control,
  } = useForm<CampaignProposalForm3Input>({
    resolver: zodResolver(campaignProposalForm3Schema),
  })

  //Form 4 States - coverImage
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [coverImage, setCoverImage] = useState<AttachmentMetaData | null>(null)

  const {
    register: registerCampaignDesQuotCoverForm,
    formState: {
      isDirty: campaignDesQuotCoverFormDirty,
      errors: campaignDesQuotCoverFormError,
    },
    getValues: getCampaignDesQuotCoverFormValues,
    handleSubmit: handleSubmitCampaignDesQuotCoverForm,
    reset: resetCampaignDesQuotCoverForm,
    control: campaignDesQuotCoverFormControl,
  } = useForm<CamEditDesQuotCoverFormInput>({
    resolver: zodResolver(camEditDesQuotCoverFormSchema),
  })

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const handleBack = () => {
    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleNext = () => {
    window.scrollTo(0, 0)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false)
    navigate('/')
  }

  const handleCampaignImageChange = (images: AttachmentMetaData[]) => {
    setIsDirty(true)
    setCampaignImages(images)
  }

  const handleQrChange = (qr: AttachmentMetaData) => {
    setIsDirty(true)
    setQr(qr)
    setBankAccount({
      ...bankAccount,
      qrCodeUrl: qr?.url ?? '',
    })
  }

  const handleCoverImageChange = (coverImage: AttachmentMetaData) => {
    setIsDirty(true)
    setCoverImage(coverImage)
  }

  const prepareDataAndSend = async () => {
    const campaignImageUrls = campaignImages.map((i) => i.url)
    const qrUrl = bankAccount.qrCodeUrl

    const coverImageUrl = coverImage?.url ?? ''

    const combinedData = {
      ...combinedCampaignData({
        form1: getCampaignProposalForm1Values(),
        form2: getCampaignProposalForm2Values(),
        form3: getCampaignProposalForm3Values(),
        imageUrls: campaignImageUrls,
        holderChurches,
        contactPersons,
        selectedAreas,
        qrUrl,
        bankAccount,
      }),
      ...getCampaignDesQuotCoverFormValues(),
      coverImageUrl,
    }

    setFormData(combinedData)
  }

  useEffect(() => {
    async function getCampaignData() {
      if (!campaignId) {
        return
      }

      const campaignData = await CampaignEditServices.getCampaignData(
        campaignId
      )
      const campaignDesQuoteCoverData =
        await CampaignEditServices.getCampaignDesQuoteCoverData(campaignId)

      if (campaignData) {
        resetForm1(campaignData)
        resetForm2(campaignData)
        resetForm3(campaignData)
        if (campaignDesQuoteCoverData) {
          resetCampaignDesQuotCoverForm(campaignDesQuoteCoverData)
          setCoverImage({
            url: campaignDesQuoteCoverData.coverImageUrl,
            fileName: campaignDesQuoteCoverData.coverImageUrl,
            fileSize: 0,
          })
        }

        setContactPersons(campaignData.oldContactPerson)
        setHolderChurches(campaignData.oldChurchHolder)
        setSelectedAreas([
          campaignData.areaHolistic.includes('EDUCATION'),
          campaignData.areaHolistic.includes('CHILD_SAFEGUARDING'),
          campaignData.areaHolistic.includes('INFRASTRUCTURE'),
          campaignData.areaHolistic.includes('YOUTH_LEADERSHIP'),
          campaignData.areaHolistic.includes('WELL_BEING'),
        ])
        setCampaignImages(campaignData.image)

        if (campaignData.bankAccount) {
          setBankAccount(campaignData.bankAccount)
          setQr(
            campaignData.bankAccount.qrCodeUrl
              ? {
                  url: campaignData.bankAccount.qrCodeUrl,
                  fileName: campaignData.bankAccount.qrCodeUrl,
                  fileSize: 0,
                }
              : undefined
          )
        }
      }
      setLoadingCampaignData(false)
    }

    getCampaignData()
  }, [
    campaignId,
    resetForm1,
    resetForm2,
    resetForm3,
    resetCampaignDesQuotCoverForm,
  ])

  useEffect(() => {
    if (sendEditStatusCode === -1) return

    if (sendEditStatusCode === 200) {
      setShowSuccessModal(true)
      setIsDirty(false)
    }
  }, [sendEditStatusCode, setIsDirty])

  useEffect(() => {
    if (formData && !sendEditLoading) {
      sendEditReq()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  useEffect(() => {
    const isDirty =
      campaignProposalForm1Dirty ||
      campaignProposalForm2Dirty ||
      campaignProposalForm3Dirty ||
      campaignDesQuotCoverFormDirty

    setIsDirty(isDirty)
  }, [
    campaignDesQuotCoverFormDirty,
    campaignProposalForm1Dirty,
    campaignProposalForm2Dirty,
    campaignProposalForm3Dirty,
    setIsDirty,
  ])

  if (loadingCampaignData || sendEditLoading) {
    window.scrollTo(0, 0)
    return (
      <div className='flex h-full items-stretch justify-center'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <CustomToast />
      <Stack flexDirection='column' minHeight={'100vh'}>
        <Navbar />
        <Typography alignSelf={'center'} variant='h1' sx={{ margin: '20px' }}>
          เสนอโครงการ
        </Typography>
        <div className='flex min-h-screen flex-col'>
          <div className='flex-1'>
            <Container sx={{ marginBottom: '80px' }}>
              <Stack gap={'20px'}>
                <div
                  style={{
                    maxWidth: '400px',
                    width: '100%',
                    minWidth: 0,
                    alignSelf: 'center',
                  }}
                >
                  <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel></StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                <div>
                  <Stack
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    paddingTop={'20px'}
                  >
                    <Stack
                      flexDirection='column'
                      border={1}
                      borderColor={grey[400]}
                      borderRadius={'20px'}
                      width={'100%'}
                      padding={3}
                      gap='40px'
                      bgcolor={'#FFFFFF'}
                    >
                      {activeStep === 0 && (
                        <CampaignProposalForm1
                          contactPersons={contactPersons}
                          holderChurches={holderChurches}
                          selectedAreas={selectedAreas}
                          onContactPersonChange={(v) => {
                            setIsDirty(true)
                            setContactPersons(v)
                          }}
                          onHolderChurchChange={(v) => {
                            setIsDirty(true)
                            setHolderChurches(v)
                          }}
                          onSelectedAreaChange={(v) => {
                            setIsDirty(true)
                            setSelectedAreas(v)
                          }}
                          register={registerCampaignProposalForm1}
                          control={campaignProposalForm1Control}
                          errors={campaignProposalForm1Error}
                          onSubmit={handleSubmitForm1}
                          onSubmissionPass={handleNext}
                          allowEditProjectHolder={true}
                          viewOnly={false}
                        />
                      )}
                      {activeStep === 1 && (
                        <CampaignProposalForm2
                          images={campaignImages}
                          onImagesChange={handleCampaignImageChange}
                          onBack={handleBack}
                          register={registerCampaignProposalForm2}
                          errors={campaignProposalForm2Error}
                          control={campaignProposalForm2Control}
                          onSubmit={handleSubmitForm2}
                          onSubmissionPass={handleNext}
                          viewOnly={false}
                        />
                      )}
                      {activeStep === 2 && (
                        <CampaignProposalForm3
                          onBack={handleBack}
                          register={registerCampaignProposalForm3}
                          errors={campaignProposalForm3Error}
                          control={campaignProposalForm3Control}
                          churchBankAccounts={[]}
                          currBankAccount={bankAccount}
                          setBankAccount={setBankAccount}
                          onSubmit={handleSubmitForm3}
                          onSubmissionPass={handleNext}
                          viewOnly={false}
                          adminView={false}
                          hasNextPage={true}
                        />
                      )}

                      {activeStep === 3 && (
                        <CampaignDesQuotCoverForm
                          coverImage={coverImage}
                          onCoverImageChange={handleCoverImageChange}
                          onBack={handleBack}
                          register={registerCampaignDesQuotCoverForm}
                          errors={campaignDesQuotCoverFormError}
                          control={campaignDesQuotCoverFormControl}
                          onSubmit={handleSubmitCampaignDesQuotCoverForm}
                          onSubmissionPass={prepareDataAndSend}
                          viewOnly={false}
                        />
                      )}
                    </Stack>
                  </Stack>
                </div>
              </Stack>
            </Container>
            <NormalModal
              id='success modal'
              type='DEFAULT'
              title={'แก้ไขแบบฟอร์มสำเร็จ'}
              description='ระบบได้ทำการบันทึกข้อมูล'
              open={showSuccessModal}
              onClose={handleSuccessModalClose}
            />
          </div>
          <Footer />
        </div>
      </Stack>
    </>
  )
}

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import ModalWithButton from '../../components/Modal/ModalWithButton'
import NormalModal from '../../components/Modal/NormalModal'
import { useNavigate, useParams } from 'react-router-dom'
import ModalFillReason from '../../components/Modal/ModalFillReason'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { ADMIN_ROUTE } from '../../constants/admin-route-constant'
import AdminChurchProposalForm from './AdminChurchProposalForm'
import AuthService from '../../services/auth.service'
import { useGet } from '../../hooks/useFetch'
import { ChurchRequestData } from '../../models/admin/ChurchRequest'
import Church from '../Church'
import { common, grey } from '@mui/material/colors'
import axios from 'axios'
import ModalDeleteWithConfirm from '../../components/Modal/ModalDeleteWithConfirm'
import AdminCommentSection from '../../components/ChurchProposal/AdminCommentSection'
import CustomToast from '../../components/Toast/CustomToast'
import { toast } from 'react-toastify'
import { SNACKBAR_TEXT } from '../../constants/snackbar-text'
import createAPIErrorMessage from '../../utils/createAPIErrorMessage'

export default function ChurchRequestDetail() {
  const navigate = useNavigate()
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''
  const token = AuthService.getTokenUser()

  const [page, setPage] = useState<number>(1)
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false)
  const [openConfirmApproveModal, setOpenConfirmApproveModal] =
    useState<boolean>(false)
  const [openNeedfixModal, setOpenNeedfixModal] = useState<boolean>(false)
  const [openConfirmNeedFixModal, setOpenConfirmNeedFixModal] =
    useState<boolean>(false)
  const [openDeleteChurchModal, setOpenDeleteChurchModal] =
    useState<boolean>(false)
  const [sendingData, setSendingData] = useState<boolean>(false)

  const params = useParams()

  const [requestData, loading, refresh, statusCode] = useGet<ChurchRequestData>(
    {
      url: `/church-requests/${params.requestId}`,
      ...(token && { jwtToken: token }),
      autoFetch: true,
    }
  )

  const handleClickApprove = () => {
    setOpenApproveModal(true)
  }

  const rejectType = useMemo(() => {
    const reqType = requestData?.churchRequestType
    if (reqType === 'CREATE') {
      return 'request-fix'
    } else {
      return 'reject'
    }
  }, [requestData?.churchRequestType])

  const handleConfirmApprove = async () => {
    setSendingData(true)
    try {
      const response = await axios.patch(
        `${API_URL}/church-requests/${params.requestId}/approve`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
        }
      )

      // Handle a successful response (e.g., display a success message)
      // console.log('Church request approved:', response.data)
      setSendingData(false)
      setOpenApproveModal(false)
      setOpenDeleteChurchModal(false)
      setOpenConfirmApproveModal(true)
    } catch (error: any) {
      // Handle errors (e.g., display an error message)
      // console.error('Error editing item:', error)
      showErrorToast(error)
      setSendingData(false)
    }
  }

  const handleClickConfirmNeedfix = async (data: string) => {
    const comment = {
      comment: data,
    }
    setSendingData(true)
    try {
      await axios.patch(
        `${API_URL}/church-requests/${params.requestId}/${rejectType}`,
        comment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
        }
      )

      // Handle a successful response (e.g., display a success message)
      // console.log('Church request rejected:', response.data)
      setOpenNeedfixModal(false)
      setOpenConfirmNeedFixModal(true)
      setSendingData(false)
    } catch (error: any) {
      // Handle errors (e.g., display an error message)
      // console.error('Error editing item:', error)
      showErrorToast(error)
      setSendingData(false)
    }
  }

  const showErrorToast = (error: any) => {
    toast.error(`${SNACKBAR_TEXT.ERROR} ${createAPIErrorMessage(error)}`)
  }

  const [approveModalTitle, approveModalDescp, approveModalConfirmTxt] =
    useMemo(() => {
      const requestType = requestData?.churchRequestType
      if (requestType === 'CREATE') {
        return [
          'ยืนยันที่จะสร้างโบสถ์ใช่ไหม',
          'โปรดแน่ใจว่าคุณได้ตรวจสอบข้อมูลคำขอการสร้างโบสถ์แล้ว',
          'สร้างโบสถ์',
        ]
      } else if (requestType === 'EDIT') {
        return [
          'ยืนยันที่จะแก้ไขโบสถ์ใช่ไหม',
          'โปรดแน่ใจว่าคุณได้ตรวจสอบข้อมูลคำขอการแก้ไขโบสถ์แล้ว',
          'แก้ไขโบสถ์',
        ]
      } else if (requestType === 'DELETE') {
        return [
          'แจ้งเตือนการลบโบสถ์',
          'แน่ใจหรือไม่ที่จะลบโบสถ์นี้? การกระทำนี้ไม่สามารถย้อนกลับได้',
          'แก้ไขโบสถ์',
        ]
      } else {
        return [' ', ' ', '']
      }
    }, [requestData?.churchRequestType])

  const [confirmApproveModalTitle] = useMemo(() => {
    const requestType = requestData?.churchRequestType
    if (requestType === 'CREATE') {
      return ['สร้างโบสถ์สำเร็จ']
    } else if (requestType === 'EDIT') {
      return ['แก้ไขข้อมูลโบสถ์สำเร็จ']
    } else if (requestType === 'DELETE') {
      return ['โบสถ์ถูกลบ']
    } else {
      return [' ', ' ', '']
    }
  }, [requestData?.churchRequestType])

  const [
    requestFixModalTitle,
    requestFixModalDescp,
    requestFixModalConfirmTxt,
  ] = useMemo(() => {
    const requestType = requestData?.churchRequestType
    if (requestType === 'CREATE') {
      return [
        'เหตุผลในการปฎิเสธการสร้างโบสถ์',
        'แจ้งเหตุผลที่คุณปฎิเสธ เพื่อให้สมาชิกของโบสถ์สามารถแก้ไขข้อมูลได้ถูกต้อง',
        'ยืนยันการปฎิเสธ',
      ]
    } else if (requestType === 'EDIT') {
      return [
        'เหตุผลในการปฎิเสธการแก้ไขโบสถ์',
        'แจ้งเหตุผลที่คุณปฎิเสธ เพื่อให้สมาชิกของโบสถ์สามารถแก้ไขข้อมูลได้ถูกต้อง',
        'ยืนยันการปฎิเสธ',
      ]
    } else if (requestType === 'DELETE') {
      return [
        'เหตุผลในการปฎิเสธการลบโบสถ์',
        'แจ้งเหตุผลที่คุณปฎิเสธ เพื่อให้สมาชิกของโบสถ์สามารถแก้ไขข้อมูลได้ถูกต้อง',
        'ยืนยันการปฎิเสธ',
      ]
    } else {
      return [' ', ' ', '']
    }
  }, [requestData?.churchRequestType])

  const [confirmRequestFixModalTitle] = useMemo(() => {
    const requestType = requestData?.churchRequestType
    if (requestType === 'CREATE') {
      return ['คำขอการสร้างโบสถ์ได้ถูกปฎิเสธ']
    } else if (requestType === 'EDIT') {
      return ['คำขอการแก้ไขโบสถ์ได้ถูกปฎิเสธ']
    } else if (requestType === 'DELETE') {
      return ['คำขอการลบโบสถ์ได้ถูกปฎิเสธ']
    } else {
      return [' ', ' ', '']
    }
  }, [requestData?.churchRequestType])

  return (
    <div className='bg-[#FBFDFF]'>
      <ResponsiveAppBar />
      {sendingData ? (
        <>
          <Box
            display={'flex'}
            justifyContent={'center'}
            padding={'10vw'}
            alignItems={'center'}
            height={'70vh'}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Container>
          <Box height={'24px'} />
          <Box textAlign={'center'}>
            <Typography variant='h2'>
              Request: {requestData?.churchRequestType} Church
            </Typography>

            <Box height={'20px'} />
            {requestData?.adminComment !== '' && !loading && (
              <>
                <AdminCommentSection comment={requestData?.adminComment!} />
                <Box height={'20px'} />
              </>
            )}

            {requestData?.churchRequestType === 'DELETE' ? (
              <>
                <Box bgcolor={common.white}>
                  <Church adminView manualChurchId={requestData.churchId} />
                </Box>

                <Stack
                  gap={'16px'}
                  flexDirection={'row'}
                  width={'100%'}
                  justifyContent={'flex-end'}
                >
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => setOpenDeleteChurchModal(true)}
                  >
                    <Typography variant='h6'>Approve</Typography>
                  </Button>
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={() => setOpenNeedfixModal(true)}
                  >
                    <Typography variant='h6'>Deny</Typography>
                  </Button>
                </Stack>
              </>
            ) : (
              <Stack
                flexDirection='column'
                border={1}
                borderColor={grey[400]}
                borderRadius={'20px'}
                width={'100%'}
                padding={3}
                gap='40px'
                bgcolor={common.white}
              >
                <AdminChurchProposalForm
                  requestData={requestData}
                  loading={loading}
                  page={requestData?.churchRequestType === 'CREATE' ? page : 0}
                  requestType={requestData?.churchRequestType}
                />
                {page === 1 && requestData?.churchRequestType === 'CREATE' && (
                  <Stack
                    width={'100%'}
                    alignItems={'flex-end'}
                    paddingTop={'20px'}
                  >
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => setPage(page + 1)}
                    >
                      <Typography variant='h6'>{`ถัดไป >`}</Typography>
                    </Button>
                  </Stack>
                )}

                {(page === 2 || requestData?.churchRequestType === 'EDIT') && (
                  <Stack
                    width={'100%'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    paddingTop={'20px'}
                  >
                    <Button
                      sx={{
                        visibility:
                          requestData?.churchRequestType === 'EDIT'
                            ? 'hidden'
                            : 'visible',
                        maxHeight: '44px',
                      }}
                      variant='outlined'
                      color='primary'
                      onClick={() => setPage(page - 1)}
                    >
                      <Typography variant='h6'>{`< กลับ`}</Typography>
                    </Button>

                    <Stack
                      gap={'16px'}
                      flexDirection={{ xs: 'column', md: 'row' }}
                    >
                      <Button
                        variant='outlined'
                        color='primary'
                        onClick={handleClickApprove}
                        sx={{ textTransform: 'none' }}
                      >
                        <Typography variant='h6'>Approve</Typography>
                      </Button>
                      <Button
                        variant='outlined'
                        color='error'
                        onClick={() => setOpenNeedfixModal(true)}
                        sx={{ textTransform: 'none' }}
                      >
                        <Typography variant='h6'>
                          {requestData?.churchRequestType === 'CREATE'
                            ? 'Request fix'
                            : 'Deny'}
                        </Typography>
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}

            <Box height={'20px'} />
          </Box>
        </Container>
      )}

      {/* CREATE CHURCH MODAL */}
      <ModalWithButton
        id='admin-create-church'
        type='DEFAULT'
        title={approveModalTitle}
        description={approveModalDescp}
        confirmBtnText={approveModalConfirmTxt}
        cancleBtnText='ยกเลิก'
        open={openApproveModal && !sendingData}
        handleClickConfirm={handleConfirmApprove}
        onClose={() => setOpenApproveModal(false)}
        disableCancelBtn={sendingData}
        disableConfirmBtn={sendingData}
      />

      {/* CONFIRM CREATE CHURCH MODAL */}
      <NormalModal
        id={'admin-confirm-create-church'}
        type={'DEFAULT'}
        title={confirmApproveModalTitle}
        confirmText='กลับไปที่หน้ารวมคำขอโบสถ์'
        open={openConfirmApproveModal}
        onClickConfirm={() => navigate(ADMIN_ROUTE.CHURCH_REQUEST)}
        onClose={() => setOpenConfirmApproveModal(false)}
      />

      {/* NEED FIX CHURCH MODAL */}
      <ModalFillReason
        id={'admin-need-fix-church'}
        type={'ERROR'}
        title={requestFixModalTitle}
        description={requestFixModalDescp}
        confirmText={requestFixModalConfirmTxt}
        open={openNeedfixModal && !sendingData}
        onClose={() => setOpenNeedfixModal(false)}
        onClickConfirm={handleClickConfirmNeedfix}
        disableConfirmBtn={sendingData}
      />

      <NormalModal
        id={'admin-confirm-need-fix-church'}
        type={'ERROR'}
        title={confirmRequestFixModalTitle}
        confirmText='กลับไปที่หน้ารวมคำขอโบสถ์'
        open={openConfirmNeedFixModal}
        onClose={() => {
          setOpenConfirmNeedFixModal(false)
          navigate(ADMIN_ROUTE.CHURCH_REQUEST)
        }}
        onClickConfirm={() => navigate(ADMIN_ROUTE.CHURCH_REQUEST)}
      />

      <ModalDeleteWithConfirm
        id='admin-confirm-delete-church'
        type='ERROR'
        confirmText={requestData?.churchName!}
        title={'แจ้งเตือนการลบโบสถ์'}
        description={
          'แน่ใจหรือไม่ที่จะลบโบสถ์นี้? การกระทำนี้ไม่สามารถย้อนกลับได้'
        }
        confirmBtnText={'ฉันเข้าใจและยืนยัน'}
        cancleBtnText={'ยกเลิก'}
        open={openDeleteChurchModal}
        onClose={() => setOpenDeleteChurchModal(false)}
        handleClickConfirm={handleConfirmApprove}
      />

      <CustomToast />
    </div>
  )
}

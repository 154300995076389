import { Box, Typography, TextField } from '@mui/material'
import React from 'react'
import { COLOR } from '../../constants/color-constant'
import { PaymentData } from '../../models/campaign'

interface DonationCustomAmountProps {
  selected: boolean
  toUploadData: PaymentData
  setToUploadData: React.Dispatch<React.SetStateAction<PaymentData>>
}

export default function DonationCustomAmount(props: DonationCustomAmountProps) {
  const { selected, toUploadData, setToUploadData } = props

  return (
    <Box
      display={'flex'}
      width={'auto'}
      minWidth={'200px'}
      height={'100px'}
      borderRadius={'14px'}
      border={'1px solid #C1C1C1'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        background: selected ? COLOR.PRIMARY_2 : 'white',
        cursor: 'pointer',
      }}
      boxShadow={'0px 4px 10px 5px rgba(0, 0, 0, 0.10)'}
    >
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Typography
          variant='h3'
          color={selected ? 'white' : 'gray'}
          align='center'
        >
          ระบุจำนวนเงิน
        </Typography>
        {selected && (
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={'1vw'}
          >
            <Typography variant='h3' color={selected ? 'white' : 'gray'}>
              ฿
            </Typography>
            <TextField
              id='standard-basic'
              variant='standard'
              type='number'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setToUploadData({ ...toUploadData, total: +event.target.value })
              }}
              inputProps={{
                style: {
                  color: 'white',
                  fontFamily: 'Mitr',
                  fontWeight: 400,
                  fontSize: '28px',
                  lineHeight: '44px',
                },
              }}
              sx={{ width: '100px' }}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

import {
  Box,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  Grid,
  Button,
  CssBaseline,
  Paper,
  Divider,
  Stack,
  Checkbox,
} from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import { COLOR } from '../constants/color-constant'
import loginBaner from '../assets/images/login_banner.png'
import { ReactComponent as IconGoogle } from '../assets/icons/google.svg'
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useGoogleLogin } from '@react-oauth/google'
import AuthService from '../services/auth.service'
import { useNavigate } from 'react-router-dom'
import { useAuthNotEnterMiddleware } from '../services/AuthMiddleware'
import { useUser } from '../services/UserContext'
import Navbar from '../components/Navbar/Navbar'
import { ROUTE } from '../constants/route-constant'
import ModalNotification from '../components/Modal/ModalNotification'
import { ReactComponent as Sendmail } from '../assets/icons/sendmailnoti.svg'
import React from 'react'
import axios from 'axios'
import CustomToast from '../components/Toast/CustomToast'
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#&()-_{}:;',?/*~$^+=<>"
const registerSchema = object({
  firstname: string().nonempty('ต้องกรอกชื่อ'),
  lastname: string().nonempty('ต้องกรอกนามสกุล'),
  email: string().nonempty('ต้องกรอกอีเมล').email('อีเมลไม่ถูกต้อง'),
  phone: string()
    .optional()
    .refine(
      (value) => !value || /^[0-9]{10}$/.test(value),
      'เบอร์โทรศัพท์ไม่ถูกต้อง'
    ),
  password: string()
    .nonempty('ต้องกรอกรหัสผ่าน')
    .min(8, 'รหัสผ่านต้องมีความยาวมากกว่า 8 ตัวอักษร')
    .max(20, 'รหัสผ่านต้องมีความยาวไม่เกิน 20 ตัวอักษร'),
  passwordConfirm: string().nonempty('กรุณายืนยันรหัสผ่าน'),
})
  .refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'รหัสผ่านไม่ตรงกัน',
  })
  .refine(
    (data) => /[a-zA-Z0-9!@#&()-_{}:;',?/*~$^+=<>]$/.test(data.password),
    {
      path: ['password'],
      message:
        "รองรับเฉพาะตัวอักษร (a-z),(A-Z), เลข 0-9 และ \n อักขระพิเศษ  !@#&()-_{}:;',?/*~$^+=<>",
    }
  )

type RegisterInput = TypeOf<typeof registerSchema>

const RegisterPage = () => {
  const navigate = useNavigate()
  const tokenChecked = useAuthNotEnterMiddleware()
  const [isRandomPassword, setIsRandomPassword] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const [isSendedToBackend, setIsSendedToBackend] = useState<boolean>(false)

  const handleShow1 = () => setShowPassword1((prev) => !prev)
  const handleShow2 = () => setShowPassword2((prev) => !prev)

  const randompassword = () => {
    const length = Math.floor(Math.random() * (19 - 9) + 9)
    let result = ''
    let counter = 0

    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
      counter += 1
    }

    setShowPassword1(true)
    setShowPassword2(true)

    setIsRandomPassword(true)
    setValue('password', result, { shouldValidate: true })
    setValue('passwordConfirm', result, { shouldValidate: true })
  }

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  })
  const [showEmailSection, setShowEmailSection] = useState(false)
  const userContext = useUser()
  const onSubmitHandler: SubmitHandler<RegisterInput> = async (values) => {
    if (values && termsChecked) {
      try {
        await AuthService.register(values)
        setIsSendedToBackend(true)
        userContext.handleLogin()
        await new Promise((resolve) => setTimeout(resolve, 3000))
        reset()
        navigate('/')
      } catch (error) {
        const resMessage =
          (error instanceof Error && error.message) ||
          'มีข้อผิดพลาดเกิดขึ้นในระหว่างการสมัครสมาชิก'

        if (axios.isAxiosError(error)) {
          if (
            error.response?.data.detail ===
            'Sign-up Failed: This email is already used in another account. \nPlease attempt to sign in using your username and password or Google OAuth instead.\n'
          ) {
            toast.error('อีเมลนี้ถูกใช้งานแล้ว')
            return
          }
        }

        toast.error('ไม่สามารถสมัครสมาชิกได้: ' + resMessage)
      }

      setTermsChecked(false)
    }
  }
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (tokenResponse) => {
      AuthService.googleLogin(tokenResponse.code).then(
        () => {
          if (userContext) {
            userContext.handleLogin()
          }
          navigate('/')
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.detail) ||
            error.message ||
            error.toString()
          toast.error('Login failed: ' + resMessage)
        }
      )
    },
  })

  if (!tokenChecked) {
    return null // Don't render anything until token check is complete
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Navbar />
        <ModalNotification
          id={'admin-confirm-create-church'}
          type={'SUCCESS'}
          SvgIcon={
            <Sendmail
              style={{
                height: 80,
              }}
            />
          }
          title='ลิงก์ยืนยันการสมัครสมาชิกถูกส่งไปยังอีเมลของท่านแล้ว'
          description='กรุณาตรวจสอบอีเมลของท่านเพื่อยืนยันการสมัครสมาชิก'
          open={isSendedToBackend}
          onClose={() => setIsSendedToBackend(false)}
        />
        <div style={{ flex: '1', overflowY: 'auto' }}>
          <Grid container component='main' sx={{ height: '100%' }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={false}
              md={4}
              lg={6}
              sx={{
                backgroundImage: 'url(' + loginBaner + ')',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                  t.palette.mode === 'light'
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={6}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 3,
                  mx: { xl: 20, lg: 6, md: 6, sm: 6, xs: 3 },
                  display: 'flex',
                  flexWrap: 'nowarp',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CustomToast />
                <Box width={'100%'} my={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <Typography variant='h1'>ยินดีต้อนรับสู่ </Typography>

                    <Typography variant='h1' color={COLOR.PRIMARY_1}>
                      น้ำใจ
                    </Typography>
                  </Box>
                  <Typography variant='h6'>
                    ร่วมสร้างอนาคตที่ดีขึ้นให้กับเด็ก ๆ
                  </Typography>
                  <Typography variant='body1' color={COLOR.GRAY_600}>
                    กรุณาสมัครสมาชิกด้วยบัญชี Google หรือ อีเมล
                  </Typography>

                  <Button
                    variant='outlined'
                    onClick={() => googleLogin()}
                    style={{
                      borderBlockWidth: '2px',
                      borderRadius: '10px',
                      width: '100%',
                      marginTop: '5%',
                      marginBottom: '2%',
                      padding: '10px',
                      gap: '15px',
                    }}
                  >
                    <IconGoogle />
                    <Typography variant='h6' color={COLOR.GRAY_600}>
                      สมัครสมาชิกด้วยบัญชี Google
                    </Typography>
                  </Button>
                  <Divider>หรือ สมัครสมาชิกด้วยอีเมลของท่าน</Divider>
                </Box>
                {!showEmailSection && (
                  <Button
                    onClick={() => {
                      setShowEmailSection(true)
                    }}
                    variant='outlined'
                    style={{
                      borderBlockWidth: '2px',
                      borderRadius: '10px',
                      width: '100%',
                      // marginTop: '5%',
                      marginBottom: '2%',
                      padding: '10px',
                      gap: '25px',
                    }}
                  >
                    <MailOutlineRoundedIcon
                      style={{ height: '40px', width: '40px' }}
                    />
                    <Typography variant='h6' color={COLOR.GRAY_600}>
                      สมัครสมาชิกด้วยอีเมล
                    </Typography>
                  </Button>
                )}

                {showEmailSection && (
                  <Box
                    width={'100%'}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <Box
                      width={'100%'}
                      component='form'
                      noValidate
                      autoComplete='off'
                      onSubmit={handleSubmit(onSubmitHandler)}
                    >
                      <Stack
                        spacing={{ xs: 1, sm: 2 }}
                        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                        useFlexGap
                        justifyContent={'space-between'}
                        flexWrap='wrap'
                        marginBottom={'20px'}
                      >
                        {/* First Name Field */}
                        <TextField
                          sx={{
                            width: {
                              xs: '100%',
                              md: '47%',
                            },
                            label: { color: COLOR.DIVIDER_MAIN },
                          }}
                          label='ชื่อ'
                          fullWidth
                          required
                          error={Boolean(errors.firstname)}
                          helperText={errors.firstname?.message}
                          {...register('firstname')}
                        />
                        {/* Last Name Field */}
                        <TextField
                          sx={{
                            width: {
                              xs: '100%',
                              md: '47%',
                            },
                            label: { color: COLOR.DIVIDER_MAIN },
                          }}
                          label='นามสกุล'
                          fullWidth
                          required
                          error={Boolean(errors.lastname)}
                          helperText={errors.lastname?.message}
                          {...register('lastname')}
                        />
                      </Stack>
                      {/* Phone Field */}
                      <TextField
                        sx={{ mb: 2, label: { color: COLOR.DIVIDER_MAIN } }}
                        label='เบอร์โทรศัพท์'
                        fullWidth
                        inputProps={{
                          inputMode: 'tel',
                          onInput: (e) => {
                            // Allow only numeric characters
                            e.currentTarget.value =
                              e.currentTarget.value.replace(/[^0-9]/g, '')
                          },
                        }}
                        type='text'
                        helperText={errors.phone?.message}
                        {...register('phone')}
                      />
                      {/* Email Field */}
                      <TextField
                        sx={{ mb: 2, label: { color: COLOR.DIVIDER_MAIN } }}
                        label='อีเมล'
                        fullWidth
                        required
                        type='email'
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                        {...register('email')}
                      />
                      {/* Password Field */}
                      <TextField
                        sx={{ mb: 2, label: { color: COLOR.DIVIDER_MAIN } }}
                        label={isRandomPassword ? '' : 'รหัสผ่าน'}
                        fullWidth
                        required
                        type={showPassword1 ? 'text' : 'password'}
                        error={Boolean(errors.password)}
                        helperText={
                          errors.password?.message &&
                          errors.password?.message
                            .split('\n')
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                          // errors.password?.message
                        }
                        {...register('password')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                onClick={() => {
                                  randompassword()
                                }}
                              >
                                <Tooltip title='สุ่มรหัสผ่าน'>
                                  <ShuffleIcon />
                                </Tooltip>
                              </IconButton>

                              <IconButton onClick={handleShow1} edge='end'>
                                {showPassword1 ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* Confirm Password Field */}
                      <TextField
                        sx={{ mb: 2, label: { color: COLOR.DIVIDER_MAIN } }}
                        label={isRandomPassword ? '' : 'ยืนยันรหัสผ่าน'}
                        fullWidth
                        required
                        type={showPassword2 ? 'text' : 'password'}
                        error={Boolean(errors.passwordConfirm)}
                        helperText={errors.passwordConfirm?.message}
                        {...register('passwordConfirm')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton onClick={handleShow2} edge='end'>
                                {showPassword2 ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* Terms Checkbox */}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              required
                              checked={termsChecked}
                              onChange={(e) =>
                                setTermsChecked(e.target.checked)
                              }
                            />
                          }
                          label={
                            <Typography
                              style={{
                                whiteSpace: 'normal', // Change whiteSpace to 'normal'
                                wordWrap: 'break-word', // Add wordWrap property
                                overflowWrap: 'break-word', // Add overflowWrap property
                                maxWidth: '100%', // Optionally, set a maximum width
                              }}
                            >
                              ฉันได้อ่านและยอมรับ{' '}
                              <Typography
                                color={COLOR.PRIMARY_1}
                                onClick={() => {
                                  navigate(ROUTE.PRIVACY_POLICY)
                                }}
                                style={{ display: 'inline', cursor: 'pointer' }}
                              >
                                นโยบายความเป็นส่วนตัว
                              </Typography>{' '}
                              และ{' '}
                              <Typography
                                color={COLOR.PRIMARY_1}
                                onClick={() => {
                                  navigate(ROUTE.COOKIES_POLICY)
                                }}
                                style={{ display: 'inline', cursor: 'pointer' }}
                              >
                                นโยบายคุกกี้
                              </Typography>
                            </Typography>
                          }
                        />
                        <FormHelperText
                          error={!termsChecked}
                          style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            maxWidth: '100%',
                          }}
                        >
                          {!termsChecked
                            ? '*กรุณายอมรับนโยบายความเป็นส่วนตัวและนโยบายคุกกี้ ก่อนทำการสมัครสมาชิก'
                            : ' '}
                        </FormHelperText>
                      </FormGroup>
                      {/* Submit Button */}
                      <Button
                        style={{
                          borderBlockWidth: '2px',
                          borderRadius: '10px',
                          borderColor: !termsChecked
                            ? COLOR.DIVIDER_MAIN
                            : COLOR.PRIMARY_2,
                          padding: '10px',
                        }}
                        type='submit'
                        fullWidth
                        variant='outlined'
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!termsChecked} // Disable button if either field is empty
                      >
                        <Typography
                          variant='h5'
                          color={
                            !termsChecked ? COLOR.DIVIDER_MAIN : COLOR.PRIMARY_2
                          }
                        >
                          สมัครสมาชิกด้วยอีเมล
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
                <Typography
                  variant='subtitle1'
                  style={{
                    whiteSpace: 'normal', // Change whiteSpace to 'normal'
                    wordWrap: 'break-word', // Add wordWrap property
                    overflowWrap: 'break-word', // Add overflowWrap property
                    maxWidth: '100%', // Optionally, set a maximum width
                  }}
                >
                  มีบัญชีอยู่แล้วใช่ไหม{' '}
                  <Typography
                    color={COLOR.PRIMARY_1}
                    onClick={() => {
                      navigate(ROUTE.LOGIN)
                    }}
                    style={{ display: 'inline', cursor: 'pointer' }}
                  >
                    เข้าสู่ระบบที่นี่
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default RegisterPage

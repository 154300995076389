import axios from 'axios'
import { UserData, UserProfile, UserRole } from '../models/UserTypes'

const API_URL = process.env.REACT_APP_BASE_API
const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

const headers = {
  'x-api-key': API_KEY,
}

interface RegisterProps {
  firstname: string
  lastname: string
  phone?: string
  email: string
  password: string
  passwordConfirm: string
}

const register = (props: RegisterProps) => {
  const { firstname, lastname, phone, email, password } = props

  return axios
    .post(
      API_URL + '/auth/sign-up',
      {
        firstName: firstname,
        lastName: lastname,
        phoneNumber: phone !== '' ? phone : undefined,
        email,
        password,
      },
      {
        headers,
      }
    )
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('token', JSON.stringify(response.data.accessToken))
      }
    })
}

const login = (email: String, password: String) => {
  return axios
    .post(
      API_URL + '/auth/sign-in',
      {
        email: email,
        password: password,
      },
      {
        headers,
      }
    )
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.clear()
        localStorage.setItem('token', JSON.stringify(response.data.accessToken))
      }

      return response.data
    })
}

const googleLogin = (authorizationCode: String) => {
  return axios
    .post(
      API_URL + '/auth/sign-in-google',
      {
        authorizationCode: authorizationCode,
      },
      {
        headers,
      }
    )
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.clear()
        localStorage.setItem('token', JSON.stringify(response.data.accessToken))
      }

      return response.data
    })
}

const jwtFetchdata = (token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-api-key': API_KEY,
    },
  }

  const getUserProfile = () => {
    return axios.get<UserProfile>(API_URL + '/users/profile', config)
  }

  const getUserRole = () => {
    return axios.get<UserRole>(API_URL + '/users/role', config)
  }

  return Promise.all([getUserProfile(), getUserRole()])
    .then((responses) => {
      const { userDetail, churchShort } = responses[0].data
      const userRoleData = responses[1].data

      const modifiedResponse: UserData = {
        userdetail: userDetail,
        churchShort,
        userrole: userRoleData,
      }
      return modifiedResponse
    })
    .catch((error) => {
      console.error(error)
      if (error.response?.status === 403 || error.response?.status === 401) {
        console.log(
          'Removing token from local storage due to 403 Forbidden or token expiration. (logout)'
        )
        // Remove token from local storage
        localStorage.removeItem('token')
      }
      throw error // Rethrow the error to propagate it to the caller
    })
}

const logout = () => {
  localStorage.clear()

  //TODO make logout remove token in DB
  // return axios.post(API_URL + 'signout').then((response) => {
  //   return response.data
  // })
}

const getTokenUser = () => {
  const token = localStorage.getItem('token')
  if (token) {
    return token.replace(/"/g, '')
  }

  return null
}

const verifyUser = async (verifyCode: String) => {
  try {
    const response = await axios.get(
      `${API_URL}/auth/verify?verifyToken=${verifyCode}`,
      {
        headers,
      }
    )

    if (response.status === 200) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Error while verifying user:', error)
    return false
  }
}

const getResetPass = (email: string) => {
  return axios
    .post(
      API_URL + '/auth/forgot-password',
      {
        email: email,
      },
      {
        headers,
      }
    )
    .then((response) => {
      return response.data
    })
}

const getSetPass = (password: String, resetCode: String | null) => {
  if (resetCode) {
    return axios
      .post(
        API_URL + '/auth/reset-password',
        {
          newPassword: password,
          resetCode: resetCode,
        },
        {
          headers,
        }
      )
      .then((response) => {
        return response.data
      })
  }
}
const AuthService = {
  register,
  login,
  googleLogin,
  jwtFetchdata,
  logout,
  getTokenUser,
  verifyUser,
  getResetPass,
  getSetPass,
}

export default AuthService

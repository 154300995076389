import { Box, Stack, Typography } from '@mui/material'

import BankLogo from '../BankLogo/BankLogo'

import { BankAccount } from '../../models/campaign'
import { formatBankAccountNumber } from '../../utils/formatString'

interface SelectBankAccountProps {
  viewOnly: boolean
  bankAccounts: BankAccount[]
  current: BankAccount | null
  setCurrent: (current: BankAccount) => void
}

export const sameBank = (
  bank1: BankAccount | null,
  bank2: BankAccount | null
) => {
  if (!bank1 || !bank2) return false

  return (
    bank1.id === bank2.id &&
    bank1.bankName === bank2.bankName &&
    bank1.accountName === bank2.accountName &&
    bank1.accountNumber === bank2.accountNumber &&
    bank1.qrCodeUrl === bank2.qrCodeUrl
  )
}

function SelectBankAccount({
  viewOnly,
  bankAccounts,
  current,
  setCurrent,
}: SelectBankAccountProps) {
  const handleSelectAccount = (index: number) => {
    setCurrent(bankAccounts[index])
  }

  return (
    <Stack gap='10px'>
      <Typography variant='h5'>
        กรุณาเลือกบัญชีธนาคารที่มีอยู่ในโบสถ์ของท่าน
      </Typography>
      <Box
        sx={{
          height: {
            xs: '100%',
            lg: '800px',
          },
          overflowY: 'auto',
        }}
      >
        <Stack gap='16px' minHeight={0} mr='8px'>
          {bankAccounts.map((bankAccount, i) => (
            <button
              disabled={viewOnly}
              key={bankAccount.id}
              onClick={(e) => {
                e.preventDefault()
                handleSelectAccount(i)
              }}
              className={`rounded-[12px] border-[1px]
                shadow-[2px_2px_4px_0px_rgba(33,33,33,0.10)]
                focus:border-[#0079FF] focus:outline-none
              ${
                sameBank(bankAccount, current)
                  ? 'border-[#0079FF] bg-[#f5faff]'
                  : 'hover:border-[#0079FF] hover:bg-white'
              }`}
            >
              <Stack
                padding='12px 16px'
                direction={'row'}
                alignItems='center'
                gap='24px'
                maxWidth='370px'
                flexWrap='wrap'
              >
                <Stack
                  width='120px'
                  alignItems='center'
                  gap='8px'
                  minWidth='120px'
                >
                  <BankLogo bankName={bankAccount.bankName} />
                  <Typography variant='body2'>
                    {bankAccount.bankName}
                  </Typography>
                </Stack>
                <Box textAlign={'left'} minWidth='180px'>
                  <Typography variant='body1' sx={{ overflowWrap: 'anywhere' }}>
                    ชื่อบัญชี: {bankAccount.accountName}
                  </Typography>
                  <Typography variant='body1' sx={{ overflowWrap: 'anywhere' }}>
                    เลขที่บัญชี:{' '}
                    {formatBankAccountNumber(bankAccount.accountNumber)}
                  </Typography>
                </Box>
              </Stack>
            </button>
          ))}
        </Stack>
      </Box>
    </Stack>
  )
}

export default SelectBankAccount

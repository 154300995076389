import { AxiosError } from 'axios'

type APIErrorWithStatus = {
  type: string
  title: string
  status: number
  detail: string
  instance: string
}

export default function createAPIErrorMessage(error: AxiosError): string {
  if (error.response) {
    if (error.response.data)
      return (error.response.data as APIErrorWithStatus)?.detail || ''
  }

  return ''
}

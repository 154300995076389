import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { theme } from '../../constants/theme'
import { createContext, useEffect, useState } from 'react'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { Role, UserData } from '../../models/UserTypes'
import { useUser } from '../../services/UserContext'
import { useGet } from '../../hooks/useFetch'
import { useAuthMiddleware } from '../../services/AuthMiddleware'
import AuthService from '../../services/auth.service'
import { Navigate } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import AdminList from '../../components/Admin/AdminList'
import AddMoreAdminModal from '../../components/Admin/modal/AddMoreAdminModal'

import { AllAdminList } from '../../models/admin/AdminManagement'
import PermissionDeny from '../ErrorPage/PermissionDeny'
import CustomToast from '../../components/Toast/CustomToast'

export const AdminManagementContext = createContext<any>([])

export default function AdminManagementPage() {
  const desktop = useMediaQuery('(min-width:680px)')

  const [page, setPage] = useState(1)

  const token = AuthService.getTokenUser()
  const tokenChecked = useAuthMiddleware()
  const userData: UserData | null = useUser().userData
  const [userRole, setUserRole] = useState<Role>()
  const [openAddMoreModal, setOpenAddMoreModal] = useState<boolean>(false)

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  // TODO: Change API to /admins/list
  const [allAdminList, loadingAdminList, refreshAdminList] =
    useGet<AllAdminList>({
      url: '/admins/list',
      ...(token && { jwtToken: token }),
      autoFetch: true,
      queryParams: {
        pageNumber: page,
      },
    })

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const StyledTableHeadCell = styled(TableCell)({
    paddingBottom: '8px',
    paddingTop: '8px',
    paddingLeft: '16px',
  })

  const handleCloseModal = () => {
    refreshAdminList()
    setOpenAddMoreModal(false)
  }

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          {userRole === 'SUPER_ADMIN' ? (
            <AdminManagementContext.Provider value={[refreshAdminList]}>
              <div>
                <ResponsiveAppBar />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Stack
                    flexDirection={desktop ? 'row' : 'column'}
                    justifyContent={'space-between'}
                    width={'90%'}
                    alignItems={'center'}
                    paddingY={'24px'}
                  >
                    <Typography variant='h3' sx={{ padding: 0 }}>
                      Admin Management
                    </Typography>
                    <Button
                      startIcon={<Add />}
                      variant='outlined'
                      onClick={() => setOpenAddMoreModal(true)}
                    >
                      <Typography variant='h6' sx={{ padding: 0 }}>
                        Add more admin
                      </Typography>
                    </Button>
                  </Stack>

                  <Box
                    display={'flex'}
                    padding={'8px'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    textAlign={'center'}
                    marginBottom={'12px'}
                    sx={{
                      borderRadius: '8px',
                      border: '1px solid',
                      borderColor: theme.palette.grey[200],
                      backgroundColor: 'white',
                      width: '90%',
                    }}
                  >
                    <TableContainer>
                      <Table>
                        {desktop ? (
                          <TableHead>
                            <TableRow>
                              <StyledTableHeadCell sx={{ width: '40%' }}>
                                <Typography variant='h6' color={'text.primary'}>
                                  Name
                                </Typography>
                              </StyledTableHeadCell>
                              <StyledTableHeadCell sx={{ width: '60%' }}>
                                <Typography variant='h6' color={'text.primary'}>
                                  Username
                                </Typography>
                              </StyledTableHeadCell>
                              <TableCell sx={{ width: '20px' }}></TableCell>
                            </TableRow>
                          </TableHead>
                        ) : (
                          <TableHead>
                            <TableRow>
                              <StyledTableHeadCell>
                                <Typography variant='h6' color={'text.primary'}>
                                  Admin detail
                                </Typography>
                              </StyledTableHeadCell>
                            </TableRow>
                          </TableHead>
                        )}

                        {!loadingAdminList && (
                          <TableBody>
                            {allAdminList?.userList.map((admin) => (
                              <AdminList
                                key={`admin-list-${admin.id}`}
                                userId={admin.id}
                                firstName={admin.firstName}
                                lastName={admin.lastName}
                                email={admin.email}
                                role={admin.role}
                              />
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                    {loadingAdminList && (
                      <Box paddingY={2}>
                        <CircularProgress
                          color='primary'
                          disableShrink
                          size={60}
                          sx={{ margin: 'auto' }}
                        />
                      </Box>
                    )}
                    <Pagination
                      count={allAdminList?.totalPages}
                      page={page}
                      onChange={handleChange}
                      sx={{ paddingTop: '5px' }}
                    />
                  </Box>
                </Box>
                <AddMoreAdminModal
                  open={openAddMoreModal}
                  setOpen={setOpenAddMoreModal}
                  onClose={() => handleCloseModal()}
                  // handleClickConfirm={() => handleAddmoreAdmin({})}
                />
                <CustomToast />
              </div>
            </AdminManagementContext.Provider>
          ) : (
            <div>
              {userRole === 'ADMIN' ? <PermissionDeny /> : <Navigate to='/' />}
            </div>
          )}
        </ThemeProvider>
      )
    }
  }
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Stack,
  Container,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import ChurchProposalForm1, {
  ChurchMember,
  ChurchProfileInput,
  churchProfileSchema,
} from './ChurchProposalForm1' // ChurchProfileInput,
import ChurchProposalForm2, {
  ChurchProposal2Error,
  SelectedChannels,
} from './ChurchProposalForm2'

import AdminCommentSection from './AdminCommentSection'
import { grey } from '@mui/material/colors'
import NormalModal from '../Modal/NormalModal'
import { usePost, usePut } from '../../hooks/useFetch'
import uploadFile from '../../services/uploadfile.service'
import AuthService from '../../services/auth.service'
import trimFormValues from '../../utils/trimFormValues'
import {
  ChurchRequestStatus,
  GetChurchProposalFormAnswer,
  GetChurchProposalFormData,
  SubmitChurchProposalFormData,
} from '../../models/ChurchProposalFormData'
import { AttachmentMetaData } from '../../models/AttachmentMetaData'
import appendHttpsIfMissing from '../../utils/appendHttps'
import StatusBar from '../Admin/StatusBar'
import createAPIErrorMessage from '../../utils/createAPIErrorMessage'

const steps = ['Step 1', 'Step 2']

const MAX_MEMBERS = 10

interface ChurchProposalProps {
  type: 'CREATE' | 'EDIT'
  requestId?: string
  status?: ChurchRequestStatus
  adminNote?: string
  initialData?: GetChurchProposalFormData
}

function ChurchProposal({
  type,
  requestId,
  status,
  adminNote,
  initialData,
}: ChurchProposalProps) {
  const navigate = useNavigate()
  const [formData, setFormData] = useState<SubmitChurchProposalFormData>()

  const {
    register: registerChurchProfileForm,
    formState: { errors: churchProfileErrors },
    getValues: getChurchProfileData,
    handleSubmit: handleSubmitForm1,
    control: churhProfileFormControl,
  } = useForm<ChurchProfileInput>({
    resolver: zodResolver(churchProfileSchema),
    defaultValues: initialData,
  })

  const [
    createChurchRes,
    sendCreateLoading,
    sendCreateReq,
    sendCreateStatusCode,
    sendCreateError,
  ] = usePost<any>({
    url: '/church-requests/propose-create',
    autoFetch: false,
    jwtToken: AuthService.getTokenUser() ?? '',
    data: formData,
  })
  const [fileUploadLoading, setFileUploadLoading] = useState(false)
  const [uploadFileError, setUploadFileError] = useState(false)

  const [
    editChurchRes,
    sendEditLoading,
    sendEditReq,
    sendEditStatusCode,
    sendEditError,
  ] = usePut<any>({
    url: `/church-requests/propose-create/${requestId}/fix`,
    autoFetch: false,
    jwtToken: AuthService.getTokenUser() ?? '',
    data: formData,
  })

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  // Page1 States, not managed by React Hook form
  const memberSectionRef = useRef<HTMLDivElement>(null)
  const [members, setMembers] = useState<ChurchMember>({
    mcp: null,
    staffs: [],
  })
  const [memberError, setMemberError] = useState(false)

  const getMemberRange = (initialData?: GetChurchProposalFormData) => {
    if (
      initialData &&
      initialData['formAnswers'] &&
      initialData.formAnswers.length > 0
    ) {
      const question = initialData.formAnswers.find(
        (q: GetChurchProposalFormAnswer) => q.formQuestion.id === 1
      )

      if (question) {
        return question.answer
      }
    }

    return ''
  }

  const getSelectedChannels = (
    initialData?: GetChurchProposalFormData
  ): SelectedChannels => {
    if (!initialData) {
      return {
        Facebook: false,
        Twitter: false,
        Youtube: false,
        Email: false,
        OtherChecked: false,
        Other: '',
      }
    }

    const channels = initialData.formAnswers
      .filter((q: GetChurchProposalFormAnswer) => q.formQuestion.id === 2)
      .map((ans: GetChurchProposalFormAnswer) => ans.answer)

    const other = channels.filter(
      (c: string) =>
        c !== 'Facebook' && c !== 'Twitter' && c !== 'Youtube' && c !== 'Email'
    )

    return {
      Facebook: channels.includes('Facebook'),
      Twitter: channels.includes('Twitter'),
      Youtube: channels.includes('Youtube'),
      Email: channels.includes('Email'),
      OtherChecked: other.length > 0,
      Other: other.length > 0 ? other[0] : '',
    }
  }

  // Page2 States
  const [memberRange, setMemberRange] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState<AttachmentMetaData[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [selectedChannels, setSelectedChannels] = useState<SelectedChannels>({
    Facebook: false,
    Twitter: false,
    Youtube: false,
    Email: false,
    OtherChecked: false,
    Other: '',
  })

  // Set initial data
  useMemo(() => {
    if (initialData) {
      setUploadedFiles(initialData.documents)
      setMemberRange(getMemberRange(initialData))
      setSelectedChannels(getSelectedChannels(initialData))
      setMembers({
        mcp: initialData.mainContactPerson
          ? {
              id: initialData.mainContactPerson.id,
              firstName: initialData.mainContactPerson.firstName,
              lastName: initialData.mainContactPerson.lastName,
              email: initialData.mainContactPerson.email,
            }
          : null,
        staffs: initialData.churchStaff,
      })
    }
  }, [initialData])

  const [page2Errors, setPage2Errors] = useState<ChurchProposal2Error>({
    memberRangeError: false,
    fileTooBig: false,
    isFileMissing: false,
  })

  const onMembersChange = (newVal: ChurchMember) => {
    setMembers(newVal)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleFileChange = ({
    files,
    uploadedFilesMetadata,
  }: {
    files: File[]
    uploadedFilesMetadata: AttachmentMetaData[]
  }) => {
    setFiles(files)
    setUploadedFiles(uploadedFilesMetadata)
  }

  const validateMembers = useCallback(() => {
    let memberCount = 0
    const { mcp, staffs } = members
    if (mcp) memberCount++
    memberCount += staffs.length
    const isPassed = mcp && memberCount <= MAX_MEMBERS

    setMemberError(!isPassed)
    return isPassed
  }, [members])

  const onSubmitPage1Handler: SubmitHandler<ChurchProfileInput> = (values) => {
    if (validateMembers()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      window.scrollTo(0, 0)
    } else {
      if (memberSectionRef && memberSectionRef.current) {
        memberSectionRef.current.scrollIntoView()
      }
    }
  }

  const handleSubmitPage2 = async (memberCount: string, channels: string[]) => {
    const cdnFileLinks = uploadedFiles ? uploadedFiles?.map((f) => f.url) : []
    const page1Data = trimFormValues(getChurchProfileData())
    if (page1Data.website) {
      page1Data.website = appendHttpsIfMissing(page1Data.website)
    }

    const page2Data = {
      formAnswers: [
        {
          questionId: 1,
          answer: memberCount,
        },
        ...channels.map((channel) => {
          return {
            questionId: 2,
            answer: channel,
          }
        }),
      ],
      documents: [...(await uploadFiles()), ...cdnFileLinks],
    }

    setFormData({
      ...page1Data,
      ...getMembersId(),
      ...page2Data,
    })
  }

  const getMembersId = () => {
    return {
      mainContactPersonId: members.mcp?.id || '',
      churchStaffId: members.staffs.map((staff) => staff.id),
    }
  }

  const handleSubmitCreateChurch = () => {
    if (sendCreateLoading || uploadFileError) return

    sendCreateReq()
  }

  const handleSubmitEditChurch = () => {
    if (sendEditLoading || uploadFileError) return

    sendEditReq()
  }

  useEffect(() => {
    if (formData) {
      if (type === 'CREATE') {
        handleSubmitCreateChurch()
      } else if (type === 'EDIT') {
        handleSubmitEditChurch()
      }
    }
  }, [formData, type])

  useEffect(() => {
    if (sendEditStatusCode === -1 && sendCreateStatusCode === -1) return

    if (sendEditStatusCode === 200 || sendCreateStatusCode === 200) {
      setShowSuccessModal(true)
    } else {
      const statusCode =
        type === 'CREATE' ? sendCreateStatusCode : sendEditStatusCode

      let errMsg = ''
      if (sendEditError) {
        errMsg = createAPIErrorMessage(sendEditError)
      } else if (sendCreateError) {
        errMsg = createAPIErrorMessage(sendCreateError)
      }
      toast.error(
        `ไม่สามารถส่งคำขอได้ กรุณาลองอีกครั้งในภายหลัง ${errMsg} :ERROR(${statusCode})`
      )
    }
  }, [
    sendEditStatusCode,
    sendCreateStatusCode,
    type,
    sendEditError,
    sendCreateError,
  ])

  const uploadFiles = async (): Promise<string[]> => {
    setFileUploadLoading(true)
    try {
      const responses = await Promise.all(
        files.map((file) =>
          uploadFile('/church-requests/upload-document', file, 'file')
        )
      )

      const cdnUrls = responses.map((res) => res.data.url)
      setUploadFileError(false)
      return cdnUrls
    } catch (error: any) {
      setUploadFileError(true)
      let toastErrorMsg = `ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองอีกครั้งในภายหลัง`
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response)
        const msg = createAPIErrorMessage(error)
        toastErrorMsg = `ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองอีกครั้งในภายหลัง ${msg} :ERROR(${error.response.status})`
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      toast.error(toastErrorMsg)
    } finally {
      setFileUploadLoading(false)
    }

    return []
  }

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false)
    navigate('/')
  }

  if (sendCreateLoading || sendEditLoading) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        padding={'10vw'}
        alignItems={'center'}
        height={'70vh'}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Container sx={{ marginBottom: '80px' }}>
        <Stack gap={'20px'}>
          <div
            style={{
              maxWidth: '400px',
              width: '100%',
              minWidth: 0,
              alignSelf: 'center',
            }}
          >
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
          </div>
          {type === 'EDIT' && adminNote && (
            <>
              {status && (
                <Stack flexDirection='row' gap='24px'>
                  <Typography variant='h6'>สถานะ: </Typography>
                  <StatusBar variant={status} />
                  <Typography variant='h6'>
                    {status === 'REQUEST_FIX'
                      ? 'กรุณาแก้ไขข้อมูล ก่อนส่งคำร้องขอการสร้างโบสถ์อีกครั้ง'
                      : 'ไม่สามารถแก้ไขคำร้องขอนี้ได้ในขณะนี้'}
                  </Typography>
                </Stack>
              )}
              {adminNote && <AdminCommentSection comment={adminNote} />}
            </>
          )}
          <div>
            <Stack
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              paddingTop={'20px'}
            >
              <Stack
                flexDirection='column'
                border={1}
                borderColor={grey[400]}
                borderRadius={'20px'}
                width={'100%'}
                padding={3}
                gap='40px'
              >
                {activeStep === 0 && (
                  <ChurchProposalForm1
                    viewOnly={type === 'EDIT' && status !== 'REQUEST_FIX'}
                    ref={memberSectionRef}
                    churchProfileErrors={churchProfileErrors}
                    register={registerChurchProfileForm}
                    control={churhProfileFormControl}
                    members={members}
                    memberError={memberError}
                    onMemberChange={onMembersChange}
                    onSubmit={handleSubmitForm1(onSubmitPage1Handler)}
                  />
                )}
                {activeStep === 1 && (
                  <ChurchProposalForm2
                    viewOnly={type === 'EDIT' && status !== 'REQUEST_FIX'}
                    memberRange={memberRange}
                    uploadedFilesMetadata={uploadedFiles}
                    files={files}
                    selectedChannels={selectedChannels}
                    onMemberRangeChange={setMemberRange}
                    onFilesChange={handleFileChange}
                    onSelectedChannelsChange={setSelectedChannels}
                    errors={page2Errors}
                    setErrors={setPage2Errors}
                    onBack={handleBack}
                    onSubmit={handleSubmitPage2}
                    isSubmitLoading={
                      sendCreateLoading || fileUploadLoading || sendEditLoading
                    }
                  />
                )}
              </Stack>
            </Stack>
          </div>
        </Stack>
      </Container>
      <NormalModal
        id='success modal'
        type='DEFAULT'
        title={
          type === 'CREATE'
            ? 'ส่งแบบฟอร์มการสร้างโบสถ์สำเร็จ'
            : 'แก้ไขแบบฟอร์มสำเร็จ'
        }
        description='ระบบจะทำการตรวจสอบข้อมูล และแจ้งผลของท่านผ่านทางอีเมล'
        open={showSuccessModal}
        onClose={handleSuccessModalClose}
      />
    </>
  )
}

export default ChurchProposal

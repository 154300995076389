import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { common, grey } from '@mui/material/colors'
import { theme } from '../../../constants/theme'
import { CloseRounded, Visibility, VisibilityOff } from '@mui/icons-material'
import { COLOR } from '../../../constants/color-constant'
import { useForm } from 'react-hook-form'
import { TypeOf, object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import AuthService from '../../../services/auth.service'
import { addNewAdminBody } from '../../../models/admin/AdminManagement'
import { AdminManagementContext } from '../../../pages/Admin/AdminManagementPage'
import axios from 'axios'
import trimFormValues from '../../../utils/trimFormValues'
import { toast } from 'react-toastify'
import createAPIErrorMessage from '../../../utils/createAPIErrorMessage'

const registerSchema = object({
  firstname: string().nonempty('First Name is required'),
  lastname: string().nonempty('Last Name is required'),
  email: string().nonempty('Email is required').email('Email is invalid'),
  password: string()
    .nonempty('Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  passwordConfirm: string().nonempty('Please confirm your password'),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ['passwordConfirm'],
  message: 'Passwords do not match',
})

interface AddMoreAdminModalProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
  handleClickConfirm?: (data: addNewAdminBody) => void
}

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
}

type RegisterInput = TypeOf<typeof registerSchema>

export default function AddMoreAdminModal(props: AddMoreAdminModalProps) {
  const { open, onClose } = props
  const token = AuthService.getTokenUser()
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

  const { register } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  })

  const [refreshAdminList] = useContext(AdminManagementContext)

  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)

  const [firstNameInput, setFirstNameInput] = useState<string>('-1')
  const [lastNameInput, setLastNameInput] = useState<string>('-1')
  const [emailOrUserNameInput, setEmailOrUserNameInput] = useState<string>('-1')
  const [passwordInput, setPasswordInput] = useState<string>('-1')
  const [confirmPasswordInput, setConfirmPasswordInput] = useState<string>('-1')
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [passWordErrorText, setPasswordErrorText] = useState<string>('')
  const [confirmPasswordError, setConfirmPasswordError] =
    useState<boolean>(false)

  const handleShow1 = () => setShowPassword1((prev) => !prev)
  const handleShow2 = () => setShowPassword2((prev) => !prev)

  useEffect(() => {
    if (
      (passwordInput !== '-1' && passwordInput.length <= 8) ||
      (passwordInput !== '-1' && passwordInput.length > 32)
    ) {
      setPasswordError(true)
      if (passwordInput.length <= 8) {
        setPasswordErrorText('Password must be more than 8 characters.')
      } else {
        setPasswordErrorText('Password must be less than 32 characters.')
      }
    } else {
      setPasswordError(false)
    }
  }, [passwordInput, setPasswordInput])

  useEffect(() => {
    if (confirmPasswordInput !== passwordInput || confirmPasswordInput === '') {
      setConfirmPasswordError(true)
    } else {
      setConfirmPasswordError(false)
    }
  }, [confirmPasswordInput, passwordInput, setConfirmPasswordInput])

  const formValidation = () => {
    return (
      firstNameInput === '-1' ||
      firstNameInput === '' ||
      lastNameInput === '-1' ||
      lastNameInput === '' ||
      emailOrUserNameInput === '-1' ||
      emailOrUserNameInput === '' ||
      passwordInput === '-1' ||
      passwordError ||
      confirmPasswordInput === '-1' ||
      confirmPasswordError
    )
  }

  // console.log('emailOrUsernameInput', emailOrUserNameInput)

  const handleAddmoreAdmin = async () => {
    const body = {
      firstName: firstNameInput,
      lastName: lastNameInput,
      emailOrUsername: emailOrUserNameInput,
      password: passwordInput,
    }
    // console.log('trimFormValues(body)', trimFormValues(body))
    try {
      await axios.post(`${API_URL}/admins/add`, trimFormValues(body), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-API-KEY': `${API_KEY}`,
        },
      })
      console.log('add admin success')
      onClose()
      refreshAdminList()
      toast.success('Success')
    } catch (e: any) {
      console.log('error')
      toast.error(`An error has occurred. ${createAPIErrorMessage(e)}`)
    }
  }
  return (
    <Backdrop
      sx={{ color: common.black, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Modal open={open} onClose={onClose}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          sx={ModalStyle}
          width={{ xs: '80vw', md: '640px' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'flex-end'}
            alignItems={'flex-end'}
            flex={'1 0 0'}
          >
            {/* title & description section */}
            <Typography
              id='modal-modal-title'
              variant='h5'
              color='primary'
              width={'100%'}
            >
              New admin
            </Typography>
            <Divider
              variant='fullWidth'
              sx={{ color: grey[200], padding: '4px' }}
              flexItem
            />
            <Box height={theme.spacing(1)} />

            <Typography
              id='modal-modal-description'
              variant='subtitle1'
              color={grey[600]}
              width={'100%'}
            >
              Please fill each section to add new admin.
            </Typography>
            <Box height={theme.spacing(1)} />

            <Box height={theme.spacing(1)} />

            <Box width={'100%'} component='form' noValidate autoComplete='off'>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                useFlexGap
                justifyContent={'space-between'}
                flexWrap='wrap'
                marginBottom={'20px'}
              >
                {/* First Name Field */}
                <TextField
                  sx={{
                    width: {
                      xs: '100%',
                      md: '47%',
                    },
                    label: { color: COLOR.DIVIDER_MAIN },
                  }}
                  label='First Name'
                  fullWidth
                  required
                  error={firstNameInput === ''}
                  helperText={
                    firstNameInput === '' && (
                      <Typography variant='body2'>
                        First Name is required.
                      </Typography>
                    )
                  }
                  {...register('firstname')}
                  size='small'
                  onChange={(event) =>
                    setFirstNameInput(event.target.value.trim())
                  }
                />
                {/* Last Name Field */}
                <TextField
                  sx={{
                    width: {
                      xs: '100%',
                      md: '47%',
                    },
                    label: { color: COLOR.DIVIDER_MAIN },
                  }}
                  label='Last Name'
                  fullWidth
                  required
                  error={lastNameInput === ''}
                  helperText={
                    lastNameInput === '' && (
                      <Typography variant='body2'>
                        Last Name is required.
                      </Typography>
                    )
                  }
                  {...register('lastname')}
                  size='small'
                  onChange={(event) =>
                    setLastNameInput(event.target.value.trim())
                  }
                />
              </Stack>
              {/* Email Field */}
              <TextField
                sx={{ mb: 2, label: { color: COLOR.DIVIDER_MAIN } }}
                label='E-mail or Username'
                fullWidth
                required
                type='email'
                error={emailOrUserNameInput === ''}
                helperText={
                  emailOrUserNameInput === '' && (
                    <Typography variant='body2'>
                      E-mail or Username is required.
                    </Typography>
                  )
                }
                {...register('email')}
                size='small'
                onChange={(event) =>
                  setEmailOrUserNameInput(event.target.value.trim())
                }
              />
              {/* Password Field */}
              <TextField
                sx={{ mb: 2, label: { color: COLOR.DIVIDER_MAIN } }}
                label={'Password'}
                fullWidth
                required
                type={showPassword1 ? 'text' : 'password'}
                error={passwordError}
                helperText={
                  passwordError && (
                    <Typography variant='body2'>{passWordErrorText}</Typography>
                  )
                }
                {...register('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleShow1} edge='end'>
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                onChange={(event) =>
                  setPasswordInput(event.target.value.trim())
                }
              />
              {/* Confirm Password Field */}
              <TextField
                sx={{ mb: 2, label: { color: COLOR.DIVIDER_MAIN } }}
                label={'Confirm Password'}
                fullWidth
                required
                type={showPassword2 ? 'text' : 'password'}
                error={confirmPasswordError}
                helperText={
                  confirmPasswordError && (
                    <Typography variant='body2'>
                      {confirmPasswordInput === ''
                        ? 'Confirm password is required.'
                        : 'Passwords do not match.'}
                    </Typography>
                  )
                }
                {...register('passwordConfirm')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleShow2} edge='end'>
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(event) =>
                  setConfirmPasswordInput(event.target.value.trim())
                }
                size='small'
              />
            </Box>
            <Stack // buttons
              alignItems={{ sx: 'stretch', md: 'flex-end' }}
              justifyContent={'flex-end'}
              width={'100%'}
            >
              <Button
                type='submit'
                variant='outlined'
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleAddmoreAdmin()}
                disabled={formValidation()}
              >
                <Typography variant='h6'>Create Admin</Typography>
              </Button>
            </Stack>
          </Box>
          <Box // close button
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: '0px',
              top: '0px',
              margin: '16px',
            }}
          >
            <CloseRounded
              sx={{ color: grey[400], cursor: 'pointer', fontSize: '20px' }}
            />
          </Box>
        </Box>
      </Modal>
    </Backdrop>
  )
}

import React, { useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import ChurchProposal from '../../components/ChurchProposal/ChurchProposal'
import { useUser } from '../../services/UserContext'
import NormalModal from '../../components/Modal/NormalModal'
import { ROUTE } from '../../constants/route-constant'
import AuthService from '../../services/auth.service'
import CustomToast from '../../components/Toast/CustomToast'

function CreateChurchProposalPage() {
  const userContext = useUser()
  const token = AuthService.getTokenUser()
  const navigate = useNavigate()

  const [isResendValidationModal, setIsResendValidationModal] = useState(false)

  useEffect(() => {
    if (!token) {
      navigate(ROUTE.LOGIN)
    }

    if (userContext.userData) {
      if (!userContext.userData.userdetail.verified) {
        setIsResendValidationModal(true)
      }

      // Only normal logged in user can access this page
      if (userContext.userData.userrole.userRole !== 'USER') {
        navigate(ROUTE.LOGIN)
      }
    }
  }, [navigate, token, userContext])

  if (!token || !userContext.userData) return null

  return (
    <>
      <CustomToast />
      <Stack flexDirection='column' minHeight={'100vh'}>
        <Navbar />
        <Typography alignSelf={'center'} variant='h1' sx={{ margin: '20px' }}>
          ลงทะเบียนคริสตจักร
        </Typography>
        <ChurchProposal type='CREATE' />
        <Footer />
        <NormalModal
          id={'resend-Validation-email'}
          type={'DEFAULT'}
          title={`กรุณายืนยันตัวตนของท่านผ่านอีเมลก่อนเข้าใช้งาน`}
          open={isResendValidationModal}
          isClosable={false}
          confirmText='ไปที่หน้าโปรไฟล์เพื่อยืนยันอีเมล'
          onClickConfirm={() => {
            setIsResendValidationModal(false)
            navigate(ROUTE.USER)
          }}
        />
      </Stack>
    </>
  )
}

export default CreateChurchProposalPage
